<template>
  <!-- 派工台 -->
  <div class="dispatchingUnits">
    <!-- 左侧服务预约 -->
    <div
      class="leftSidePart"
      :style="{ height: candidateHeight() }"
      v-show="isShowCandidateTable == 'order'"
    >
      <div
        class="resize-bar"
        @mouseenter="getResizeEnter"
        @mouseleave="getResizeLeave"
        ref="resizeRef"
        :style="{
          'max-width': candidateWeight(),
          width: candidateNormalWeight,
        }"
      ></div>
      <div class="resize-line"></div>
      <div
        class="resize-save leftSidePartBox"
      >
        <div class="globalFunctionIcon">
          <!-- 策略--pengyd -->
          <!-- <div class="strategy">
          <span>策略：</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in strategyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
          <!-- 预约区域选择 -->
          <div class="reservationArea">
            <div class="reservation-icon" @click="dialogTree">
              <svg class="icon" aria-hidden="true">
                <use href="#icon-quyu"></use>
              </svg>
            </div>
            <!-- 颜色提示弹框 -->
            <el-popover
              placement="bottom"
              width="484"
              height="320"
              trigger="click"
              v-model="showColorFlag"
            >
              <div class="color-box">
                <div class="color-title-box">
                  <div class="color-title">{{ $t("c1291") }}</div>
                  <svg
                    class="icon"
                    @click="closeColorPopover"
                    aria-hidden="true"
                  >
                    <use href="#icon-close"></use>
                  </svg>
                </div>
                <div class="color-tip">{{ $t("c1292") }}</div>
                <div class="color-content">
                  <div class="status-left">
                    <div
                      v-for="item in rightColorData"
                      :key="item.id"
                      class="status-box"
                    >
                      <el-color-picker
                        :value="item.legend"
                        size="mini"
                        @change="(value) => changeLegend(value, item)"
                      ></el-color-picker>
                      <div class="status-value">{{ item.codevalue }}</div>
                    </div>
                  </div>
                </div>
                <div class="color-btn">
                  <el-button type="primary" @click="saveStatusLegends">
                    {{ $t("pagecreator_button_save") }}
                  </el-button>
                </div>
              </div>
              <div class="reservation-icon" slot="reference">
                <svg
                  class="icon"
                  aria-hidden="true"
                  @click="getAppoStatusLegends"
                >
                  <use href="#icon-zhuangtaituli"></use>
                </svg>
              </div>
            </el-popover>
          </div>
          <!-- 派遣控制台 -->
          <div class="dispatchConsole" @click="dispatchConsoleSetUp">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-shezhi"></use>
            </svg>
          </div>
        </div>

        <!-- 所有服务预约 -->
        <div class="serviceAppointment" v-show="isShowService">
          <div class="appointment-type">
            <div class="name" @click.stop="changeType">
              <span>{{ appointName.label }}</span>
              <svg class="icon" aria-hidden="true">
                <use href="#icon-sanjiao"></use>
              </svg>
              <ul class="appoint-box" v-show="isShowTypeBox">
                <li
                  :class="{ active: item.id == appointName.id }"
                  v-for="(item, index) in appointNameArr"
                  :key="index"
                  @click.stop="sendType(item)"
                >
                  {{ item.label }}
                </li>
              </ul>
            </div>
            <div class="function" @click.stop="editType()">
              <svg class="icon" aria-hidden="true">
                <use href="#icon-editUnit"></use>
              </svg>
              <ul class="deit-func" v-show="isShowButtonBox">
                <li
                  v-for="(item, index) in buttonTypeArr"
                  :key="index"
                  @click.stop="editButton(item)"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
          <!-- 时间筛选器 -->
          <div class="timeFilter">
            <!-- 正在选定时间范围内预约 -->
            <div class="timeLimit">
              <el-popover
                placement="right-start"
                width="185"
                trigger="click"
                popper-class="elPopoverTimeLimit"
              >
                <div class="theClock" slot="reference">
                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-shizhong"></use>
                  </svg>

                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-sanjiao"></use>
                  </svg>
                </div>
                <!-- 时间范围内选项 -->
                <!-- 最早允许的开始时间{{$t('label.service.appointmentList.earliest.allowedStart')}}  到期日期{{$t('label.chatter.maturiyDate')}}
                  到期时段开始{{$t('label.service.appointmentList.startOf.maturityPeriod')}}  计划开始{{$t('label.service.appointmentList.plansToStart')}}
                  计划结束{{$t('label.service.appointmentList.plansToEnd')}}   甘特图显示日期{{$t('label.service.appointmentList.gantt.chart.shows')}}
                  显示选定时间范围内的服务预约 {{$t('label.service.dispatchingUnits.selected.time.range')}} -->
                <div class="timeLimitItems">
                  <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item>
                      <el-checkbox-group v-model="form.type">
                        <el-checkbox
                          :label="
                            $t(
                              'label.service.appointmentList.earliest.allowedStart'
                            )
                          "
                          name="type"
                        ></el-checkbox>
                        <el-checkbox
                          :label="$t('label.chatter.maturiyDate')"
                          name="type"
                        ></el-checkbox>
                        <el-checkbox
                          :label="
                            $t(
                              'label.service.appointmentList.startOf.maturityPeriod'
                            )
                          "
                          name="type"
                        ></el-checkbox>
                        <el-checkbox
                          :label="
                            $t('label.service.appointmentList.plansToStart')
                          "
                          name="type"
                        ></el-checkbox>
                        <el-checkbox
                          :label="
                            $t('label.service.appointmentList.plansToEnd')
                          "
                          name="type"
                        ></el-checkbox>
                        <el-checkbox
                          :label="
                            $t(
                              'label.service.appointmentList.gantt.chart.shows'
                            )
                          "
                          name="type"
                        ></el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-form>
                  <div style="word-break: break-word">
                    {{
                      $t("label.service.dispatchingUnits.selected.time.range")
                    }}
                  </div>
                </div>
              </el-popover>
            </div>
            <!-- 选择日期  {{$t('label.tabpage.selectdate')}}  水平 label.service.dispatchingUnits.the.level-->
            <div class="theCalendar">
              <div class="theLevelOf">
                {{ $t("label.service.dispatchingUnits.the.level") }}:
              </div>
              <div class="block">
                <el-date-picker
                  v-model="dateValue"
                  type="date"
                  value-format="yyyy-MM-dd"
                  :placeholder="$t('label.tabpage.selectdate')"
                  v-if="ispickerShow"
                  @change="changeDate"
                >
                </el-date-picker>
                <el-date-picker
                  v-model="dateValue"
                  type="date"
                  :placeholder="$t('label.tabpage.selectdate')"
                  disabled
                  v-if="!ispickerShow"
                >
                </el-date-picker>
              </div>
            </div>
            <!-- 匹配甘特表日期  {{$t('label.service.dispatchingUnits.gantt.data')}}-->
            <div class="ganttChart">
              <el-checkbox v-model="checkedDate" @change="checkedDateMethod">{{
                $t("label.service.dispatchingUnits.gantt.data")
              }}</el-checkbox>
            </div>
          </div>

          <!-- 无服务预约{{$t('label.service.dispatchingUnits.noservice.booking')}}
             当前{{ left }}-{{ right }}条数据，共{{ totalCount }}条数据    label.service.dispatchingUnits.left.right.total
             批量派遣 {{$t('label.service.dispatchingUnits.batch.send')}} -->
          <div class="appointment-list-title">
            <div class="appointment-search">
              <div class="number" v-show="totalCount == 0">
                {{ $t("label.service.dispatchingUnits.noservice.booking") }}
                <i
                  class="el-icon-refresh-left refresh-list"
                  @click="updateAppList"
                ></i>
              </div>
              <div class="number" v-show="totalCount != 0">
                <!-- 当前{{ left }}-{{ right }}条数据，共{{ totalCount }}条数据 -->
                {{
                  $t("label.service.dispatchingUnits.left.right.total", {
                    left: left,
                    right: right,
                    totalCount: totalCount,
                  })
                }}
                <i
                  class="el-icon-refresh-left refresh-list"
                  @click="updateAppList"
                ></i>
              </div>
              <!-- 自动计划--pengyd -->
              <!-- <div class="button automaticPlan" @click="automaticPlanClick">
              自动计划
            </div> -->
              <div class="function" v-if="false">
                <div class="button plan" @click="batchDispatch">
                  {{ $t("label.service.dispatchingUnits.batch.send") }}
                </div>
              </div>
            </div>
            <!-- 搜索服务预约 {{$t('label.service.dispatchingUnits.search.services')}}-->
            <el-input
              :placeholder="
                $t('label.service.dispatchingUnits.search.services')
              "
              suffix-icon="el-icon-search"
              v-model="inputValueStr"
              @change="getInputValue"
            ></el-input>
          </div>
          <!-- 预约列表 -->
          <appointment-list
            ref="appointmentList"
            :resourceId="appointName.id"
            :dateValue="dateValue"
            :formType="form.type.join()"
            :treeString="treeString"
            :inputValue="inputValue"
            :isGnattAndMap="activeName"
            @locationGrantt="locationGrantt"
            @openCandidate="openCandidate"
          ></appointment-list>
        </div>

        <!-- 区域 {{$t('setup.layout.component.area')}}-->
        <div class="selectArea" v-show="!isShowService">
          <!-- 区域 -->
          <div class="areaTitle">
            <span>{{ $t("setup.layout.component.area") }}</span>
          </div>
          <!-- 显示未与区域关联的服务预约 {{$t('label.service.dispatchingUnits.displays.service.appointments')}}-->
          <div class="regionalCorrelation">
            <el-checkbox
              v-model="checkedRegional"
              :disabled="!ispermissionRegional"
              >{{
                $t(
                  "label.service.dispatchingUnits.displays.service.appointments"
                )
              }}</el-checkbox
            >
          </div>
          <!-- 搜索服务区域 {{$t('label.service.dispatchingUnits.search.service.area')}}-->
          <div class="searchServiceArea">
            <div class="searServArea">
              <el-input
                v-model="filterText"
                :placeholder="
                  $t('label.service.dispatchingUnits.search.service.area')
                "
              ></el-input>
            </div>
            <!-- 全选 {{$t('setup.layout.button.selectall')}}-->
            <!-- 取消全选 {{$t('label.cancelall')}}-->
            <div class="allAndCancel">
              <el-button @click="serviceAreaAll">{{
                $t("setup.layout.button.selectall")
              }}</el-button>
              <el-button @click="canceiServiceAreaAll">{{
                $t("label.cancelall")
              }}</el-button>
            </div>
          </div>
          <!-- 服务区域展开列表 -->
          <div class="searchServiceAreaList" v-loading="showListLoading">
            <el-tree
              :data="listData"
              show-checkbox
              :default-expand-all="true"
              :default-checked-keys="listIscheckedData"
              check-strictly
              node-key="id"
              ref="tree"
              highlight-current
              :props="defaultProps"
              :filter-node-method="filterNode"
              @check="checkTree"
            >
            </el-tree>
          </div>
          <!-- 取消{{$t('label.emailsync.button.cancel')}} 和 保存{{$t('component.telerecord.button.save')}} -->
          <div class="cancleAndSave">
            <el-button @click="cancleService" :loading="showButtonLoading">
              {{ $t("label.emailsync.button.cancel") }}
            </el-button>
            <el-button @click="saveService" :loading="showButtonLoading">
              {{ $t("component.telerecord.button.save") }}
            </el-button>
          </div>
        </div>
      </div>
      <!-- 拖拽图标 -->
      <div class="drag-icon">
        <svg
          aria-hidden="true"
          v-if="resizeIconFlag && getShowDragIcon('shouqi')"
        >
          <use href="#icon-paigongtai-shouqi"></use>
        </svg>
        <svg
          aria-hidden="true"
          v-if="resizeIconFlag && getShowDragIcon('zhankai')"
        >
          <use href="#icon-paigongtai-zhankai"></use>
        </svg>
      </div>
    </div>
    <!-- 候选人列表  label.service.gantt.available.plan-->
    <div
      class="candidateBox"
      :style="{ height: candidateHeight() }"
      v-show="isShowCandidateTable == 'candidate'"
    >
      <div
        class="resize-bar"
        @mouseenter="getResizeEnter"
        @mouseleave="getResizeLeave"
        ref="resizeRef"
        :style="{
          'max-width': candidateWeight(),
          width: candidateNormalWeight,
        }"
      ></div>
      <div class="resize-line"></div>
      <div class="resize-save">
        <div class="candidateTitle">
          <!-- 计划策略禁选：1、当策略为只读时禁选2、切换策略禁选时禁选-->
          <el-select
            v-model="strategyValue"
            @change="strategyChange"
            placeholder="请选择策略"
            :disabled="strategyReadOnly ? strategyReadOnly : strategyNoChange"
            filterable
            style="width: 70%"
          >
            <el-option
              v-for="item in strategyOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <svg class="icon" @click="closeSuccess()" aria-hidden="true">
            <use href="#icon-close"></use>
          </svg>
        </div>
        <div class="optimalAllocation">
          <div>{{ $t("c1290") }}</div>
          <!-- <div class="show-optimal" @click="recommendShowFn">
              {{ $t("c1289") }}
          </div> -->
        </div>
        <candidateNeutralList
          ref="candidateNeutralRef"
          :strategyValue="strategyValue"
          @strategyChange="strategyChange"
          @getbtnJurisdiction="getbtnJurisdiction"
          @locationGrantt="locationGrantt"
          @serviceAppoSuccess="serviceAppoSuccess"
          @setNoChange="setNoChange"
        />
      </div>
      <!-- 拖拽图标 -->
      <div class="drag-icon">
        <svg
          aria-hidden="true"
          v-if="resizeIconFlag && getShowDragIcon('shouqi')"
        >
          <use href="#icon-paigongtai-shouqi"></use>
        </svg>
        <svg
          aria-hidden="true"
          v-if="resizeIconFlag && getShowDragIcon('zhankai')"
        >
          <use href="#icon-paigongtai-zhankai"></use>
        </svg>
      </div>
    </div>
    <!-- 派遣成功页面 -->
    <div class="candidate-success" v-show="isShowCandidateTable == 'success'">
      <div class="success-close">
        <svg class="icon" @click="closeSuccess('update')" aria-hidden="true">
          <use href="#icon-close"></use>
        </svg>
      </div>
      <!-- 分配成功||派遣成功 -->
      <div class="success-tip" v-if="dispatchOrDistribution != 'dispatch'">
        {{ $t("c1284") }}
      </div>
      <div class="success-tip" v-if="dispatchOrDistribution == 'dispatch'">
        {{ $t("c2139") }}
      </div>
      <div class="success-icon">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-checkMark"></use>
        </svg>
      </div>
      <div class="success-content">
        <div class="success-content-title">{{ $t("label.zhaiyao") }}</div>
        <div
          class="success-key"
          v-for="item in candidateSuccessData"
          :key="item.label"
        >
          <div class="success-label">{{ item.label }}</div>
          <div class="success-value">{{ item.value }}</div>
        </div>
        <div class="success-content-title">
          {{ $t("label.service.resources") }}
        </div>
        <div
          class="success-key"
          v-for="item in candidateServeData"
          :key="item.label"
        >
          <div class="success-serve">{{ item.label }}</div>
          <div class="success-value">{{ item.value }}</div>
        </div>
      </div>
      <div class="success-btn" @click="closeSuccess('update')">
        <el-button type="primary">{{ $t("label.logout") }}</el-button>
      </div>
    </div>
    <!-- 右侧甘特图和地图 -->
    <div class="rightSidePart" :style="{ height: candidateHeight() }">
      <!-- Tab切换 -->
      <div class="tabSwitch">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            :label="$t('label.projectManagement.gantt')"
            name="first"
          >
            <!-- 甘特表组件   甘特图{{$t('label.projectManagement.gantt')}}-->
            <gantt
              ref="Fullcalendar2Grantt"
              :appoJurisdiction="appoJurisdiction"
              :ResourceJurisdiction="ResourceJurisdiction"
              @ganttDateValueMethod="ganttDateValueMethod"
              @getKPITime="getKPITime"
            />
          </el-tab-pane>
          <el-tab-pane v-if="false" :label="$t('label.icon.172')" name="second">
            <!-- 百度地图组件   地图{{$t('label.icon.172')}}-->
            <ganttBaiduMap />
          </el-tab-pane>
          <el-tab-pane
            v-if="false"
            :label="$t('label.ServiceCloud.distributionMap')"
            name="distributionMap"
          >
            <!-- 分布图组件  -->
            <distributionMap v-if="activeName === 'distributionMap'" />
          </el-tab-pane>
        </el-tabs>
        <div class="tolTimeBox" v-if="false">
          <div v-for="(KPIitem, KPIindex) in KPIData" :key="KPIindex">
            <el-tooltip :content="KPIitem.tip" placement="top">
              <svg class="icon" aria-hidden="true">
                <use :href="'#icon-' + KPIitem.imgSrc"></use>
              </svg>
            </el-tooltip>
            {{ KPIitem.time }}
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="$t('c1288')" :visible.sync="recommendShow" width="440px">
      <div class="celue">
        <div class="scrollBoxUnique celue-list">
          <div v-for="(item, index) in recommendRuleData" :key="index">
            <div class="celue-objectname">{{ item.ruleObject }}</div>
            <div
              class="celue-rule"
              v-for="(ruleitem, ruleindex) in item.data"
              :key="ruleindex"
            >
              <div class="celue-rule-item">
                <div class="celue-number">{{ Number(ruleindex) + 1 }}</div>
                <div class="celue-rule-title">{{ ruleitem.ruleName }}</div>
                <div class="celue-rule-content">
                  {{ ruleitem.ruleNameValue }}
                </div>
              </div>
              <div class="celue-rule-item">
                <div class="celue-div"></div>
                <div class="celue-rule-title">{{ ruleitem.ruleType }}</div>
                <div class="celue-rule-content">
                  {{ ruleitem.ruleTypeValue }}
                </div>
              </div>
              <div class="celue-rule-item">
                <div class="celue-div"></div>
                <div class="celue-rule-title">{{ ruleitem.ruleContent }}</div>
                <div class="celue-rule-content">
                  {{ ruleitem.ruleContentValue }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="recommendShow = false">取 消</el-button>
        <el-button type="primary" @click="saveCanditate">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 派遣控制台设置 组件-->
    <dispatchConsoleSetUp
      :dispConSetUp="dispConSetUp"
      @dispConSetUpCancle="dispConSetUpCancle"
      @dispConSetUpConfrim="dispConSetUpConfrim"
    />
    <!-- 删除自定义服务预约视图，弹窗 -->
    <deleteCustomView
      :delCustomView="delCustomView"
      :id="appointName.id"
      :name="appointName.label"
      @deleteCustomViewCancle="deleteCustomViewCancle"
      @deleteCustomViewConfrim="deleteCustomViewConfrim"
    />
    <new-build-dialog
      v-if="dialogNewBuild"
      :dialogNewBuild="dialogNewBuild"
      :appointName="appointName"
      @closeNewBuild="closeNewBuild"
    ></new-build-dialog>
    <edit-dialog
      v-if="dialogEdit"
      :dialogNewBuild="dialogEdit"
      :appointName="appointName"
      @closeEdit="closeEdit"
    ></edit-dialog>
  </div>
</template>

<script>
import candidateNeutralList from "@/views/dispatchingUnits/component/candidateNeutralList";
import dispatchConsoleSetUp from "@/views/dispatchingUnits/component/dispatchConsoleSetUp";
import deleteCustomView from "@/views/dispatchingUnits/component/deleteCustomView";
import appointmentList from "@/views/dispatchingUnits/component/appointmentList.vue";
import editDialog from "@/views/dispatchingUnits/component/editDialog";
import newBuildDialog from "@/views/dispatchingUnits/component/newBuildDialog";

import gantt from "@/views/dispatchingUnits/component/gantt";
import ganttBaiduMap from "@/views/dispatchingUnits/component/ganttBaiduMap";
import distributionMap from "@/views/dispatchingUnits/component/distributionMap";

import {
  saveCheckServiceTerritory,
  findMyServiceTerritory,
  getAppointmentChecked,
  saveAppointmentChecked,
  getServiceAppointmentView,
  getObjectPermission,
  getVisitDispatchPlatformPermission,
  getPolicyList,
  buttonPermission,
  getAppoStatusLegends,
  saveStatusLegends,
  getSystemPermission,
} from "./api.js";

export default {
  components: {
    dispatchConsoleSetUp,
    deleteCustomView,
    appointmentList,
    editDialog,
    newBuildDialog,
    gantt,
    ganttBaiduMap,
    candidateNeutralList,
    distributionMap,
  },
  //进入组件前权限判断
  beforeRouteEnter: (to, from, next) => {
    getVisitDispatchPlatformPermission()
      .then((res) => {
        if (res.data) {
          next(true);
        } else {
          next("/DispatchDesk");
        }
      })
      .catch(() => {});
  },
  data() {
    return {
      showListLoading: true, // 列表加载loading
      showButtonLoading: false, // 按钮加载loading
      // 策略列表
      strategyOptions: [],
      // 策略只读
      strategyReadOnly: false,
      // 策略id
      strategyValue: "",
      dialogTreeTrue: false,
      form: {
        type: [
          this.$i18n.t("label.service.appointmentList.earliest.allowedStart"), //最早允许的开始时间 this.$i18n.t('label.service.appointmentList.earliest.allowedStart')
          this.$i18n.t("label.chatter.maturiyDate"), //到期日期 this.$i18n.t('label.chatter.maturiyDate')
          this.$i18n.t("label.service.appointmentList.startOf.maturityPeriod"), //到期时段开始 this.$i18n.t('label.service.appointmentList.startOf.maturityPeriod')
          this.$i18n.t("label.service.appointmentList.plansToStart"), //计划开始 this.$i18n.t('label.service.appointmentList.plansToStart')
          this.$i18n.t("label.service.appointmentList.plansToEnd"), //计划结束 this.$i18n.t('label.service.appointmentList.plansToEnd')
          this.$i18n.t("label.service.appointmentList.gantt.chart.shows"), //甘特图显示日期 this.$i18n.t('label.service.appointmentList.gantt.chart.shows')
        ],
      },
      ispickerShow: true,
      isShowService: true, // 控制预约列表和区域的切换
      dispConSetUp: false, // 派遣控制台的显示和隐藏 控制
      delCustomView: false, // 删除自定义服务预约视图，弹窗
      dateValue: "", // 日期选择
      dateGanttValue: "",
      checkedDate: false,
      checkedRegional: false,
      // 与区域关联的服务预约,true有权限false没权限
      ispermissionRegional: true,
      filterText: "", // 搜索服务区域
      activeName: "first",
      listData: [], //服务预约区域的全部数据
      listIdData: [], //服务预约区域全部ID
      otherListIdData: [], //服务预约区域未勾选的ID
      listIscheckedData: [], //服务预约区域勾选的ID
      defaultProps: {
        children: "childServiceTerritory",
        label: "name",
      },
      inputValue: "",
      inputValueStr: "",
      //服务预约名称
      appointName: {},
      //服务预约名称数组
      appointNameArr: [],
      //服务预约弹框显示与隐藏
      isShowTypeBox: false,
      //按钮的类型数组
      // buttonTypeArr: ["新建", "编辑", "删除"],
      //按钮弹框的显示与隐藏
      isShowButtonBox: false,
      //美化弹框
      isShowMeiHuaBox: false,
      // 新建弹框
      dialogNewBuild: false,
      //编辑弹框
      dialogEdit: false,
      //树id
      treeString: "",
      totalCount: 0,
      left: 0,
      right: 0,
      isShowCandidateTable: "order", //是否显示候选人列表
      KPIData: [
        {
          type: "sumTime",
          time: "0" + this.$i18n.t("label.ems.hour"),
          tip: this.$i18n.t("label.service.gantt.total.planned.duration"), //'服务预约的计划持续时间总和',
          imgSrc: "ganttTime",
        },
        {
          type: "avgTime",
          time: "0" + this.$i18n.t("label.ems.hour"),
          tip: this.$i18n.t("label.service.gantt.avg.traveltime"), //'服务预约的平均出行时间',
          imgSrc: "kpiVehcar",
        },
        {
          type: "completeSum",
          time: "0/0",
          tip: this.$i18n.t("c152"), //当前选择时间范围内的已完成服务预约数量/当前选择时间范围内的所有服务预约数量,
          imgSrc: "kpiyes",
        },
        {
          type: "violationListSum",
          time: "0",
          tip: this.$i18n.t("label.service.gantt.violations.sum"), //'违规的服务预约数量',
          imgSrc: "gantan",
        },
        {
          type: "injeopardySum",
          time: "0",
          tip: this.$i18n.t("label.service.gantt.dangerous.sum"), //'处于危险状态的服务预约数量',
          imgSrc: "ganttTips",
        },
        {
          type: "question",
          time: "",
          tip: this.$i18n.t("label.service.gantt.refreshData"), //刷新页面后更新数据
          imgSrc: "ganttwhat",
        },
      ], //预约KPI按钮
      //服务预约权限
      appoJurisdiction: {},
      //服务资源权限
      ResourceJurisdiction: {},
      // 颜色提示弹框开关
      showColorFlag: false,
      // 颜色图例数据
      rightColorData: [],
      // 派遣成功参数：开始时间，结束时间，持续时间
      candidateSuccessData: [],
      // 派遣成功参数：服务区域，服务资源
      candidateServeData: [],
      // 推荐策略弹框
      recommendShow: false,
      // 推荐策略数据
      recommendRuleData: [
        {
          ruleObject: "工作规则对象",
          data: [
            {
              ruleName: "规则名称",
              ruleNameValue: "筛选总部工程师",
              ruleType: "规则类型",
              ruleTypeValue: "字段匹配规则",
              ruleContent: "规则内容",
              ruleContentValue: "服务区域AAA",
            },
          ],
        },
        {
          ruleObject: "工作规则对象2",
          data: [
            {
              ruleName: "规则名称",
              ruleNameValue: "筛选总部工程师",
              ruleType: "规则类型",
              ruleTypeValue: "字段匹配规则",
              ruleContent: "规则内容",
              ruleContentValue: "服务区域AAA",
            },
          ],
        },
      ],
      // 服务区域id
      serviceterritoryId: "", //服务区域id
      // 点击候选人获取当前的服务预约记录
      currentServeAppointment: {},
      dispatchOrDistribution: "", //区分是分配还是派遣页面
      candidateNormalWeight: "", //候选人列表宽度默认30%
      resizeIconFlag: false, //是否显示拖拽图标
      updateJurisdiction: false, //是否调取按钮权限接口
      strategyNoChange: false, //策略是否可选
    };
  },
  watch: {
    treeString(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$bus.$emit("get-serviceCheckID", newVal);
      }
    },
    // 搜索服务预约区域
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {
    buttonTypeArr() {
      let buttonArr = [];
      if (this.appointName.canDelete && this.appointName.canEdit) {
        buttonArr = [
          this.$i18n.t("label.import.page1.newcreate"),
          this.$i18n.t("label.emailtocloudcc.edit"),
          this.$i18n.t("label.emailtocloudcc.delete"),
        ];
      } else if (this.appointName.canDelete && !this.appointName.canEdit) {
        buttonArr = [
          this.$i18n.t("label.import.page1.newcreate"),
          this.$i18n.t("label.emailtocloudcc.delete"),
        ];
      } else if (!this.appointName.canDelete && this.appointName.canEdit) {
        buttonArr = [
          this.$i18n.t("label.import.page1.newcreate"),
          this.$i18n.t("label.emailtocloudcc.edit"),
        ];
      } else {
        buttonArr = [this.$i18n.t("label.import.page1.newcreate")];
      }
      return buttonArr;
    },
  },
  mounted() {
    document.addEventListener("click", this.clickFn);
    this.getNowTime();
    // this.fastSwitching();
    this.serviceAppointmentView("yes");
    this.$Bus.$on("deliver-list-totalCount", this.ebFn);
    // this.ganttDateValueMethod();
    this.getObjJurisdiction("serviceappointment");
    this.getObjJurisdiction("serviceresource");
    localStorage.setItem("dispatchOpendArea", "[]");
  },
  beforeDestroy() {
    this.$Bus.$off("deliver-list-totalCount", this.ebFn);
    document.removeEventListener("click", this.clickFn);
  },
  methods: {
    // 候选人列表最大宽度
    candidateWeight() {
      return parseInt(window.innerWidth * 0.6) + "px";
    },
    // 候选人列表高度
    candidateHeight() {
      let strHeight = localStorage.getItem("footerToolBottom");
      let heightpx = Number(strHeight.substring(0, strHeight.length - 2));
      if (heightpx > 0) {
        return parseInt(window.innerHeight - 56 - heightpx) + "px";
      } else {
        return parseInt(window.innerHeight - 56) + "px";
      }
    },
    // 鼠标悬浮时显示展开图标
    getResizeEnter() {
      this.resizeIconFlag = true;
    },
    // 鼠标悬浮时显示展开图标
    getResizeLeave() {
      this.resizeIconFlag = false;
    },
    // 根据宽度的变化是否图标
    getShowDragIcon(value) {
      let maxwidth = this.candidateWeight().substring(
        0,
        this.candidateWeight().length - 2
      );
      let width = this.$refs.resizeRef.style.width.substring(
        0,
        this.$refs.resizeRef.style.width.length - 2
      );
      if (value == "shouqi" && Number(maxwidth) < Number(width)) {
        return true;
      } else if (value == "zhankai" && Number(maxwidth) > Number(width)) {
        return true;
      } else {
        return false;
      }
    },
    // 候选人列表宽度
    getcandidateWeight() {
      this.candidateNormalWeight = parseInt(window.innerWidth * 0.3) + "px";
      this.$refs.resizeRef.style.width = this.candidateNormalWeight;
    },
    ebFn(a, b, totalCount) {
      this.left = a;
      this.right = b;
      this.totalCount = totalCount;
    },
    clickFn() {
      this.isShowMeiHuaBox = false;
      this.isShowTypeBox = false;
      this.isShowButtonBox = false;
    },
    // 只有选中颜色才能修改，点击清空时不更改颜色
    changeLegend(value, data) {
      if (value) {
        this.rightColorData.map((color) => {
          if (color.id == data.id) {
            color.legend = value;
          }
        });
      } else {
        this.rightColorData.map((item) => {
          if (item.id == data.id) {
            item.legend = "";
          }
        });
      }
    },
    // 获取图例颜色数据
    async getAppoStatusLegends() {
      let res = await getAppoStatusLegends();
      this.rightColorData = res.data;
    },
    // 保存图例颜色
    async saveStatusLegends() {
      let saveColor = [];
      this.rightColorData.map((item) => {
        let saveitem = {
          id: item.id,
          legend: item.legend,
        };
        saveColor.push(saveitem);
      });
      let res = await saveStatusLegends(saveColor);
      if (res.result) {
        this.showColorFlag = false;
        //保存成功后刷新服务预约列表
        this.$refs.appointmentList.getAppointmentList();
      }
    },
    // 取消分配或分配后回到服务预约列表式更新权限接口
    getbtnJurisdiction() {
      this.updateJurisdiction = true;
    },
    // 修改策略
    strategyChange() {
      this.$refs.candidateNeutralRef.recommendCandidatesST(
        this.currentServeAppointment,
        this.strategyValue
      );
    },
    // 获取计划策略列表
    async getPolicyList() {
      let res = await getPolicyList();
      this.strategyOptions = res.data.dataList;
      // 只读
      this.strategyReadOnly = res.data.readOnly;
      this.isShowCandidateTable = "candidate";
    },
    // 推荐策略弹框确定按钮
    saveCanditate() {
      this.recommendShow = false;
    },
    // 显示推荐策略弹框
    recommendShowFn() {
      this.recommendShow = true;
    },
    // 退出分配成功页面时，清空选中状态
    closeSuccess(value) {
      // 分配成功后需要刷新服务预约详情
      if (value || this.updateJurisdiction === true) {
        this.$refs.appointmentList.getAppointmentList(
          "",
          "",
          "sssigned",
          this.$refs.candidateNeutralRef.serviceappointmentId
        );
      } else {
        this.$refs.appointmentList.getAppointmentList();
      }
      this.isShowCandidateTable = "order";
      this.$refs.candidateNeutralRef.currentServer = "";
      this.$refs.candidateNeutralRef.chooseCandidateActive = "";
      this.$refs.candidateNeutralRef.radioServiceArea = "";
      this.$refs.candidateNeutralRef.chooseCandidateActive = "";
      this.$refs.candidateNeutralRef.showBatchDispatch = 0;
      this.$refs.candidateNeutralRef.formData = [];
      this.$refs.candidateNeutralRef.serviceAreaData = [];
      this.updateJurisdiction = false;
    },
    // 关闭颜色提示框
    closeColorPopover() {
      this.showColorFlag = false;
    },
    getInputValue() {
      this.inputValue = this.inputValueStr;
    },
    //获取对象操作权限
    async getObjJurisdiction(id) {
      let resultData = await getObjectPermission({ id: id });
      if (resultData.result) {
        if (id == "serviceappointment") {
          this.appoJurisdiction = resultData.data;
        } else if (id == "serviceresource") {
          this.ResourceJurisdiction = resultData.data;
        }
      }
    },
    // 计划策略禁选：计划策略可选的情况下，设置禁选
    setNoChange(value) {
      if (!this.strategyReadOnly) {
        this.strategyNoChange = value;
      }
    },
    // 派遣成功回调函数
    // data:派遣成功回显数据
    // teype:分配or派遣
    serviceAppoSuccess(data, type) {
      this.candidateSuccessData = [
        {
          label: this.$i18n.t(
            "label.service.ganttBaiduMap.scheduled.starttime"
          ),
          value: "",
        },
        {
          label: this.$i18n.t("label.service.ganttBaiduMap.scheduled.endtime"),
          value: "",
        },
        {
          label: this.$i18n.t("c1285"),
          value: "",
        },
      ];
      if (type == "distribution") {
        this.candidateServeData = [
          {
            label: this.$i18n.t("label.ServiceCloud.fuwuquyu"),
            value: "",
          },
        ];
      } else {
        this.candidateServeData = [
          {
            label: this.$i18n.t("label.ServiceCloud.fuwuquyu"),
            value: "",
          },
          {
            label: this.$i18n.t("label.service.gantt.service.resources"),
            value: "",
          },
        ];
      }
      data.map((item) => {
        if (item.startTime) {
          if (this.candidateSuccessData[0].value == "") {
            this.candidateSuccessData[0].value = item.startTime;
          } else {
            this.candidateSuccessData[0].value =
              this.candidateSuccessData[0].value + "," + item.startTime;
          }
        }
        if (item.endTime) {
          if (this.candidateSuccessData[1].value == "") {
            this.candidateSuccessData[1].value = item.endTime;
          } else {
            this.candidateSuccessData[1].value =
              this.candidateSuccessData[1].value + "," + item.endTime;
          }
        }
        if (item.duration) {
          if (this.candidateSuccessData[2].value == "") {
            this.candidateSuccessData[2].value = item.duration;
          } else {
            this.candidateSuccessData[2].value =
              this.candidateSuccessData[2].value + "," + item.duration;
          }
        }
        if (item.region) {
          if (this.candidateServeData[0].value == "") {
            this.candidateServeData[0].value = item.region;
          } else {
            this.candidateServeData[0].value =
              this.candidateServeData[0].value + "," + item.region;
          }
        }
        if (item.resources) {
          if (this.candidateServeData[1].value == "") {
            this.candidateServeData[1].value = item.resources;
          } else {
            this.candidateServeData[1].value =
              this.candidateServeData[1].value + "," + item.resources;
          }
        }
      });
      if (this.candidateSuccessData[2].value) {
        this.candidateSuccessData[2].value =
          this.candidateSuccessData[2].value + this.$i18n.t("hour");
      }
      this.isShowCandidateTable = "success";
      this.dispatchOrDistribution = type;
    },
    //批量派遣
    batchDispatch() {
      if (this.$refs.appointmentList.checkedList.length == 0) {
        this.$message.warning(this.$i18n.t("c845"));
        return false;
      }
      let arr = [];
      this.$refs.appointmentList.checkedList.forEach((res) => {
        if (res.status == this.$i18n.t("label.projectManagement.planned")) {
          //已计划 label.projectManagement.planned
          let option = {
            id: res.id,
            status: "已派遣", //已派遣 label.projectManagement.dispatched
          };
          arr.push(option);
        }
      });
      if (arr.length === 0) {
        this.$message.warning(this.$i18n.t("c846"));
      } else if (arr.length > 0) {
        this.$refs.appointmentList.save(JSON.stringify(arr));
      }
    },
    //更改时间
    changeDate() {
      // this.$refs.appointmentList.getAppointmentList()
    },
    // 打开候选人列表
    openCandidate(data) {
      this.isShowCandidateTable = "candidate";
      this.currentServeAppointment = data;
      this.buttonPermission(data.id);
      this.getPolicyList(data.id);
      this.strategyValue = data.schedulingpolicyid; //策略id
      this.$refs.candidateNeutralRef.reconmendLoading = true;
      this.$refs.candidateNeutralRef.recommendCandidatesST(data);
      this.getcandidateWeight();
    },
    // 获取当前服务预约记录下显示按钮权限
    async buttonPermission(id) {
      let res = await buttonPermission({
        id: id,
      });
      if (res.data) {
        // 派工平台候选人
        this.$refs.candidateNeutralRef.isShowDispatch = res.data.dispatch;
        this.$refs.candidateNeutralRef.isShowDistribution =
          res.data.distribution;
        // 派工平台
        this.$refs.appointmentList.isShowDispatch = res.data.dispatch;
        this.$refs.appointmentList.isShowDistribution = res.data.distribution;
      }
    },
    // 服务预约列表跳转指定日期
    locationGrantt(d) {
      this.$refs.Fullcalendar2Grantt.locationToGrantt(d);
    },
    // 获取区域
    async getDefaultArea(viewObj) {
      let res = await findMyServiceTerritory();
      let idArr = [];
      if (res.result) {
        res.data.map((item) => {
          if (item.ischecked === "true") {
            idArr.push(item.id);
            if (
              item.childServiceTerritory &&
              item.childServiceTerritory.length > 0
            ) {
              item.childServiceTerritory.map((itemChild) => {
                idArr.push(itemChild.id);
              });
            }
          }
        });
      }
      this.treeString = idArr.toString();
      // 切换区域：switch
      this.$refs.appointmentList?.freshList(
        viewObj.id,
        idArr.toString(),
        "switch"
      );
    },
    //获取服务预约视图
    async serviceAppointmentView(a, id) {
      let result = await getServiceAppointmentView();
      if (result.result == true) {
        this.appointNameArr = result.data;
        this.appointNameArr.forEach((res) => {
          if (a == "yes") {
            // if (res.isdefault == '1') {
            //   this.appointName = res
            // }
            // if (res.id == "aec2021CCB66896U4ZyB") {
            //   this.appointName = res;
            // }
            this.appointName = result.data[0];
          } else if (a == "no") {
            if (res.id == this.appointName.id) {
              this.appointName = res;
            }
          } else if (a == "newBuild") {
            if (res.id == id) {
              this.appointName = res;
            }
          }
        });
        // this.appointName = result.data[0];
        this.getDefaultArea(this.appointName);
        this.$Bus.$emit("get-appointment-list", this.appointName);
      }
    },
    //获取当前时间
    getNowTime() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}`;
      this.dateValue = defaultDate;
    },
    // 获取“显示未与服务区域关联的服务预约”是否被勾选
    async getAppointmentIsChecked() {
      // 查显示未与区域关联的服务预约的权限
      let ispermission = await this.getSystemPermission("aab010021");
      this.ispermissionRegional = ispermission;
      // true有权限查是否被勾选，false没权限则不勾选
      if (ispermission) {
        let result = await getAppointmentChecked();
        if (result.result == true) {
          this.checkedRegional = result.data.ischecked == "true" ? true : false;
        }
      } else {
        this.checkedRegional = ispermission;
      }
    },
    // 更改“显示未与服务区域关联的服务预约”是否被勾选
    async saveAppointmentIsChecked() {
      let opt = this.checkedRegional == true ? "true" : "false";
      await saveAppointmentChecked({ ischecked: opt });
    },
    //获取KPI时间
    getKPITime(data) {
      this.KPIData.map((item) => {
        if (item.type == "sumTime") {
          // 小时 label.ems.hour
          item.time = data.sumTime + this.$i18n.t("label.ems.hour");
        } else if (item.type == "injeopardySum") {
          item.time = data.injeopardySum;
        } else if (item.type == "violationListSum") {
          item.time = data.violationListSum;
        } else if (item.type == "completeSum") {
          item.time = data.completeSum + "/" + data.orderSum;
          if (data.completeSum == data.orderSum && data.completeSum != 0) {
            item.imgSrc = "greenYes";
          } else {
            item.imgSrc = "kpiyes";
          }
        } else if (item.type == "avgTime") {
          if (data.estimatedtraveltime) {
            item.time =
              parseInt(data.estimatedtraveltime / data.orderSum) +
              this.$i18n.t("label.ems.hour");
          } else {
            item.time = "0" + this.$i18n.t("label.ems.hour");
          }
        }
      });
    },
    // 从甘特表组件传过来的同步日历日期
    ganttDateValueMethod(value) {
      this.dateGanttValue = value.toString();
      if (this.checkedDate) {
        var date = new Date(this.dateGanttValue);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        var d = date.getDate();
        d = d < 10 ? "0" + d : d;
        // var h = date.getHours() - 8;
        // h = h < 10 ? "0" + h : h;
        this.dateValue = y + "-" + m + "-" + d;
        // this.dateValue = this.dateGanttValue
      }
    },
    checkedDateMethod() {
      if (this.checkedDate) {
        var date = new Date(this.dateGanttValue);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        var d = date.getDate();
        d = d < 10 ? "0" + d : d;
        // var h = date.getHours() - 8;
        // h = h < 10 ? "0" + h : h;
        this.dateValue = y + "-" + m + "-" + d;
        this.ispickerShow = false;
        // this.$refs.appointmentList.getAppointmentList()
      } else {
        this.ispickerShow = true;
      }
    },
    //关闭编辑弹框
    closeEdit(res) {
      this.dialogEdit = false;
      if (res == "again") {
        this.serviceAppointmentView("no");
      }
    },
    //关闭新建弹框
    closeNewBuild(res, id) {
      this.dialogNewBuild = false;
      if (res == "again") {
        this.serviceAppointmentView("newBuild", id);
      }
    },
    handleClick() {},
    // 获取当前用户可见的所有服务区域接口
    async fastSwitching() {
      this.showListLoading = true;
      this.listIscheckedData = [];
      this.listData = [];
      let { data } = await findMyServiceTerritory({});
      this.listData = data;
      this.showListLoading = false;
      // 获取“显示未与服务区域关联的服务预约”是否被勾选
      this.getAppointmentIsChecked();
      this.$nextTick(() => {
        this.treeString = this.$refs.tree.getCheckedKeys().join();
      });
      // 获取服务预约列表数据的所有ID
      this.getAllID(this.listData);
      // 获取服务预约列表数据的所有选中状态
      this.getAllIschecked(this.listData);
    },

    // 处理父节点选中=》子节点全部选中   子节点选中的话=》父节点不会选中的逻辑
    checkTree(data) {
      //获取当前选中的节点
      let node = this.$refs.tree.getNode(data.id);
      this.checkChild(node);
      this.cancelChild(node);
    },
    checkChild(node) {
      //  如果是父节点 并且被选中
      if (node.childNodes.length !== 0 && node.checked == true) {
        node.childNodes.forEach((item, index, array) => {
          array[index].checked = true;
          this.checkChild(array[index]);
        });
      }
    },
    cancelChild(node) {
      //  如果是父节点 并且没被选中
      if (node.childNodes.length !== 0 && node.checked == false) {
        node.childNodes.forEach((item, index, array) => {
          array[index].checked = false;
          this.cancelChild(array[index]);
        });
      }
    },
    // 获取服务预约列表数据的所有ID
    getAllID(data) {
      for (var key in data) {
        if (data[key].childServiceTerritory) {
          this.listIdData.push(data[key].id);
          this.getAllID(data[key].childServiceTerritory);
        } else {
          this.listIdData.push(data[key].id);
        }
      }
    },
    // 获取服务预约列表数据的所有选中状态
    getAllIschecked(data) {
      for (var key in data) {
        if (data[key].childServiceTerritory) {
          if (data[key].ischecked == "true") {
            this.listIscheckedData.push(data[key].id);
          }
          this.getAllIschecked(data[key].childServiceTerritory);
        } else {
          if (data[key].ischecked == "true") {
            this.listIscheckedData.push(data[key].id);
          }
        }
      }
    },
    // 搜索服务预约区域
    filterNode(value, listData) {
      if (!value) return true;
      return listData.name.indexOf(value) !== -1;
    },
    //显示与隐藏树
    dialogTree() {
      this.dialogTreeTrue = true;
      this.showListLoading = true;
      this.isShowService = false;
      this.fastSwitching();
    },
    // 全选服务区域
    serviceAreaAll() {
      this.$refs.tree.setCheckedKeys(this.listIdData);
    },
    // 取消全选服务区域
    canceiServiceAreaAll() {
      this.$refs.tree.setCheckedKeys([]);
    },
    // 取消区域
    cancleService() {
      this.dialogTreeTrue = false;
      this.isShowService = true;
      // 清空搜索框内容
      this.filterText = "";
      this.getAppointmentIsChecked();
    },
    // 保存区域更改
    async saveService() {
      this.showButtonLoading = true;
      this.dialogTreeTrue = false;
      this.otherListIdData = this.listIdData.filter((item) => {
        return this.$refs.tree.getCheckedKeys().indexOf(item) === -1;
      });
      this.otherListIdData = Array.from(new Set(this.otherListIdData));
      let option = {
        checkedServiceTerritoryids: this.$refs.tree.getCheckedKeys().join(),
        uncheckedServiceTerritoryids: this.otherListIdData.join(),
      };
      let result = await saveCheckServiceTerritory(option);
      if (result.result == true) {
        this.showButtonLoading = false;
        this.treeString = this.$refs.tree.getCheckedKeys().join();
        this.saveAppointmentIsChecked();
        this.isShowService = true;
        // 清空搜索框内容
        this.filterText = "";
        this.$bus.$emit("save-saveAppointmentIsChecked", this.checkedRegional);
        this.getDefaultArea(this.appointName);
        this.$Bus.$emit("upadteArea");
      }
    },
    // 派遣控制台设置
    dispatchConsoleSetUp() {
      this.dispConSetUp = true;
    },
    dispConSetUpCancle() {
      this.dispConSetUp = false;
    },
    dispConSetUpConfrim() {
      this.dispConSetUp = false;
    },
    // 删除自定义服务预约视图，弹窗
    deleteCustomViewCancle() {
      this.delCustomView = false;
    },
    deleteCustomViewConfrim() {
      this.serviceAppointmentView("yes");
      this.delCustomView = false;
    },

    //显示美化弹框
    // gattnMeiHua() {
    //   this.isShowMeiHuaBox = true;
    // },
    //更改服务类型
    changeType() {
      this.isShowTypeBox = true;
    },
    //选中弹框中的服务类型
    sendType(item) {
      this.appointName = item;
      this.getDefaultArea(this.appointName);
      this.isShowTypeBox = false;
    },
    //操作服务类型
    editType() {
      this.isShowButtonBox = true;
    },
    editButton(item) {
      if (item == this.$i18n.t("label.import.page1.newcreate")) {
        //新建 label.import.page1.newcreate
        this.dialogNewBuild = true;
      } else if (item == this.$i18n.t("label.emailtocloudcc.delete")) {
        //删除 label.emailtocloudcc.delete
        this.delCustomView = true;
      } else if (item == this.$i18n.t("label.emailtocloudcc.edit")) {
        //编辑 label.emailtocloudcc.edit
        this.dialogEdit = true;
      }
      this.isShowButtonBox = false;
    },
    /**
     * getSystemPermission:通用权限查找接口
     * @param {String} id:查询权限对应id
     */
    async getSystemPermission(id = "") {
      // 是否有权限
      let flag = null;
      let res = await getSystemPermission({ id });
      if (res.data && res.data.ispermission) {
        // 有权限
        flag = res.data.ispermission;
      }
      return flag;
    },
    /**
     * 更新列表数据
     */
    updateAppList() {
      this.$refs.appointmentList.getAppointmentList();
    },
  },
};
</script>
<style lang="scss">
.elPopoverTimeLimit {
  // 时间范围内选项
  .timeLimitItems {
    .el-form {
      .el-form-item {
        margin-bottom: 6px !important;
        .el-form-item__content {
          line-height: 24px !important;
          margin-left: 0px !important;
          .el-checkbox-group {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    div:last-of-type {
      font-size: 12px;
      color: #080707;
    }
  }
}
</style>
<style lang="scss" scoped>
.dispatchingUnits {
  display: flex;
  width: 100%;
  height: calc(100% - 10px);
  overflow: hidden;
  .leftSidePart {
    background: #fff;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    .leftSidePartBox {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }
    .resize-save {
      position: absolute;
      top: 0;
      right: 3px;
      bottom: 0;
      left: 0;
      overflow-x: hidden;
    }
    .drag-icon {
      position: absolute;
      right: -12px;
      top: 200px;
      width: 26px;
      height: 26px;
      z-index: 9999;
      svg {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 1px solid;
        box-shadow: 3px 4px 27px 1px rgb(149 151 155 / 60%);
        border: 1px solid #f9f2f2;
      }
    }
    .resize-bar {
      height: inherit;
      resize: horizontal;
      opacity: 0;
      cursor: ew-resize;
      min-width: 430px; /* 最小宽度 300px */
      overflow: scroll;
    }
    .resize-line {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border-left: 2px solid #bbb;
      pointer-events: none;
    }
    .resize-bar:hover ~ .resize-line,
    .resize-bar:active ~ .resize-line {
      border-left: 2px solid #2d6cfc;
    }
    .resize-bar::-webkit-scrollbar {
      width: 430;
      height: inherit;
    }
    .globalFunctionIcon {
      width: 100%;
      height: 48px;
      display: flex;
      // justify-content: flex-end;
      justify-content: space-between;
      align-items: center;
      padding: 15px 3%;
      box-sizing: border-box;
      border-bottom: solid 1px #dddfe8;
      background: #f8f9fb;
      // 策略
      // .strategy {
      //   .el-select {
      //     width: 180px;
      //     height: 32px;
      //     margin-left: 10px;
      //   }
      //   ::v-deep .el-input {
      //     height: 32px;
      //   }
      //   ::v-deep .el-input__inner {
      //     height: 32px;
      //     line-height: 32px;
      //   }
      //   ::v-deep .el-input__suffix-inner {
      //     line-height: 32px !important;
      //     height: 32px !important;
      //   }
      //   ::v-deep .el-select__caret {
      //     line-height: 32px !important;
      //     height: 32px !important;
      //   }
      // }
      // 预约区域选择
      .reservationArea {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .reservation-icon {
          width: 25px;
          height: 25px;
          background: #ffffff;
          border-radius: 3px;
          border: 1px solid #eaeaec;
        }
        .icon {
          display: inline-block;
          margin: 5px;
          cursor: pointer;
        }
        ::v-deep .el-popper {
          border: 0;
        }
      }
      // 派遣控制台
      .dispatchConsole {
        display: none;
        width: 25px;
        height: 25px;
        border: solid 1px #dddfe8;
        background: white;
        margin-left: 15px;
        .icon {
          display: inline-block;
          margin: 5px;
          width: 14px;
        }
      }
    }
    // 所有服务预约
    .serviceAppointment {
      width: 100%;
      height: calc(100% - 55px);
      background: #fff;
      .active {
        background: #006dcc;
        color: #fff;
      }
      .appointment-type {
        // margin-bottom: 12px;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 3%;
        box-sizing: border-box;
        font-size: 16px;
        color: #2e324d;
        border-bottom: 1px solid #dddfe8;
        .name {
          cursor: pointer;
          position: relative;
          .icon {
            display: inline-block;
            width: 12px;
            height: 12px;
          }
          span {
            max-width: 260px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            float: left;
          }
          .appoint-box {
            position: absolute;
            left: 0;
            top: 30px;
            border: 1px solid #dddfe8;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
            border-radius: 1px;
            width: 250px;
            background: #fff;
            z-index: 2;
            padding: 10px 0;
            font-size: 14px;
            li {
              padding: 0 12px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            li:hover {
              background: #006dcc;
              color: #fff;
            }
          }
        }
        .function {
          cursor: pointer;
          position: relative;
          width: 14px;
          .icon {
            display: inline-block;
            width: 14px;
            height: 14px;
          }
          .deit-func {
            font-size: 12px;
            position: absolute;
            top: 30px;
            right: 0;
            background: #ffffff;
            border: 1px solid #dddfe8;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
            border-radius: 1px;
            width: 80px;
            padding: 10px 0;
            z-index: 2;
            li {
              padding: 5px 0;
              text-align: center;
            }
            li:hover {
              background: #006dcc;
              color: #fff;
            }
          }
        }
      }
      // 时间筛选器
      .timeFilter {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: space-between;
        padding: 20px 3%;
        box-sizing: border-box;
        border-bottom: solid 1px #dddfe8;
        align-items: center;
        // 正在选定时间范围内预约
        .timeLimit {
          .theClock {
            display: flex;
            align-items: center;
            height: 31px;
            box-shadow: 0 0 1px 0 rgba(0, 54, 204, 0.4);
            border-radius: 3px;
            border: solid 1px #dddfe8;
            .icon {
              cursor: pointer;
              display: inline-block;
              margin: 0 4px;
              width: 15px;
              &:nth-of-type(1) {
                margin-left: 6px;
              }
            }
          }
        }
        // 日期选择
        .theCalendar {
          width: 50%;
          display: flex;
          align-items: center;
          margin-left: 5%;
          // 输入框前面文字
          .theLevelOf {
            width: 20%;
            font-size: 12px;
            color: #9fa3b4;
            letter-spacing: 0;
            white-space: nowrap;
          }
          // 日期输入框
          .el-input {
            width: 80%;
            margin-left: 8px;
            ::v-deep .el-input__inner {
              // min-width: 80%;
              height: 32px;
              line-height: 32px;
              padding-right: 5px;
            }
            // 日期输入框内图标
            ::v-deep .el-input__icon {
              line-height: 32px;
            }
          }
        }
        // 匹配甘特表日期
        .ganttChart {
          font-size: 14px;
          input {
            margin-left: 0;
            vertical-align: -2px;
          }
        }
      }

      .appointment-list-title {
        border-bottom: 1px solid #dddfe8;
        // height: 90px;
        box-sizing: border-box;
        padding: 10px 3%;
        .appointment-search {
          margin-bottom: 12px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          .button {
            text-align: center;
            cursor: pointer;
            float: left;
            border: 1px solid #dddfe8;
            padding: 5px 10px;
            color: #006dcc;
          }
          // 自动计划
          // .automaticPlan {
          //   margin-left: 100px;
          // }
          .plan {
            border-radius: 3px 0 0 3px;
          }
          .dispatc {
            border-left: none;
            border-right: none;
          }
          .icon {
            border-radius: 0 3px 3px 0;
            width: 30px;
            position: relative;
            .dialog-box {
              position: absolute;
              top: 30px;
              right: 0;
              background: #ffffff;
              border: 1px solid #dddfe8;
              box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
              border-radius: 1px;
              width: 120px;
              padding: 10px 0;
              z-index: 2;
              li {
                padding: 0 10px;
                color: #006dcc;
                text-align: left;
                img {
                  margin-right: 5px;
                }
              }
            }
          }
        }
        .refresh-list {
          margin-left: 8px;
          cursor: pointer;
          &:hover {
            color: #006dcc;
          }
        }
        ::v-deep .el-input__inner {
          border-radius: 3px;
          height: 30px;
        }
        ::v-deep .el-input__suffix {
          right: 15px;
          top: -5px;
        }
      }
    }
    // 区域
    .selectArea {
      width: 100%;
      height: calc(100% - 55px);
      overflow: auto;
      .areaTitle {
        width: 100%;
        padding: 14px 0 14px 40px;
        font-size: 14px;
      }
      // 显示未与区域关联的服务预约
      .regionalCorrelation {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 10px;
        font-size: 14px;
        border-bottom: 1px solid #dcdcdc;
        border-top: 1px solid #dcdcdc;
        input {
          vertical-align: -2px;
        }
      }
      // 搜索服务区域
      .searchServiceArea {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding: 20px 10px;
        .searServArea {
          width: 40%;
          ::v-deep .el-input {
            width: 100%;
          }
          ::v-deep .el-input__inner {
            // width: 209px !important;
            height: 30px !important;
          }
        }
        .allAndCancel {
          display: flex;
          ::v-deep .el-button {
            padding: 7px 20px !important;
          }
        }
      }
      // 服务区域展开列表
      .searchServiceAreaList {
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid #dcdcdc;
        padding: 20px 0 100px 10px;
      }
      // 取消和保存
      .cancleAndSave {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 20px 10px;
        box-sizing: border-box;
        ::v-deep .el-button {
          padding: 7px 20px !important;
        }
      }
    }
  }
  .candidateBox {
    background-color: #fff;
    position: relative;
    .candidateTitle {
      border-bottom: 1px solid #dddfe8;
      padding: 11px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      ::v-deep .el-select {
        padding-left: 0;
      }
      ::v-deep .el-input__inner {
        height: 30px !important;
        line-height: 30px !important;
      }
      ::v-deep .el-input__icon {
        height: 30px;
        line-height: 30px;
      }
    }
    .optimalAllocation {
      display: flex;
      padding: 10px;
      // border-bottom: 1px solid #dddfe8;
      font-size: 14px;
      height: 40px;
      justify-content: space-between;
      background: #f8f9fb;
      .show-optimal {
        font-weight: bold;
        color: #006dcc;
      }
    }
    .neutralTime {
      display: flex;
      flex-direction: column;
      padding: 12px 10px;
      border-bottom: 1px solid #dddfe8;
      font-size: 12px;
      background: #f8f9fb;
    }
    .resize-save {
      position: absolute;
      top: 0;
      right: 3px;
      bottom: 0;
      left: 0;
      overflow-x: hidden;
    }
    .drag-icon {
      position: absolute;
      right: -12px;
      top: 200px;
      width: 26px;
      height: 26px;
      z-index: 9999;
      svg {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 1px solid;
        box-shadow: 3px 4px 27px 1px rgb(149 151 155 / 60%);
        border: 1px solid #f9f2f2;
      }
    }
    .resize-bar {
      height: inherit;
      resize: horizontal;
      opacity: 0;
      cursor: ew-resize;
      min-width: 430px; /* 最小宽度 300px */
      overflow: scroll;
    }
    .resize-line {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border-left: 1px solid #bbb;
      pointer-events: none;
    }
    .resize-bar:hover ~ .resize-line,
    .resize-bar:active ~ .resize-line {
      border-left: 1px solid #2d6cfc;
    }
    .resize-bar::-webkit-scrollbar {
      width: 430;
      height: inherit;
    }
  }
  .candidate-success {
    background: #fff;
    width: 30%;
    height: 100%;
    border-right: 2px solid #dddfe8;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 18px 12px;
    .success-close {
      display: flex;
      justify-content: end;
    }
    .success-tip {
      font-weight: bold;
      color: #088d3d;
      font-size: 17px;
      text-align: center;
      margin: 30px 0 32px 0;
    }
    .success-icon {
      text-align: center;
      margin-bottom: 33px;
      svg {
        width: 60px;
        height: 60px;
      }
    }
    .success-content {
      border-top: 1px solid #eeeeee;
      .success-content-title {
        color: #333333;
        font-size: 16px;
        margin: 20px 0;
      }
      .success-key {
        display: flex;
        margin-bottom: 12px;
        .success-label {
          font-size: 14px;
          color: #666666;
          margin-right: 30px;
          width: 84px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .success-value {
          color: #333333;
          // max-width: 244px;
          max-width: calc(100% - 120px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .success-serve {
          font-size: 14px;
          color: #666666;
          margin-right: 58px;
        }
      }
    }
    .success-btn {
      margin-top: 80px;
      text-align: center;
      ::v-deep .el-button {
        width: 160px;
      }
    }
  }
}

.rightSidePart {
  flex: 1;
  box-sizing: border-box;
  background: #fff;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  .tabSwitch {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    // padding: 9px 0px 0px 8px;
    .tolTimeBox {
      position: absolute;
      top: 17px;
      right: 10px;
      display: flex;
      font-size: 12px;
      div {
        margin-right: 10px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 6px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}
.celue {
  height: 100%;
  .celue-item-label {
    font-size: 14px;
    color: #999999;
    line-height: 19px;
    margin-bottom: 12px;
  }
  .celue-item {
    margin-bottom: 20px;

    .celue-item-option {
    }
  }
  .celue-list {
    height: 40%;
  }
  .celue-objectname {
    width: 100%;
    color: #999999;
    background: #f7f7fa;
    height: 32px;
    line-height: 32px;
    padding-left: 12px;
    font-size: 14px;
    margin: 4px 0;
  }
  .celue-rule {
    margin-top: 12px;
    .celue-rule-item {
      margin-bottom: 8px;
      font-size: 14px;
      display: flex;
      .celue-number {
        width: 20px;
        height: 20px;
        margin: 0 8px 0 12px;
        display: inline-block;
        border-radius: 50%;
        color: #576c9c;
        border: 1px solid #d8eeff;
        text-align: center;
      }
      .celue-div {
        width: 40px;
        height: 20px;
      }
    }
    .celue-rule-title {
      width: 76px;
      display: inline-block;
      color: #999999;
    }
    .celue-rule-content {
      display: inline-block;
      padding-left: 12px;
      color: #333333;
    }
  }
}
.color-box {
  padding: 8px 20px;
  display: flex;
  flex-direction: column;
  .color-btn {
    width: 100%;
    text-align: right;
    padding: 20px 20px 0 0;
  }
  ::v-deep .el-button {
    width: 60px;
    height: 32px;
    line-height: 32px;
    padding: 0;
  }
  .color-title-box {
    display: flex;
    justify-content: space-between;
  }
  .color-title {
    font-size: 14px;
    font-weight: 500;
    color: rgba(51, 51, 51, 0.9);
  }
  .color-tip {
    font-weight: 400;
    color: #999999;
    margin-top: 12px;
  }
  .color-content {
    display: flex;
    min-height: 160px;
    background: #f7f7fa;
    margin-top: 10px;
    border-radius: 0px 0px 2px 2px;
  }
  .status-left {
    width: 100%;
    padding: 26px 0 26px 40px;
    display: flex;
    flex-wrap: wrap;
  }
  .status-right {
    width: 208px;
  }
  .color-line {
    border: 1px solid #e1e1e1;
    margin: 21px 0;
    height: 118px;
  }
}
.status-box {
  display: flex;
  align-items: center;
  width: 50%;
  ::v-deep .el-color-picker__trigger {
    width: 24px;
    height: 24px;
  }
  .status-value {
    margin-left: 12px;
  }
}
// 甘特图+地图tabs样式
::v-deep .el-tabs {
  width: 100%;
  height: 100%;
}
::v-deep .el-tabs__header {
  width: 99%;
  margin: 0 0 10px 0 !important;
  .el-tabs__nav-wrap {
    padding-left: 16px;
    // 被选中的tabs的__样式
    .el-tabs__nav {
      // .el-tabs__active-bar.is-top:last-of-type {
      //   width: 60px !important;
      //   transform: translateX(66px) !important;
      // }
    }
    .el-tabs__item {
      height: 48px;
      line-height: 48px;
    }
  }
}
::v-deep .el-tabs__content {
  height: calc(100% - 55px) !important;
  margin-left: 16px;
  #pane-first {
    height: 100%;
    .app-container {
      height: 100%;
      .gantt-container {
        height: calc(100% - 110px);
        overflow: hidden;

        .fc-direction-ltr .fc-button-group > .fc-button {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          background-color: #fff;
          color: #1d2033;
          border: 1px solid #dddfe8;
        }

        .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        .fc-direction-ltr .fc-button-group > .fc-button:last-child {
          border-top-right-radius: 0.25em;
          border-bottom-right-radius: 0.25em;
        }
        //点击增加，减少天数按钮
        .fc .fc-button-primary:focus {
          // background: #DDDFE8;
          border: 1px solid #dddfe8;
          // color: #006DCC;
          box-shadow: none !important;
        }
        //悬浮增加，减少天数按钮
        .fc .fc-button-primary:hover {
          background: #dddfe8;
          border: 1px solid #dddfe8;
          color: #006dcc;
          box-shadow: none !important;
        }
        .fc .fc-button-primary:not(:disabled).fc-button-active,
        .fc .fc-button-primary:not(:disabled):active {
          color: #006dcc;
          background: #dddfe8;
        }
        // 今天按钮：日期是当天添加颜色背景色
        .fc .fc-button-primary:disabled {
          color: #006dcc;
          background: #dddfe8;
        }
        .fc .fc-resource-timeline .fc-resource-group:not([rowspan]) {
          background: #d8eeff !important;
        }
        .fc .fc-timeline-header-row-chrono .fc-timeline-slot-frame {
          justify-content: center;
        }
        .fc .fc-timeline-slot-cushion {
          color: #2e324d;
          font-weight: 200;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
