<!-- distributionMap -->
<template>
  <div class="distributionMap">
    <div class="distributionMapHeather">
      <div class="H-left">
        <el-radio-group v-model="radio" @change="handleRadioChange">
          <el-radio
            v-for="(item, index) in radioBox"
            :label="item.id"
            :key="index"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
      </div>
      <div class="H-midlle">
        <el-checkbox v-model="overlap">{{
          $t("label.ServiceCloud.Overlapping")
        }}</el-checkbox>
      </div>
      <div class="H-right">
        <el-select
          v-model="analysisType"
          placeholder="请选择"
          @change="handleChange"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="mapContainer">
      <!-- 筛选区域 -->
      <div class="search_container">
        <div class="select_TBoxHe">
          <!-- 勾选重叠显示派工时，仅支持筛选项“服务预约编号”进行筛选，其他筛选项禁用。 -->
          <!-- 服务预约编号 -->
          <el-input
            v-model="fuwuNo"
            :placeholder="$t('label.ServiceCloud.yuyuebainhao')"
            prefix-icon="el-icon-search"
            clearable
            size="mini"
            @keyup.enter.native="handleSearch"
            ref="mark"
          ></el-input>
          <!-- 资源名称 -->
          <el-input
            v-model="fuwuName"
            :placeholder="$t('label.service.ganttBaiduMap.service.resourname')"
            prefix-icon="el-icon-search"
            clearable
            size="mini"
            v-show="radio === 'resource'"
            @keyup.enter.native="handleSearch"
          ></el-input>
          <!-- 加入 资产序号为-->
          <!-- 资产名称 -->
          <el-input
            v-model="zcName"
            :placeholder="$t('label.ServiceCloud.zichanmingcheng')"
            prefix-icon="el-icon-search"
            clearable
            size="mini"
            v-show="radio === 'asset'"
            @keyup.enter.native="handleSearch"
          ></el-input>
          <!-- 资产序号 -->
          <el-input
            v-model="zcNo"
            :placeholder="$t('label.ServiceCloud.zichanxuhao')"
            prefix-icon="el-icon-search"
            clearable
            v-show="radio === 'asset'"
            size="mini"
            @keyup.enter.native="handleSearch"
          ></el-input>
          <!-- 地理位置 -->
          <el-input
            v-model="site"
            :placeholder="$t('label.ServiceCloud.diliweizhi')"
            prefix-icon="el-icon-search"
            v-show="
              radio === 'asset' ||
              radio === 'serviceappo' ||
              radio === 'resource'
            "
            clearable
            size="mini"
            @keyup.enter.native="handleSearch"
          ></el-input>
          <!-- 筛选 -->
          <el-button
            type="primary"
            size="mini"
            v-show="radio === 'asset' || radio === 1"
            @click="handleSearch"
            :loading="searchLoading"
            >{{ $t("label.filter") }}</el-button
          >
        </div>
        <div class="select_kBoxHe">
          <!-- 服务区域 -->
          <selectCheckBox
            class="select_kBox"
            :checkName.sync="serviceterritoryid"
            v-show="radio === 'serviceappo' || radio === 'resource'"
            :liData="liData"
            :lableName="$t('label.ServiceCloud.fuwuquyu') + '：'"
          />
          <!-- 优先级 -->
          <selectCheckBox
            class="select_kBox"
            :checkName.sync="priority"
            v-show="radio === 'serviceappo'"
            :liData="liDataPriority"
            :lableName="$t('label.priority') + '：'"
          />
          <!-- 状态 -->
          <selectCheckBox
            class="select_kBox"
            :checkName.sync="appoStatus"
            v-show="radio === 'serviceappo'"
            :liData="liDataState"
            :lableName="$t('label.partner.all.state') + '：'"
          />
          <!-- 资源类型 -->
          <selectCheckBox
            class="select_kBox"
            :checkName.sync="resourcetype"
            v-show="radio === 'resource'"
            :liData="liDataResourceType"
            :lableName="$t('label.service.gantt.resourcestype') + '：'"
          />
          <!-- 筛选 -->
          <el-button
            type="primary"
            size="mini"
            v-show="radio === 'serviceappo' || radio === 'resource'"
            @click="handleSearch"
            :loading="searchLoading"
            >{{ $t("label.filter") }}</el-button
          >
        </div>
      </div>
      <!-- 百度地图 -->
      <baidu-map
        class="baiduMap"
        id="dismap"
        :center="center"
        :zoom="zoom"
        @ready="handler"
        :scroll-wheel-zoom="true"
        v-if="countryCode==='CN'"
      >
        <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT">
          :showAddressBar="true" :autoLocation="true"
        </bm-navigation>
        <bm-local-search
          :keyword="keyword"
          :auto-viewport="true"
          style="width: 300px"
        ></bm-local-search>
        <!-- 周围的点 -->
        <div
          class="baiduMap-point"
          v-for="(item, index) in checkListPorint"
          :key="index"
        >
          <!-- v-if="item.icon"  有图标才打点，避免返回状态值不对，导致打点报错  -->
          <bm-marker
            :position="{ lng: item.lng, lat: item.lat }"
            :dragging="false"
            v-if="item.icon"
            :icon="{
              url: require(`@/assets/dispatchingUnits/distributionMap/${item.icon}`),
              size: { width: 30, height: 30 },
            }"
            :top="true"
            @click="infoWindowOpen(item)"
            :animation="
              item.pointType === 'quyu' || item.pointType === 'isArea'
                ? null
                : 'BMAP_ANIMATION_BOUNCE'
            "
          >
            <bm-label
              @click="infoWindowOpen(item)"
              v-if="item.content && item.content >= 0"
              :content="item.content"
              :labelStyle="{
                color: '#000',
                backgroundColor: '#ffffff',
                height: '22px',
                lineHeight: '22px',
                textAlign: 'center',
                width: '60px',
                border: '1px solid #006DCC',
                'border-radius': '2px',
              }"
              :offset="{ width: -20, height: -22 }"
            />
            <!-- 服务资源对应地图上的点的详情卡片 -->
            <bm-info-window
              class="baiduMap-point-card"
              :position="{ lng: item.lng, lat: item.lat }"
              :show="item.showFlag"
              @close="infoWindowClose(item)"
            >
              <div class="cardAllContent" v-loading="item.loading">
                <!-- 服务预约卡片 -->
                <div class="first_card" v-show="item.pointType === 'yuyue'">
                  <div class="serviceCardTitle">
                    <div class="serviceName">
                      <img
                        :src="
                          item.icon
                            ? require(`@/assets/dispatchingUnits/distributionMap/${item.icon}`)
                            : ''
                        "
                        alt=""
                      />
                      <p>
                        <b>{{ item.spotName }}</b
                        ><span>（{{ item.status }}）</span>
                      </p>
                    </div>
                    <p class="list_item">
                      <!-- 最早允许的开始 -->
                      <b
                        >{{
                          $t(
                            "label.service.appointmentList.earliest.allowedStart"
                          )
                        }}：</b
                      >{{ item.earlieststarttime }}
                    </p>
                    <!-- 到期日期 -->
                    <p class="list_item">
                      <b>{{ $t("label.quickbooks.viewlist.duedate") }}：</b
                      >{{ item.duedate }}
                    </p>
                  </div>
                  <div class="serviceCardTwo">
                    <p class="list_item">
                      <!-- 技能要求 -->
                      <b>{{ $t("label.ServiceCloud.jinengyaoqiu") }}：</b
                      >{{ item.skillList }}
                    </p>
                    <p class="list_item">
                      <!-- 所需产品： -->
                      <b>{{ $t("label.ServiceCloud.suoxuchanpin") }}：</b
                      >{{ item.productList }}
                    </p>
                  </div>
                </div>
                <!-- 服务资源卡片 -->
                <div class="second_card" v-show="item.pointType === 'ziyuan'">
                  <div class="serviceCardTitle">
                    <div class="serviceName">
                      <img
                        :src="
                          item.icon
                            ? require(`@/assets/dispatchingUnits/distributionMap/${item.icon}`)
                            : ''
                        "
                        alt=""
                      />
                      <p>
                        <b>{{ item.spotName }}</b>
                      </p>
                    </div>
                  </div>
                  <div class="serviceCardTwo">
                    <p class="list_item">
                      <!--  技能要求：-->
                      <b>{{ $t("label.ServiceCloud.jinengyaoqiu") }}：</b
                      >{{ item.skillList }}
                    </p>
                  </div>
                  <div class="cardContainer">
                    <div
                      class="cardList"
                      v-for="(list, index) in item.serviceAppoList"
                      :key="index"
                    >
                      <b>{{ list.name }}</b>
                      <!-- 计划开始时间 -->
                      <p>
                        {{
                          $t("label.service.ganttBaiduMap.scheduled.starttime")
                        }}：{{ list.schedstarttime }}
                      </p>
                      <!-- 计划结束时间 -->
                      <p>
                        {{
                          $t("label.service.ganttBaiduMap.scheduled.endtime")
                        }}: {{ list.schedendtime }}
                      </p>
                      <!-- 地址 -->
                      <p>{{ $t("label.address") }}:{{ list.address }}</p>
                    </div>
                  </div>
                  <!-- <div class="btn_container">
                    <el-button @click="dispatchingToPeople(item)" size="mini"
                      >分配派工单给他</el-button
                    >
                  </div> -->
                </div>
                <!-- 资产卡片 -->
                <div class="third_card" v-show="item.pointType === 'zichan'">
                  <div class="serviceCardTitle">
                    <div class="serviceName">
                      <img
                        :src="
                          item.icon
                            ? require(`@/assets/dispatchingUnits/distributionMap/${item.icon}`)
                            : ''
                        "
                        alt=""
                      />
                      <p>
                        <b>{{ item.spotName }}</b
                        ><span>（{{ item.warrantytype }}）</span>
                      </p>
                    </div>
                    <!-- 客户名称 -->
                    <p class="list_item">
                      <b>{{ $t("label.mr.title.customerName") }}：</b
                      >{{ item.accountname }}
                    </p>
                    <!--  资产序号：-->
                    <p class="list_item">
                      <b>{{ $t("label.ServiceCloud.zichanxuhao") }}：</b
                      >{{ item.serialnumber }}
                    </p>
                    <!--  安装日期：-->
                    <p class="list_item">
                      <b
                        >{{
                          $t("label.appexchange.installedpage.installdate")
                        }}：</b
                      >{{ item.installdate }}
                    </p>
                    <!-- 安装地址： -->
                    <p class="list_item">
                      <b
                        >{{
                          $t("label.ServiceCloud.Installation.address")
                        }}：</b
                      >{{ item.address }}
                    </p>
                    <!-- 购买日期： -->
                    <p class="list_item">
                      <b>{{ $t("label.ServiceCloud.purchase.date") }}：</b
                      >{{ item.purchasedate }}
                    </p>
                  </div>
                </div>
              </div>
            </bm-info-window>
          </bm-marker>
        </div>
        <!-- 比例尺 -->
        <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
        <!-- 缩略图 -->
        <bm-overview-map
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          :isOpen="true"
        ></bm-overview-map>
        <!-- 定位 -->
        <bm-geolocation
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          :showAddressBar="true"
          :autoLocation="true"
        ></bm-geolocation>
        <!-- 城市列表 -->
        <!-- <bm-city-list
        class="bmCityList"
        anchor="BMAP_ANCHOR_TOP_LEFT"
      ></bm-city-list> -->
      </baidu-map>
      <!-- 谷歌地图 -->
     <gmap-map v-else :center="center" :zoom="zoom" style="width: 100%; height: 100vh" @click="updateMaker">
           <!-- 周围的点 -->
        <div
          class="googleMap-point"
          v-for="(item, index) in checkListPorint"
          :key="index"
        >
          <!-- v-if="item.icon"  有图标才打点，避免返回状态值不对，导致打点报错  -->
          <gmap-marker
            :position="{ lng: item.lng, lat: item.lat }"
            v-if="item.icon"
            :icon="{
              url: require(`@/assets/dispatchingUnits/distributionMap/${item.icon}`),
              size: { width: 30, height: 30 },
            }"
            @click="infoWindowOpen(item)"
            :animation="
              item.pointType === 'quyu' || item.pointType === 'isArea'
                ? null
                : 'google.maps.Animation.BOUNCE'
            "
          >
            <!-- 服务资源对应地图上的点的详情卡片 -->
            <gmap-info-window
              class="googleMap-point-card"
              :position="{ lng: item.lng, lat: item.lat }"
              :show="item.showFlag"
              :content="item.content"
              :labelStyle="{
                color: '#000',
                backgroundColor: '#ffffff',
                height: '22px',
                lineHeight: '22px',
                textAlign: 'center',
                width: '60px',
                border: '1px solid #006DCC',
                'border-radius': '2px',
              }"
              :offset="{ width: -20, height: -22 }"
              @closeclick="infoWindowClose(item)"
              v-if="item.showFlag"
            >
              <div class="cardAllContent" v-loading="item.loading" >
                <!-- 服务预约卡片 -->
                <div class="first_card" v-show="item.pointType === 'yuyue'">
                  <div class="serviceCardTitle">
                    <div class="serviceName">
                      <img
                        :src="
                          item.icon
                            ? require(`@/assets/dispatchingUnits/distributionMap/${item.icon}`)
                            : ''
                        "
                        alt=""
                      />
                      <p>
                        <b>{{ item.spotName }}</b
                        ><span>（{{ item.status }}）</span>
                      </p>
                    </div>
                    <p class="list_item">
                      <!-- 最早允许的开始 -->
                      <b
                        >{{
                          $t(
                            "label.service.appointmentList.earliest.allowedStart"
                          )
                        }}：</b
                      >{{ item.earlieststarttime }}
                    </p>
                    <!-- 到期日期 -->
                    <p class="list_item">
                      <b>{{ $t("label.quickbooks.viewlist.duedate") }}：</b
                      >{{ item.duedate }}
                    </p>
                  </div>
                  <div class="serviceCardTwo">
                    <p class="list_item">
                      <!-- 技能要求 -->
                      <b>{{ $t("label.ServiceCloud.jinengyaoqiu") }}：</b
                      >{{ item.skillList }}
                    </p>
                    <p class="list_item">
                      <!-- 所需产品： -->
                      <b>{{ $t("label.ServiceCloud.suoxuchanpin") }}：</b
                      >{{ item.productList }}
                    </p>
                  </div>
                </div>
                <!-- 服务资源卡片 -->
                <div class="second_card" v-show="item.pointType === 'ziyuan'">
                  <div class="serviceCardTitle">
                    <div class="serviceName">
                      <img
                        :src="
                          item.icon
                            ? require(`@/assets/dispatchingUnits/distributionMap/${item.icon}`)
                            : ''
                        "
                        alt=""
                      />
                      <p>
                        <b>{{ item.spotName }}</b>
                      </p>
                    </div>
                  </div>
                  <div class="serviceCardTwo">
                    <p class="list_item">
                      <!--  技能要求：-->
                      <b>{{ $t("label.ServiceCloud.jinengyaoqiu") }}：</b
                      >{{ item.skillList }}
                    </p>
                  </div>
                  <div class="cardContainer">
                    <div
                      class="cardList"
                      v-for="(list, index) in item.serviceAppoList"
                      :key="index"
                    >
                      <b>{{ list.name }}</b>
                      <!-- 计划开始时间 -->
                      <p>
                        {{
                          $t("label.service.ganttBaiduMap.scheduled.starttime")
                        }}：{{ list.schedstarttime }}
                      </p>
                      <!-- 计划结束时间 -->
                      <p>
                        {{
                          $t("label.service.ganttBaiduMap.scheduled.endtime")
                        }}: {{ list.schedendtime }}
                      </p>
                      <!-- 地址 -->
                      <p>{{ $t("label.address") }}:{{ list.address }}</p>
                    </div>
                  </div>
                  <!-- <div class="btn_container">
                    <el-button @click="dispatchingToPeople(item)" size="mini"
                      >分配派工单给他</el-button
                    >
                  </div> -->
                </div>
                <!-- 资产卡片 -->
                <div class="third_card" v-show="item.pointType === 'zichan'">
                  <div class="serviceCardTitle">
                    <div class="serviceName">
                      <img
                        :src="
                          item.icon
                            ? require(`@/assets/dispatchingUnits/distributionMap/${item.icon}`)
                            : ''
                        "
                        alt=""
                      />
                      <p>
                        <b>{{ item.spotName }}</b
                        ><span>（{{ item.warrantytype }}）</span>
                      </p>
                    </div>
                    <!-- 客户名称 -->
                    <p class="list_item">
                      <b>{{ $t("label.mr.title.customerName") }}：</b
                      >{{ item.accountname }}
                    </p>
                    <!--  资产序号：-->
                    <p class="list_item">
                      <b>{{ $t("label.ServiceCloud.zichanxuhao") }}：</b
                      >{{ item.serialnumber }}
                    </p>
                    <!--  安装日期：-->
                    <p class="list_item">
                      <b
                        >{{
                          $t("label.appexchange.installedpage.installdate")
                        }}：</b
                      >{{ item.installdate }}
                    </p>
                    <!-- 安装地址： -->
                    <p class="list_item">
                      <b
                        >{{
                          $t("label.ServiceCloud.Installation.address")
                        }}：</b
                      >{{ item.address }}
                    </p>
                    <!-- 购买日期： -->
                    <p class="list_item">
                      <b>{{ $t("label.ServiceCloud.purchase.date") }}：</b
                      >{{ item.purchasedate }}
                    </p>
                  </div>
                </div>
              </div>
            </gmap-info-window>
          </gmap-marker>
        </div>
    </gmap-map>
    </div>
  </div>
</template>

<script>
import selectCheckBox from "./selectCheckBox";
import Vue from "vue";
import BaiduMap from "vue-baidu-map"; //引入百度地图组件库
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "6XPhZVfaUKeRAU9Q4X9zRwtiNGouGdzl",
});
import {
  getSelectValue,
  distributionMapInfo,
  statisticalNumber,
  resourceDetailCard,
  getSkills,
  getServiceterritoryList,
} from "../api";
export default {
  name: "component_name",
  components: { selectCheckBox },
  data() {
    return {
      radio: "serviceappo", //
      overlap: false,
      center: { lng: 116.404, lat: 39.915 },
      zoom: 6,
      analysisType: "territory",
      options: [
        {
          value: "territory",
          label: this.$i18n.t("label.ServiceCloud.statistics.by.serviceArea"), //"按照服务区域统计"
        },
        {
          value: "address",
          label: this.$i18n.t("label.ServiceCloud.statistics.by.address"), // "按照地址统计",
        },
      ],
      // 服务资源周边的点
      checkListPorint: [],
      checkId: "", //当前点击点的id
      keyword: "",
      liDataPriority: [], // //优先级下拉数据
      liDataState: [], //状态下拉数据
      liDataResourceType: [], //资源类型下拉数据
      liData: [], //服务区域下拉数据
      radioBox: [
        {
          id: "serviceappo",
          name: this.$i18n.t("label.ServiceCloud.fuwuyuyue.distributionMap"), // "服务预约分布图",
        },
        {
          id: "resource",
          name: this.$i18n.t("label.ServiceCloud.fuwuziyuan.distributionMap"), // "服务资源分布图",
        },
        {
          id: "asset",
          name: this.$i18n.t("label.ServiceCloud.zichan.distributionMap"), // "资产分布图",
        },
      ],
      priority: "", //优先级
      appoStatus: "", //状态
      serviceterritoryid: "",
      fuwuNo: "",
      fuwuName: "",
      zcName: "",
      zcNo: "",
      site: "",
      searchLoading: false,
      resourcetype: "", //资源类型
      countryCode:"",//国家代码
    };
  },
  computed: {},
  watch: {
    overlap(val) {
      // 是否勾选重叠派工
      if (val) {
        this.radioBox = this.radioBox.map((item) => {
          item.id = 1;
          return item;
        });
        this.radio = 1;
        // 从左侧服务预约列表传过来的一些需要数据
        this.$bus.$on("get-checkedListRadio", this.ebFn);
        this.distributionMapInfo();
      } else {
        this.radioBox = [
          {
            id: "serviceappo",
            name: this.$i18n.t("label.ServiceCloud.fuwuyuyue.distributionMap"), // "服务预约分布图",
          },
          {
            id: "resource",
            name: this.$i18n.t("label.ServiceCloud.fuwuziyuan.distributionMap"), // "服务资源分布图",
          },
          {
            id: "asset",
            name: this.$i18n.t("label.ServiceCloud.zichan.distributionMap"), // "资产分布图",
          },
        ];
        this.radio = "serviceappo";
        this.getStatisticalNumber();
      }
    },
  },
  beforeDestroy(){
    this.$bus.$off("get-checkedListRadio", this.ebFn);
  },
  methods: {
    ebFn(data){
      this.fuwuNo = data.name.replace("-", "");
      this.$refs["mark"].focus();
    },
    //获取服务区域数据
    async GetServiceAreaData() {
      let resultData = await getServiceterritoryList({});
      this.liData = resultData.data;
    },
    //获取服务预约、服务资源地图分布统计
    getStatisticalNumber() {
      if (this.overlap) {
        return;
      }
      this.checkListPorint = []; //先将地图上的点清空
      if (this.analysisType === "address" && this.radio === "resource") {
        this.$message.warning(
          this.$i18n.t("label.ServiceCloud.prompt.warning")
        ); //按地址统计时暂无资源分布数量!
        return;
      }
      let params = {
        type: this.analysisType,
        mapType: this.radio === 1 ? "" : this.radio,
      };
      statisticalNumber(params).then((res) => {
        if (res.result && res.returnCode === "1") {
          //
          this.zoom = 6;
          this.checkListPorint = res.data.map((item) => {
            let content = "";
            if (this.radio === "serviceappo") {
              // 服务预约
              content = item.apponum;
            }
            if (this.radio === "resource") {
              // 服务资源
              content = item.resounum;
            }
            if (this.radio === "asset") {
              // 资产
              content = item.assetnum;
            }
            return {
              lng: item.lng,
              lat: item.lat,
              spotID: item.id,
              spotName: item.name,
              showFlag: false,
              icon: "dis-xiaoyuandian.svg",
              iconType: "isArea",
              pointType: "isArea",
              content: content + "",
            };
          });
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 获取分布图信息
    distributionMapInfo(isSearch) {
      this.liData = []; //切换时清空区域下拉
      this.checkListPorint = []; //切换时清空地图打点
      // 服务类型 少了
      let keyWord = null,
        serviceAppoNum = null;
      if (this.radio === "serviceappo") {
        // 服务预约
        keyWord = this.fuwuNo;
        serviceAppoNum = null;
        //清空资源类型、资产序号
        this.zcNo = null;
        this.resourcetype = null;
      }
      if (this.radio === "resource") {
        // 服务资源
        keyWord = this.fuwuName;
        serviceAppoNum = this.fuwuNo;
        this.priority = null;
        this.appoStatus = null;
        this.zcNo = null;
      }
      if (this.radio === "asset") {
        // 资产
        keyWord = this.zcName;
        serviceAppoNum = this.fuwuNo;
        this.priority = null;
        this.appoStatus = null;
        this.serviceterritoryid = "";
        this.resourcetype = null;
      }
      if (this.radio === 1) {
        // 重叠派工
        keyWord = null;
        serviceAppoNum = this.fuwuNo;
        this.site = "";
        this.serviceterritoryid = "";
        this.appoStatus = null;
        this.priority = null;
        this.zcNo = null;
        this.resourcetype = null;
      }
      //
      let params = {
        type: this.radio === 1 ? "1" : this.radio,
        keyWord: keyWord,
        site: this.site,
        serviceterritoryid: this.serviceterritoryid,
        appoStatus: this.appoStatus,
        serviceAppoNum: serviceAppoNum,
        priority: this.priority,
        overlap: this.overlap ? "true" : "false",
        serialnumber: this.zcNo,
        resourcetype: this.resourcetype,
      };
      //
      distributionMapInfo(params).then((res) => {
        if (res.result && res.returnCode === "1") {
          this.zoom = 12;
          //
          // 回显服务区域数据
          let liDataCOPY = res?.data?.territoryList || [];
          // 服务区域打点
          let quyuPoinMap = [];
          quyuPoinMap = liDataCOPY.map((item, index) => {
            if (index === 0) {
              this.center = { lng: item.lng, lat: item.lat };
            }
            return {
              lng: item.lng,
              lat: item.lat,
              spotID: item.id,
              spotName: item.name,
              showFlag: false,
              icon: "dis-quyue.svg",
              iconType: "",
              content: "",
              pointType: "quyu",
            };
          });
          // 地图打点
          let pointData = res.data;
          let checkListPorintMap = [],
            checkListPorintMapBig = [];
          if (pointData?.serviceAppoList?.length) {
            // 服务预约
            checkListPorintMap = res.data.serviceAppoList.map((item, index) => {
              if (index === 0) {
                this.center = { lng: item.lng, lat: item.lat };
              }
              let icon = "";
              // 根据返回类型判断显示那种图标的点
              switch (item.status) {
                case "进行中":
                  icon = "dis-jinxingzhong.svg";
                  break;
                case "无":
                  icon = "dis-wu.svg";
                  break;
                case "已计划":
                  icon = "dis-yijihua.svg";
                  break;
                case "已派遣":
                  icon = "dis-yipaiqian.svg";
                  break;
                case "已完成":
                  icon = "dis-yiwancheng.svg";
                  break;
                case "无法完成":
                  icon = "dis-wufawancheng.svg";
                  break;
                case "已取消":
                  icon = "dis-yiquxiao.svg";
                  break;
                default:
                  icon = "";
              }
              return {
                lng: item.lng,
                lat: item.lat,
                spotID: item.id,
                spotName: item.rname,
                showFlag: false,
                icon: icon,
                iconType: "",
                pointType: "yuyue",
                content: "",
                loading: true,
                status: item.status,
                earlieststarttime: item.earlieststarttime,
                duedate: item.duedate,
              };
            });
            checkListPorintMapBig.push(...checkListPorintMap);
          }
          if (pointData?.serviceresouceList?.length) {
            // 服务资源
            checkListPorintMap = res.data.serviceresouceList.map(
              (item, index) => {
                if (index === 0) {
                  this.center = {
                    lng: item.lastknownlocationco,
                    lat: item.lastknownlocationla,
                  };
                }
                // 根据返回类型判断显示那种图标的点
                let icon = "";
                if (item.resourcetype === "班组") {
                  icon = "dis-banzu.svg";
                }
                if (item.resourcetype !== "班组") {
                  icon = "dis-feibanzu.svg";
                }
                return {
                  lng: item.lastknownlocationco,
                  lat: item.lastknownlocationla,
                  spotID: item.id,
                  spotName: item.name || "",
                  showFlag: false,
                  icon: icon,
                  iconType: "",
                  pointType: "ziyuan",
                  content: "",
                };
              }
            );
            checkListPorintMapBig.push(...checkListPorintMap);
          }
          if (pointData?.assetList?.length) {
            // 资产
            checkListPorintMap = res.data.assetList.map((item, index) => {
              if (index === 0) {
                this.center = { lng: item.lng, lat: item.lat };
              }
              return {
                lng: item.lng,
                lat: item.lat,
                spotID: item.id,
                spotName: item.name,
                showFlag: false,
                icon: "dis-zichantubiao.svg",
                iconType: "",
                content: "",
                pointType: "zichan",
                warrantytype: item.warrantytype,
                accountname: item.accountname,
                serialnumber: item.serialnumber,
                address: item.address,
                installdate: item.installdate,
                purchasedate: item.purchasedate,
              };
            });
            checkListPorintMapBig.push(...checkListPorintMap);
          }
          // 将区域的点和各种类型分布图的点结合在一起
          this.checkListPorint = checkListPorintMapBig.concat(quyuPoinMap);
          // 当筛选时提示语添加
          if (isSearch) {
            this.searchLoading = false;
            this.$message.success(
              this.$i18n.t("label.ServiceCloud.chaxunchenggong")
            ); //'查询成功'
          }
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // （1）获取服务预约状态的数据
    getDisSelectValueStuate() {
      getSelectValue({ fieldId: "ffe21sappointment044" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          this.liDataState = res.data.map((item) => {
            return {
              name: item.codevalue,
              id: item.codekey,
            };
          });
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // （2）获取优先级
    getDisSelectValuePriority() {
      getSelectValue({ fieldId: "ffeWOLineItemField12" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          this.liDataPriority = res.data.map((item) => {
            return {
              name: item.codevalue,
              id: item.codekey,
            };
          });
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // （3）获取资源类型
    getDisSelectValueResourceType() {
      getSelectValue({ fieldId: "ffeserviceresource23" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          this.liDataResourceType = res.data.map((item) => {
            return {
              name: item.codevalue,
              id: item.codekey,
            };
          });
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    /**
     * { BMap, map }
     */
    handler() {},
    // 左侧radio类型切换
    handleRadioChange() {
      // this.zoom=3;
      this.getStatisticalNumber();
    },
    // 区域/地址统计切换
    handleChange() {
      this.getStatisticalNumber();
      if (this.radio === 1) {
        this.distributionMapInfo();
      }
    },
    // 显示悬浮窗体信息
    infoWindowOpen(item) {
      this.checkId = item.spotID;
      // 区域级的marker
      if (item.iconType === "isArea") {
        this.zoom = 13;
        // this.center = { lng: item.lng, lat: item.lat };
        this.serviceterritoryid = item.spotID; //item.spotID  资源时传当前区域有问题，暂时被清空
        this.distributionMapInfo();
      } else {
        // 获取服务预约的技能要求跟所需产品
        if (item.pointType == "yuyue") {
          item.showFlag = true;
          this.getProductAndSkillRequired(item);
        }
        // 获取服务资源详细卡片信息
        if (item.pointType == "ziyuan") {
          item.showFlag = true;
          this.resourceDetailCard(item);
        }
        // 资产详情卡片
        if (item.pointType == "zichan") {
          item.showFlag = true;
        }
      }
    },
    // 关闭悬浮窗体
    infoWindowClose(item) {
      item.showFlag = false;
    },
    // 获取服务预约的技能要求跟所需产品
    getProductAndSkillRequired(item) {
      getSkills({ id: item.spotID }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let resultData = res.data;
          let _productList = [],
            _skillList = [];
          resultData.productList.forEach((node1) => {
            _productList.push(node1.name);
          });
          this.$set(item, "productList", _productList.slice(0, 5).join(","));
          resultData.skillList.forEach((node1) => {
            _skillList.push(node1.name);
          });
          this.$set(item, "skillList", _skillList.slice(0, 5).join(","));
          item.loading = false;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 获取服务资源详细卡片信息
    resourceDetailCard(item) {
      resourceDetailCard({ id: item.spotID }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let resultData = res.data;
          let _skillList = [];
          resultData.skillList.forEach((node1) => {
            _skillList.push(node1.name);
          });
          this.$set(item, "skillList", _skillList.slice(0, 5).join(","));
          this.$set(item, "serviceAppoList", resultData.serviceAppoList);
          item.loading = false;
          //
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 筛选
    handleSearch() {
      this.searchLoading = true;
      this.distributionMapInfo("isSearch");
    },
    // 分配派工单
    // dispatchingToPeople(item) {
    //   // 卡片隐藏
    //   item.showFlag = false;
    //   // 如果左侧服务预约的计划开始时间为空  则弹出填写计划开始和计划结束的弹窗，填写后保存并调取派遣接口
    //   // 如果左侧服务预约的计划开始时间存在数据  则点击派遣后直接调取接口
    //   if (this.serviceStarttime) {
    //     this.dialogVisible = false;
    //     this.saveServiceAppo(item);
    //   } else {
    //     this.dialogVisible = true;
    //   }
    // },
    // saveServiceAppo() {},
    // 取消计划
    // handleCancle(item) {
    //   item.showFlag = false;
    //
    // },
    // 派遣
    // handleDispatch(item) {
    //   item.showFlag = false;
    //
    // },
  },
  created() {
    this.getDisSelectValueStuate();
    this.getDisSelectValuePriority();
    this.getDisSelectValueResourceType();
    this.getStatisticalNumber();
  },
  mounted() {
    this.GetServiceAreaData();
    this.countryCode=this.$cookies.get("countryCode");
  },
};
</script>
<style lang="scss" scoped>
p {
  margin: 0;
}
.distributionMapHeather {
  width: 100%;
  height: 50px;
  line-height: 50px;
  position: relative;
  display: flex;
  .H-left {
    margin-left: 10px;
  }
  .H-midlle {
    flex: 1;
    text-align: center;
  }
  .H-right {
    margin-left: 10px;
    margin-right: 20px;
    ::v-deep .el-input__inner {
      height: 30px;
    }
  }
}
.mapContainer {
  width: 100%;
  height: calc(100vh - 200px);
  position: relative;
  .baiduMap {
    height: 100%;
    // 地图样式
    // 水印样式(位置修改或隐藏)
    ::v-deep .anchorBL {
      display: none; //隐藏
      //  inset: auto auto 26px 1px !important; //位置修改
    }
    ::v-deep .BMap_cpyCtrl.BMap_noprint.anchorBL {
      // display: none; //隐藏
      inset: auto auto 10px 2px !important; //位置修改
    }
    // 右下角小地图指引
    ::v-deep .BMap_omCtrl.BMap_ieundefined.BMap_noprint.anchorBR.quad4 {
      inset: auto 4px 14px auto !important; //位置修改
    }
    // 指南针
    ::v-deep .BMap_stdMpCtrl.BMap_stdMpType0.BMap_noprint.anchorTR {
      inset: 8px -6px auto auto !important; //位置修改
      .BMap_stdMpZoom {
        top: 46px;
        left: 2px;
      }
    }
    //尺度
    ::v-deep .BMap_scaleCtrl.BMap_noprint.anchorTR {
      inset: 20px 62px auto auto !important; //位置修改
      // width: 76px !important; //宽度
    }
    // 图形绘制
    .bmControlBtn {
      margin-left: 500px;
    }
  }
  .search_container {
    position: absolute;
    top: 30px;
    left: 99px;
    z-index: 1;
    overflow: hidden;
    .select_TBoxHe {
      overflow: hidden;
    }
    .select_kBoxHe {
      margin-top: 16px;
      overflow: hidden;
    }
    .el-input {
      width: 142px;
      margin-left: 25px;
      float: left;
    }
    .el-button {
      float: left;
      margin-left: 25px;
    }
    .select_kBox {
      float: left;
      margin-left: 25px;
    }
  }
}
.baiduMap-point-card {
  max-width: 600px;
}
.googleMap-point-card {
  max-width: 600px;
}
.cardAllContent {
  // padding-bottom: 0;
  .serviceCardTitle {
    border-bottom: 1px solid #dddfe8;
    padding-bottom: 6px;
  }
  .serviceName {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    p {
      color: #1d2033;
      font-size: 12px;
      margin-left: 10px;
    }
    b {
      font-size: 18px;
    }
  }
  .list_item {
    font-size: 12px;
    margin-bottom: 6px;
  }
  .serviceCardTwo,
  .serviceCardThree {
    padding: 10px 0 4px;
    border-bottom: 1px solid #dddfe8;
  }
  em {
    font-style: normal;
  }
  .three_item {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  .serviceCardFou {
    padding: 10px 0;
    border-bottom: 1px solid #dddfe8;
    font-size: 12px;
    color: #1d2033;
    .fou_top {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      img {
        width: 14px;
        margin-right: 6px;
      }
    }
  }
  .btn_container {
    padding: 10px 0 0;
    text-align: center;
  }
  .cardContainer {
    max-height: 160px;
    overflow-y: auto;
    padding: 10px 0;
  }
  .cardList {
    border-bottom: 1px solid #dddfe8;
    b {
      font-size: 14px;
      color: #2e324d;
      margin-bottom: 6px;
    }
    p {
      font-size: 12px;
      line-height: 18px;
      color: #9fa3b4;
    }
  }
}
</style>
