<template>
  <div class="mapContainer">
    <div v-if="isGGmap">
      <!-- 高德/谷歌地图 -->
      <map-group
        ref="mapGroup"
        :mapkey="mapkey"
        :isMap="false"
        :isHide="true"
        @showDetail="showDetail"
        @AssignAppointment="AssignAppointment"
      ></map-group>
    </div>
    <div v-if="!isGGmap">
      <!-- 百度地图 -->
    <baidu-map
      class="baiduMap"
      id="allmap"
      :center="center"
      :zoom="zoom"
      @ready="handler"
      :scroll-wheel-zoom="true"
    >
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT">
        :showAddressBar="true" :autoLocation="true"
      </bm-navigation>
      <!-- 在地图上添加点 -->
      <!-- 中心点 -->
      <bm-marker
        :position="lngAndLat"
        :dragging="false"
        :icon="{
          url: require('@/assets/dispatchingUnits/centerPoint.svg'),
          size: { width: 100, height: 100 },
        }"
        animation="BMAP_ANIMATION_BOUNCE"
      >
        <!-- <bm-label
          content="中心点"
          :labelStyle="{ color: 'red', fontSize: '24px' }"
          :offset="{ width: -35, height: 30 }"
        /> -->
      </bm-marker>
      <!-- 周围的点 -->
      <div
        class="baiduMap-point"
        v-for="(item, index) in checkListPorint"
        :key="index"
      >
        <bm-marker
          :position="{ lng: item.lng, lat: item.lat }"
          :dragging="false"
          :icon="{
            url: require('@/assets/dispatchingUnits/roundingPoint.svg'),
            size: { width: 30, height: 30 },
          }"
          @click="infoWindowOpen(item)"
        >
          <!-- 服务资源对应地图上的点的详情卡片 -->
          <bm-info-window
            class="baiduMap-point-card"
            :position="{ lng: item.lng, lat: item.lat }"
            :show="item.showFlag"
            @close="infoWindowClose(item)"
            @open="infoWindowOpen(item)"
          >
            <div class="cardAllContent">
              <!-- 卡片头部  roundingPointxq-->
              <!-- 服务资源名称 label.service.ganttBaiduMap.service.resourname-->
              <div class="serviceCardTitle">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-servePeople"></use>
                </svg>

                <div class="serviceName">
                  <p>
                    {{ $t("label.service.ganttBaiduMap.service.resourname") }}
                  </p>
                  <p>{{ item.spotName }}</p>
                </div>
              </div>
              <!-- 卡片内容 -->
              <div
                class="serviceCardContent"
                v-for="(item1, index1) in roundingPointxq"
                :key="index1"
              >
                <div class="contentTitle">
                  <span>{{ item1.name }}</span>
                </div>
                <div class="serviceTime">
                  <div class="">
                    <p class="">
                      {{
                        $t("label.service.ganttBaiduMap.scheduled.starttime")
                      }}：{{ item1.schedstarttime }}
                    </p>
                    <p class="">
                      {{
                        $t("label.service.ganttBaiduMap.scheduled.endtime")
                      }}：{{ item1.schedendtime }}
                    </p>
                    <p class="">{{ $t("AD") }}：{{ item1.address }}</p>
                  </div>
                  <div class="serviceTimeState">
                    <span>{{ item1.status }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- 卡片分配派工单v-show="serviceStatus == ('无' || '已计划' || '已派遣' || '进行中') "-->
            <div
              class="serviceCardDispatching"
              v-show="
                serviceStatus === $t('label.tabpage.nothingz') ||
                serviceStatus === $t('label.projectManagement.planned') ||
                serviceStatus === $t('label.projectManagement.dispatched') ||
                serviceStatus === $t('label.starting')
              "
            >
              <div class="dispatchingbtn" @click="dispatchingToPeople(item)">
                {{ $t("label.service.ganttBaiduMap.assign.service") }}
              </div>
            </div>
          </bm-info-window>
        </bm-marker>
      </div>

      <!-- 圆形 -->
      <!-- <bm-circle :center="circlePath.center" :radius="circlePath.radius" stroke-color="blue" :stroke-opacity="0.5"
                  :stroke-weight="2" @lineupdate="updateCirclePath" :editing="true">
       </bm-circle> -->
      <!-- 比例尺 -->
      <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
      <!-- 缩略图 -->
      <bm-overview-map
        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
        :isOpen="true"
      ></bm-overview-map>
      <!-- 定位 -->
      <bm-geolocation
        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
        :showAddressBar="true"
        :autoLocation="true"
      ></bm-geolocation>
      <!-- 城市列表 -->
      <bm-city-list
        class="bmCityList"
        anchor="BMAP_ANCHOR_TOP_LEFT"
      ></bm-city-list>
    </baidu-map>
    </div>

    <!-- 计划开始结束时间设置的 遮罩层 -->
    <!--选择派遣时段 label.service.ganttBaiduMap.selectingDispatchTime -->
    <el-dialog
      :title="$t('label.service.ganttBaiduMap.selectingDispatchTime')"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="startAndEndTime">
        <div class="startTime">
          <span>{{
            $t("label.service.ganttBaiduMap.scheduled.starttime")
          }}</span>
          <el-date-picker
            v-model="startTimeVal"
            type="datetime"
            :clearable="false"
            placeholder=""
          >
          </el-date-picker>
        </div>
        <!-- <div class="endTime">
          <span>计划结束时间</span>
          <el-date-picker
            v-model="endTimeVal"
            type="datetime"
            :clearable="false"
            placeholder=""
          >
          </el-date-picker>
        </div> -->
      </div>
      <!-- 取消label.emailsync.button.cancel   确定label.tabpage.ok  -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="saveStartAndEndTimeMethod">{{
          $t("label.tabpage.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getQualifiedServiceResource,
  getQuickDispatchPage,
  saveServiceAppo,
} from "../api";
import Vue from "vue";
import BaiduMap from "vue-baidu-map"; //引入百度地图组件库
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "6XPhZVfaUKeRAU9Q4X9zRwtiNGouGdzl",
});
import { VueJsonp } from "vue-jsonp"; // 解析百度地图地址
Vue.use(VueJsonp);
import MapGroup from "@/components/Map/index.vue";
export default {
  data() {
    return {
      mapkey: "",
      isGGmap:this.$store.state.userInfoObj.countryCode != 'CN' ? true : false,
      center: { lng: 116.404, lat: 39.915 },
      zoom: 12,
      // polyline: {
      //   // 图形绘制
      //   editing: false,
      //   paths: [],
      // },
      // circlePath: {
      //   center: {
      //     lng: 116.404,
      //     lat: 39.915
      //   },
      //   radius: 500
      // },
      // markerPoint: { lng: 116.404, lat: 39.915 },
      dialogVisible: false, // 控制设置时间遮罩层的显示和隐藏
      startTimeVal: "", // 计划开始时间
      // endTimeVal: '', // 计划结束时间
      conversionStartTimeVal: "", // 计划开始时间转换成日期格式  如 2021 08-01 10:00：00
      conversionEndTimeVal: "", // 计划结束时间转换成日期格式  如 2021 08-01 10:00：00
      serviceStarttime: "", // 左侧服务预约计划开始时间
      serviceEndtime: "", // 左侧服务预约计划结束时间
      servicedurationIns: "", // 左侧服务预约持续时间
      serviceStatus: "", // 服务资源状态
      serviceId: "", // 服务资源ID
      serviceAreaId: "", // 服务资源关联的区域ID
      serviceCheckAllID: [], // 服务资源关联的区域ID为空时  勾选的所有区域ID
      wzjId: "",
      lngAndLat: "", // 服务资源详细地址解析成经纬度
      checkListPorint: [], // 服务资源周边的点
      roundingPointxq: [], // 服务资源周边的点的详情
      // pageSizeNumber:'3'
    };
  },
  components:{
    MapGroup
  },
  mounted() {
    //判断是否为国外环境、是则应用谷歌地图
    this.mapFun()
    // 从左侧服务预约列表传过来的一些需要数据
    this.$bus.$on("get-checkedListRadio", this.ebFn1);
    this.$bus.$on("get-serviceCheckID", this.ebFn2);
  },
  destroyed() {
    this.$bus.$off("get-checkedListRadio",this.ebFn1);
    this.$bus.$off("get-serviceCheckID",this.ebFn2);
    this.$bus.$off("get-checkedListRadio");
    this.$bus.$off("get-serviceCheckID");
  },
  watch: {},
  computed: {},
  methods: {
    ebFn1(wj){
      this.serviceStatus = wj.status; // 服务预约状态
      this.serviceId = wj.id; // 服务预约ID
      this.serviceAreaId = wj.serviceterritoryid; // 服务预约关联区域ID
      this.serviceStarttime = wj.schedstarttime; // 服务预约计划开始时间
      this.serviceEndtime = wj.schedendtime; // 服务预约计划结束时间
      this.servicedurationIns = Number(wj.durationIns) * 60 * 60 * 1000; // 左侧服务预约持续时间
      if(this.isGGmap){
        this.$refs.mapGroup.DispatchFun(wj.address)
      }else{
      // 详细地址解析成经纬度
        this.addressToLngAndLat(wj.address);
      }
      // 获取符合条件的服务资源  也就是围绕(左侧服务预约)中心点的 周边点(服务资源)
      this.getQualifiedServiceResource();
    },
    ebFn2(dataId){
      this.serviceCheckAllID = dataId;
    },
    //分配服务预约
    AssignAppointment(){
      this.dispatchingToPeople(this.spotBody)
    },
    //服务资源列表
    showDetail(params){
      this.spotBody = params
      this.getQuickDispatchPage(params.spotID)
    },
    //地图初始化
    mapFun() {
      if (this.isGGmap) {
      this.mapkey = "AIzaSyCyWXGrhpOd1py9eUCpzRAzbDjWtXQNqGU";
      this.$nextTick(() => {
        this.$refs.mapGroup.init();
        this.$refs.mapGroup.searchKey = "";
        this.$refs.mapGroup.searchList = [];
      });
      }
    },
    handler() {},

    // destroyed() {
    //   this.$bus.$off("get-checkedListRadio");
    //   this.$bus.$off("get-serviceCheckID");
    // },
    //  详细地址解析成经纬度
    addressToLngAndLat(adres) {
      this.$jsonp("https://api.map.baidu.com/geocoding/v3/", {
        address: adres,
        output: "json",
        ak: "6XPhZVfaUKeRAU9Q4X9zRwtiNGouGdzl",
      }).then((json) => {
        //
        this.lngAndLat = json.result.location;
        // 动态改变中心点成资源为中心
        this.center = this.lngAndLat;
      });
    },

    // 将时间秒数转换成日期
    mapDateMethod(staAndEnd) {
      var date = new Date(staAndEnd);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var mm = date.getMinutes();
      mm = mm < 10 ? "0" + mm : mm;
      var ss = date.getSeconds();
      ss = ss < 10 ? "0" + ss : ss;
      return y + "-" + m + "-" + d + " " + h + ":" + mm + ":" + ss;
    },

    // 获取符合条件的服务资源 (周边点)
    async getQualifiedServiceResource() {
      let arr = [];
      this.checkListPorint = [];
      let params = {
        serviceAppointmentId: this.serviceId,
        serviceTerritoryId:
          this.serviceAreaId == ""
            ? this.serviceCheckAllID
            : this.serviceAreaId,
      };
      let res = await getQualifiedServiceResource(params);
      if (res.result == true) {
        res.data.map((item) => {
          let tempStr =
            item.lastknownlocation + "," + item.id + "," + item.name; // 将所需经纬度和周边点的ID和name  拼接成字符串
          this.checkListPorint.push(tempStr);
        });
        // checkListPorint数组里面的字符串  转换成对象格式   四个属性 (经纬度  服务资源ID也就是周边点的ID和name  控制卡片展示的showFlag)
        this.checkListPorint.forEach((res) => {
          let obj = {
            lng: res.split(",")[1],
            lat: res.split(",")[0],
            spotID: res.split(",")[2],
            spotName: res.split(",")[3],
            showFlag: false,
          };
          arr.push(obj);
        });
        this.checkListPorint = arr;
        if(this.isGGmap && this.checkListPorint.length !=0){
           this.$refs.mapGroup.showAllService(this.checkListPorint)
        }
      }
    },

    // 服务资源 周边点的详情 (卡片里面的详情内容)
    async getQuickDispatchPage(spotID) {
      let params = {
        serviceResourceId: spotID,
        pageNum: "1",
        pageSize: "50",
      };
      let result = await getQuickDispatchPage(params);
      if (result.result == true) {
        this.roundingPointxq = result.data.serviceAppointmentMaps;
        //  将roundingPointxq 里面的开始和结束时间 由秒数转换成 日期
        this.roundingPointxq.map((item) => {
          item.schedendtime =
            item.schedendtime == null
              ? ""
              : this.mapDateMethod(item.schedendtime);
          item.schedstarttime =
            item.schedstarttime == null
              ? ""
              : this.mapDateMethod(item.schedstarttime);
        });
        if(this.isGGmap){
           this.$refs.mapGroup.showServiceDetail(this.roundingPointxq,spotID)
        }
      }
    },

    // 保存服务预约
    async saveServiceAppo(alld) {
      let option = {
        resourceid: this.serviceStarttime == null ? this.wzjId : alld.spotID,
        appoid: this.serviceId,
        territoryid:
          this.serviceAreaId == null
            ? this.serviceCheckAllID
            : this.serviceAreaId,
        startTime:
          this.serviceStarttime == null
            ? this.conversionStartTimeVal
            : this.serviceStarttime,
        endTime:
          this.serviceStarttime == null
            ? this.conversionEndTimeVal
            : this.serviceEndtime,
      };
      let result = await saveServiceAppo(option);
      if (result.result == true) {
        this.$Bus.$emit("get-appointList-map", true);
      }
    },
    // 点击地图上的标注点 出现的悬浮卡 (隐藏和显示)
    infoWindowClose(item) {
      item.showFlag = false;
    },
    infoWindowOpen(item) {
      item.showFlag = true;
      // 服务资源 周边点的详情
      this.getQuickDispatchPage(item.spotID);
      this.wzjId = item.spotID;
    },
    // 分配派工单
    dispatchingToPeople(alldata) {
      // 卡片隐藏
      alldata.showFlag = false;
      // this.serviceStarttime = ''

      // 如果左侧服务预约的计划开始时间为空  则弹出填写计划开始和计划结束的弹窗，填写后保存并调取派遣接口
      // 如果左侧服务预约的计划开始时间存在数据  则点击派遣后直接调取接口
      if (this.serviceStarttime) {
        this.dialogVisible = false;
        this.saveServiceAppo(alldata);
      } else {
        this.dialogVisible = true;
      }
    },
    // 保存开始结束时间关闭遮罩层 并调取保存服务于预约派遣接口
    saveStartAndEndTimeMethod() {
      // 将输入的开始和结束时间 转换成 2021-04-20 10:00:00 格式
      // 将中国标准时间  Tue Apr 27 2021 20:05:14 GMT+0800  转换成时间戳就是秒数
      let newStartTimeVal = new Date(this.startTimeVal).getTime();
      let newEndTimeVal = newStartTimeVal + this.servicedurationIns;
      this.conversionStartTimeVal = this.mapDateMethod(this.startTimeVal);
      this.conversionEndTimeVal = this.mapDateMethod(newEndTimeVal);
      this.dialogVisible = false;
      // 输入完后 调取保存服务预约派遣接口
      this.saveServiceAppo();
    },

    handleClose(done) {
      this.$confirm(this.$i18n.t("label.service.ganttBaiduMap.shutDown")) //确认关闭 this.$i18n.t('label.service.ganttBaiduMap.shutDown')
        .then(() => {
          done();
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
// .mapContainer {

// }
.baiduMap {
  width: 100%;
  height: 600px;
  // 地图上派工对应的点的卡片
  .baiduMap-point-card {
    padding: 10px 0 0 0;
    font-size: 12px;
    width: 330px;
    .cardAllContent {
      height: 330px;
      overflow-y: auto;
      // border: solid 1px;
      padding: 0 10px;
      // 卡片头部
      .serviceCardTitle {
        display: flex;
        .icon {
          display: inline-block;
          width: 38px;
          height: 38px;
        }
        .serviceName {
          padding: 0 6px;
          p {
            &:nth-of-type(1) {
              font-weight: 700;
              font-size: 14px;
            }
            margin: 0;
            padding: 0;
          }
        }
      }
      // 卡片内容
      .serviceCardContent {
        padding: 10px 0;
        font-size: 12px;
        border-bottom: solid 1px gainsboro;
        .contentTitle {
          font-weight: 700;
          font-size: 14px;
          color: #2e324d;
          letter-spacing: 0;
        }
        .serviceTime {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 6px;
          color: #9fa3b4;
          letter-spacing: 1px;
          p {
            margin: 0;
            padding: 0;
          }
          .serviceTimeState {
            font-size: 14px;
            font-weight: 700;
            color: #080707;
            letter-spacing: 0;
          }
        }
      }
    }

    // 卡片分配派工单
    .serviceCardDispatching {
      padding: 15px 0 0 0;
      .dispatchingbtn {
        width: 120px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        font-size: 13px;
        color: white;
        background: #006dcc;
        border-radius: 3px;
        margin: auto;
      }
    }
  }
  // 地图样式
  // 水印样式(位置修改或隐藏)
  ::v-deep .anchorBL {
    // display: none;//隐藏
    inset: auto auto 26px 1px !important; //位置修改
  }
  ::v-deep .BMap_cpyCtrl.BMap_noprint.anchorBL {
    // display: none; //隐藏
    inset: auto auto 10px 2px !important; //位置修改
  }
  // 右下角小地图指引
  ::v-deep .BMap_omCtrl.BMap_ieundefined.BMap_noprint.anchorBR.quad4 {
    inset: auto 4px 14px auto !important; //位置修改
  }
  // 指南针
  ::v-deep .BMap_stdMpCtrl.BMap_stdMpType0.BMap_noprint.anchorTR {
    inset: 8px -6px auto auto !important; //位置修改
    .BMap_stdMpZoom {
      top: 46px;
      left: 2px;
    }
  }
  //尺度
  ::v-deep .BMap_scaleCtrl.BMap_noprint.anchorTR {
    inset: 20px 62px auto auto !important; //位置修改
    // width: 76px !important; //宽度
  }
}
// 图形绘制
.bmControlBtn {
  margin-left: 500px;
}
// 计划开始结束时间设置的 遮罩层
.startAndEndTime {
  padding: 20px 0;
  .el-input {
    width: 200px;
    margin-left: 8px;
    ::v-deep .el-input__inner {
      height: 30px;
      line-height: 30px;
    }
    // 日期输入框内图标
    ::v-deep .el-input__icon {
      line-height: 30px;
    }
  }
  // .endTime {
  //   margin-top: 30px;
  // }
}
</style>
