<template>
  <div>
    <el-dialog
      :visible="delCustomView"
      :title="$t('label.emailtocloudcc.tip')"
      width="30%"
      :before-close="handleClose"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <div>
        {{
          $t("label.service.deleteCustomView.wanttoremove.filter", {
            name: name,
          })
        }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteCustomViewCancle">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="deleteCustomViewConfrim">{{
          $t("label.tabpage.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { deleteServiceAppointmentView } from "../api";

export default {
  name: "deleteCustomView",
  data() {
    return {};
  },
  props: {
    delCustomView: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClose() {
      this.$emit("deleteCustomViewCancle");
    },
    deleteCustomViewCancle() {
      this.$emit("deleteCustomViewCancle");
    },
    async deleteCustomViewConfrim() {
      let result = await deleteServiceAppointmentView({ id: this.id });
      if (result.result == true) {
        this.$emit("deleteCustomViewConfrim");
        this.$message({
          type: "success",
          message: this.$i18n.t("label.tabpage.delsuccessz"),
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/dialogStyle.scss";
</style>
