<template>
  <div class="shuttle-box">
    <div class="people-list">
      <div class="hasList isSelect" style="float: none">
        <div class="title">{{ $t("label.chosen.all") }}</div>
        <div class="body" v-loading="isLoading">
          <div class="input-box">
            <input type="text" v-model="input" />
            <svg class="icon" aria-hidden="true" >
              <use href="#icon-search"></use>
            </svg>
          </div>
          <div class="list">
            <div
              class="list-item"
              v-for="(item, index) in filterList"
              :key="item.id"
              @mouseover="mouseOver(index)"
              :style="{
                background: addCurrentIndex == index ? '#f3f6f9' : '#fff',
              }"
            >
              <div class="name">{{ item.label_name }}</div>
              <div
                class="symbol"
                v-show="addCurrentIndex == index"
                @click="selectPeo(item)"
              >
                <i class="el-icon-plus"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hasList" style="float: none">
        <div class="title">{{ $t("label.chosen.field") }}</div>
        <div class="body" v-loading="isLoading">
          <div class="list">
            <vuedraggable v-model="isSelectPeoList">
              <div
                class="list-item"
                v-for="(item, index) in isSelectPeoList"
                :key="item.id"
                @mouseover="isSelectMouseOver(index)"
                :style="{
                  background: deleteCurrentIndex == index ? '#f3f6f9' : '#fff',
                }"
              >
                <div class="name">{{ item.label_name }}</div>
                <div
                  class="symbol"
                  v-show="deleteCurrentIndex == index"
                  @click="deletePeo(index)"
                >
                  <i class="el-icon-minus"></i>
                </div>
              </div>
            </vuedraggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFieldList } from "../api";
import vuedraggable from "vuedraggable";

export default {
  props: {
    value: {
      type: Array,
      default: ()=>[],
    },
  },
  data() {
    return {
      isLoading: false,
      peopleList: [],
      input: "",
      //显示添加字段按钮
      // shouAdd: false,
      //鼠标当前移入的数据下标
      addCurrentIndex: null,
      deleteCurrentIndex: null,
      //显示删除字段按钮
      // shouDelete: false,
      isSelectPeoList: [],
    };
  },
  components: {
    vuedraggable,
  },
  computed: {
    filterList() {
      if (!this.input) return this.peopleList;
      return this.peopleList.filter((res) => {
        return new RegExp(this.input).test(res.label_name);
      });
    },
  },
  mounted() {
    // this.getFieldsList();
    // this.$Bus.$on('deliver-fields-gantt',result=>{
    //   
    //   if(result.length == 0){
    //     
    //     this.peopleList.forEach(res => {
    //         if (res.id == 'ffe21sappointment008' || res.id == 'ffe21sappointment038' || res.id == 'ffe21sappointment039' || res.id == 'ffe21sappointment044' || res.id == 'ffe21sappointment055') {
    //         this.isSelectPeoList.push(res)
    //       }
    //     })
    //   }else{
    //     this.isSelectPeoList = result;
    //   }
    // });
  },
  methods: {
    //获取对象字段列表
    async getFieldsList() {
      this.isLoading = true;
      let result = await getFieldList({ objId: "serviceappointment" });
      if (result.result == true) {
        this.peopleList = result.data;
        //已选字段展示
        this.peopleList.forEach((res) => {
          if (this.value.length == 0) {
            switch (res.api) {
              case "name":
                this.isSelectPeoList[0] = res;
                break;
              case "status":
                this.isSelectPeoList[1] = res;
                break;
              case "serviceterritoryid":
                this.isSelectPeoList[2] = res;
                break;
              case "earlieststarttime":
                this.isSelectPeoList[3] = res;
                break;
              case "duedate":
                this.isSelectPeoList[4] = res;
                break;
            }
            let id = this.isSelectPeoList.map((ele) => ele.id).join();
            this.$emit("deliverFieldId", id);
          } else {
            this.isSelectPeoList = this.value;
          }
        });
        this.isLoading = false;
      }
    },
    //删除当前选中的字段
    deletePeo(index) {
      this.isSelectPeoList.splice(index, 1);
    },
    //选择字段
    selectPeo(item) {
      if (this.isSelectPeoList.length == 6) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "最多选择六个字段",
        });
        return false;
      }
      let index = this.isSelectPeoList.findIndex((res) => res.id == item.id);
      if (index < 0) {
        this.isSelectPeoList.push(item);
      }
    },
    //鼠标移入
    isSelectMouseOver(index) {
      this.deleteCurrentIndex = index;
    },
    //鼠标移入
    mouseOver(index) {
      this.addCurrentIndex = index;
    },
    //取消选择
    // cancelSelect() {
    //   this.$emit("changeSearch", false);
    // },
    //确定查找的审批人
    // submitMessage() {
    //   let name = this.isSelectPeoList.map((res) => res.name).join();
    //   let id = this.isSelectPeoList.map((res) => res.id).join();
    //   this.$emit("deliverName", name, id);
    //   this.$emit("changeSearch", false);
    // },
  },
  watch: {
    isSelectPeoList: {
      handler() {
        // if (newVal != oldVal) {
        let id = this.isSelectPeoList.map((res) => res.id).join();
        this.$emit("deliverFieldId", id);
        this.$emit("deliverFieldIds", id);

        // }
      },
    },
  },
};
</script>

<style lang='scss' scoped>
.shuttle-box {
  position: relative;
  .people-list {
    width: 100%;
    padding: 12px;
    padding-top: 0;
    box-sizing: border-box;
    overflow: hidden;
    .hasList {
      display: inline-block;
      width: 200px;
      height: 370px;
      color: #333;
      font-size: 12px;
      .title {
        margin-bottom: 5px;
        font-weight: 500;
      }
      .body {
        border: 1px solid #d8d8d8;
        overflow: auto;
        width: 100%;
        height: 350px;
        border-radius: 3px;
        .list {
          .list-item {
            width: 100%;
            height: 30px;
            line-height: 30px;
            padding-left: 10px;
            .name {
              float: left;
            }
            .symbol {
              float: right;
              margin-right: 15px;
              cursor: pointer;
              .icon {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
        .input-box {
          padding: 0 5px;
          box-sizing: border-box;
          position: relative;
          .icon {
            width: 14px;
            height: 14px;
            position: absolute;
            left: 5px;
            bottom: 8px;
          }
        }
        input {
          width: 100%;
          height: 30px;
          outline: none;
          border: none;
          border-bottom: 1px solid #dcdcdc;
          padding-left: 18px;
        }
      }
    }
    .isSelect {
      margin-right: 80px;
    }
  }
  .box {
    height: 45px;
  }
  .buttom-button {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 45px;
    background: #f4f6f9;
    border-radius: 3px;
    padding: 12px;
    box-sizing: border-box;
    .button {
      cursor: pointer;
      width: 54px;
      height: 25px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #dcdcdc;
      float: right;
      line-height: 25px;
      text-align: center;
      margin-right: 10px;
      font-size: 12px;
    }
    .submit {
      border: none;
      background: #1b5297;
      color: white;
    }
  }
  .el-dialog {
    margin-top: 40px !important;
    .el-dialog__header {
      border-bottom: 2px solid #f0f0f0;
    }
    .el-dialog__title {
      font-size: 16px;
    }
    .el-dialog__body {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}
</style>
