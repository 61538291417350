<template>
  <el-dialog
    :visible="dispConSetUp"
    :title="'派遣控制台设置'"
    width="780px"
    :before-close="handleClose"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <el-form ref="form" :model="form" label-width="404px">
        <el-form-item>
            <span slot="label"><b>计划时段长度</b><br>仅显示对服务预约可用的服务资源</span>
            <el-input class="elInputOne" v-model="form.theLength"></el-input>
        </el-form-item>

        <el-form-item >
            <span slot="label"><b>每个页面的服务预约</b><br>设置在页面上显示的最大服务预约数量</span>
          <el-select v-model="form.serviceNumber" placeholder="75">
            <el-option label="50" value="50"></el-option>
            <el-option label="75" value="75"></el-option>
            <el-option label="100" value="100"></el-option>
            <el-option label="125" value="125"></el-option>
            <el-option label="150" value="150"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
            <span slot="label"><b>将默认甘特图视图设置为今天</b><br>从今天开始显示甘特图，在默认情况下，显示上次访问的日期。</span>
            <el-checkbox-group v-model="form.ganttChartDates">
                <el-checkbox label="将视图设置为今天" name="type"></el-checkbox>
            </el-checkbox-group>
        </el-form-item>

        <el-form-item >
            <span slot="label"><b>默认侧栏视图</b><br>在调度人员控制台加载时，选择在侧栏显示的内容。</span>
          <el-select v-model="form.view" placeholder="预约列表">
            <el-option label="预约列表" value="预约列表"></el-option>
            <el-option label="区域" value="区域"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item >
            <span slot="label"><b>预约列表颜色编码</b><br>自定义列表中预约的背景阴影</span>
          <el-select v-model="form.shadowType" placeholder="完整阴影">
            <el-option label="完整阴影" value="完整阴影"></el-option>
            <el-option label="背景阴影" value="背景阴影"></el-option>
          </el-select>
        </el-form-item>



      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dispConSetUpCancle">取 消</el-button>
      <el-button type="primary" @click="dispConSetUpConfrim">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "dispatchConsoleSetUp",
  components: {},
  data() {
    return {
      form: {
          theLength: '',        //计划时段长度
          serviceNumber: '',    //设置在页面上显示的最大服务预约数量
          ganttChartDates: '',             //将默认甘特图视图设置为今天
          view: '',             //侧栏显示的内容
          shadowType: ''        //预约列表颜色编码
        }
    };
  },
  props: {
    dispConSetUp: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit("dispConSetUpCancle");
    },
    dispConSetUpCancle() {
      this.$emit("dispConSetUpCancle");
    },
    dispConSetUpConfrim() {
      this.$emit("dispConSetUpConfrim");
    },
  },
};
</script>

<style lang="scss" scoped>
// dialog弹框的样式设置
::v-deep .el-dialog__header {
  padding: 16px 14px 16px 36px;

  .el-dialog__title {
    font-size: 18px;
    color: #080707;
    font-weight: 600;
  }
  .el-dialog__headerbtn {
    line-height: 12px;
  }

  border-bottom: 1px solid #dcdcdc;
}

::v-deep .el-dialog__body {
  padding: 16px 36px;
  border-bottom: 1px solid #dcdcdc;
  background-color: #fff;
  //  输入框前面的文字内容样式 
  .el-form-item__label {
      text-align: left;
      line-height: 20px;
      b{
        color: #006DCC;
        font-size: 16px;
      }
  }
  //  输入框样式 
  .el-form-item__content {
    margin-left: 450px !important;
    ::v-deep .elInputOne {
        width: 125px !important;
    }
    .el-input {
        width: 100%;
        .el-input__inner {
            width: 100%;
        }
    }
    
  }
  
}
::v-deep .el-dialog__footer {
  padding: 16px 36px;
  background-color: #fff;
  ::v-deep .el-button {
    text-align: center;
    border-color: #e2e2e2;
    padding: 7px 15px;
    border-radius: 3px;
  }

  ::v-deep .el-button:last-child {
    color: #fff;
    border-color: #006dcc;
    background-color: #006dcc;
  }
}
</style>