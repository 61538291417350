import axios from "@/config/httpConfig";

// 派工台

// 获取资源技能筛选器列表数据
export function getSelectValue(data) {
    return axios.post("/view/getSelectValue", data);
}
// 获取币种信息
export function GetCurrencyInfo(data) {
    return axios.post('/currency/getCurrencyInfo', data)
}

// 获获取选项列表值
export function GetViewGetSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}
// 获取记录类型
export function getRecordType(data) {
    return axios.post('/batch/getRecordType', data)
}
// 获取资源技能筛选器
export function getFilter(data) {
    return axios.post("/filter/getFilter", data);
}

// 保存资源技能筛选器
export function saveFilter(data) {
    return axios.post("/filter/saveFilter", data);
}

// 获取当前用户可见的所有服务区域
export function findMyServiceTerritory(data) {
    return axios.post("/fieldservice/findMyServiceTerritory", data);
}

// 获取服务资源选定时间范围内的服务预约
export function getServiceAppo(data) {
    return axios.post("/resource/getServiceAppo", data);
}

//获取“显示未与服务区域关联的服务预约”是否被勾选
export function getAppointmentChecked(data) {
    return axios.post("/fieldservice/getTerritoryAppointmentChecked", data);
}

// 保存显示未与服务区域关联的服务预约是否被勾选
export function saveAppointmentChecked(data) {
    return axios.post("/fieldservice/saveTerritoryAppointmentChecked", data);
}

// 获取服务预约列表
export function getServiceAppointmentList(data) {
    return axios.post("/fieldservice/getServiceAppointmentList", data);
}

// 获取服务预约详情
export function getServiceAppointmentDetail(data) {
    return axios.post("/fieldservice/getServiceAppointmentDetail", data);
}

// 获取服务预约视图
export function getServiceAppointmentView(data) {
    return axios.post("/fieldservice/getServiceAppointmentView", data);
}

//获取全部服务资源及服务预约
export function getAllResource(data) {
    return axios.post("/resource/getAllResource", data);
}

//保存用户勾选的服务区域
export function saveCheckServiceTerritory(data) {
    return axios.post("/fieldservice/saveCheckServiceTerritory", data);
}

//保存视图筛选器
export function saveServiceAppointmentView(data) {
    return axios.post("/fieldservice/saveServiceAppointmentView", data);
}

//获取字段对象列表
export function getFieldList(data) {
    return axios.post("/field/getFieldList", data);
}

//获取服务预约视图详细信息
export function getServiceAppointmentViewInfo(data) {
    return axios.post("/fieldservice/getServiceAppointmentViewInfo", data);
}

//删除服务预约视图信息
export function deleteServiceAppointmentView(data) {
    return axios.post("/fieldservice/deleteServiceAppointmentView", data);
}

//获取对象详细信息
export function getDetail(data) {
    return axios.post("/fieldservice/getDetail", data);
}

//保存服务预约已计划
export function saveServiceAppo(data) {
    return axios.post("/serviceappo/saveServiceAppo", data);
}

//取消服务预约派遣
export function cancelServiceAppo(data) {
    return axios.post("/serviceappo/cancelServiceAppo", data);
}
//获取缺勤记录
export function getResourceAbsence(data) {
    return axios.post("/resource/getResourceAbsence", data);
}

//添加或更新对象记录
export function save(data) {
    return axios.post("/objectInfo/save", data);
}

//派工台地图获取符合条件的服务资源
export function getQualifiedServiceResource(data) {
    return axios.post("/fieldServiceMapMode/getQualifiedServiceResource", data);
}

//派工台地图模式获取快速派遣页面服务预约
export function getQuickDispatchPage(data) {
    return axios.post("/fieldServiceMapMode/getQuickDispatchPage", data);
}

//获取服务预约违规列表
export function getAppoViolationList(data) {
    return axios.post("/serviceappo/getAppoViolationList", data);
}

//获取对象权限
export function getObjectPermission(data) {
    return axios.post("objectdetail/getObjectPermission", data)
}

// 获取服务预约的技能要求跟所需产品 开发人员：赵继绪
export function getSkills(data) {
    return axios.post("distributionMap/getProductAndSkillRequired", data)
}

//获取派工台权限
export function getVisitDispatchPlatformPermission() {
    return axios.post("fieldservice/getVisitDispatchPlatformPermission")
}
// 派工台-分布图接口
//获取分布图信息
export function distributionMapInfo(data) {
    return axios.post("/distributionMap/distributionMapInfo", data)
}
//获取服务预约、服务资源地图分布统计
export function statisticalNumber(data) {
    return axios.post("/distributionMap/statisticalNumber", data)
}
//获取服务资源详细卡片信息
export function resourceDetailCard(data) {
    return axios.post("/distributionMap/resourceDetailCard", data)
}

//获取服务区域
export function getServiceterritoryList(data) {
    return axios.post("/distributionMap/getServiceterritoryList", data)
}

//获取对象记录权限
export function getPermissionById(data) {
    return axios.post('/objectdetail/getPermissionById', data)
}

// 获取查找信息
export function getLookupInfo(data) {
    return axios.post('/lookup/getLookupInfo', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
    return axios.post('objectdetail/queryMoreDataByName', data)
}
// 获取计划策略列表
export function getPolicyList() {
    return axios.post('executePolicy/getPolicyList')
}
// 判断是否弹窗
export function excutePolicy(data) {
    return axios.post('executePolicy/excutePolicy', data)
}

// 计划策略弹框保存接口
export function updateServiceApp(data) {
    return axios.post('executePolicy/updateServiceApp', data)
}
// 获取区域下候选资源
export function recommendCandidates(data) {
    return axios.post('autoRecommendPolicy/recommendCandidates', data)
}
// 根据计划策略选出预约所需服务区域列表
export function recommendCandidatesST(data) {
    return axios.post('autoRecommendPolicy/recommendCandidatesST', data)
}
// 判断分配、派遣按钮权限
export function buttonPermission(data) {
    return axios.post('assignDispatch/buttonPermission', data)
}
// 服务区域分配
export function serviceAreaAssignment(data) {
    return axios.post('executePolicy/serviceAreaAssignment', data)
}
// 服务区域派遣
export function serviceAppoDispatch(data) {
    return axios.post('assignDispatch/serviceAppoDispatch', data)
}

// 获取服务预约下有已分配服务资源记录的服务资源
export function getServieceResourceByAppo(data) {
    return axios.post('assignDispatch/getServieceResourceByAppo', data)
}
// 取消分配
export function cancelServiceAreaAssignment(data) {
    return axios.post('executePolicy/cancelServiceAreaAssignment', data)
}
// 通用权限查找接口
export function getSystemPermission(data) {
    return axios.post("/user/getSystemPermission", data);
}
// 取消派遣
export function cancelServiceAppoDispatch(data) {
    return axios.post('assignDispatch/cancelServiceAppoDispatch', data)
}
// 获取对象信息
export function getObjectInfo(data) {
    return axios.post('object/getObjectInfo', data)
}
// 获取服务区域上的服务预约
export function getServiceAppOnTerritory(data) {
    return axios.post('resource/getServiceAppOnTerritory', data)
}
// 甘特图上拖拽计划
export function dragPlan(data) {
    return axios.post('resource/dragPlan', data)
}
// 获取要展示的接单/拒单按钮
export function oRButton(data) {
    return axios.post('/servicePartner/oRButton', data)
}
// 接单
export function utOrderReceive(data) {
    return axios.post('/servicePartner/utOrderReceive', data)
}
// 拒单
export function utOrderReject(data) {
    return axios.post('/servicePartner/utOrderReject', data)
}
// 拒单原因
export function getRejectReason() {
    return axios.post('/servicePartner/getRejectReason')
}

// 获取服务预约状态图例
export function getAppoStatusLegends() {
    return axios.post('/serviceappo/getAppoStatusLegends')
}
// 保存服务预约状态图例
export function saveStatusLegends(data) {
    return axios.post('/serviceappo/saveStatusLegends',data)
}
