 <template>
  <div class="app-container">
    <!-- 甘特图头部的一些功能 -->
    <div class="theHeadFeatures">
      <!-- 搜索人员输入框   -->
      <!-- 资源  @keyup.enter.native="searchPersonnelFn"  @input="searchChangePerson"-->
      <!-- 搜索人员 $t('label.file.search.people')   @input="searchPersonnelFn" -->
      <div class="searchPerson">
        <el-input
          id="search"
          v-model="searchPersonnel"
          :placeholder="$t('label.group.search.searchthisfeed')"
          :clearable="true"
          @clear="clearSearch"
        ></el-input>
        <svg class="icon" aria-hidden="true">
          <use href="#icon-renyuan"></use>
        </svg>
      </div>
      <!-- 甘特图筛选器  @show="ganttFilterMenu" -->
      <div class="ganttFilter">
        <div :class="isShowRedSpot ? 'redspot' : 'nuspot'"></div>
        <el-popover
          placement="bottom"
          width="405"
          trigger="click"
          v-model="isShowganttFilterMenu"
          @show="clickFilter"
          popper-class="ganttpopperClass"
        >
          <div slot="reference" class="filterImg">
            <svg class="icon filterImgOne" aria-hidden="true">
              <use href="#icon-shaixuanone"></use>
            </svg>
            <svg class="icon filterImgTwo" aria-hidden="true">
              <use href="#icon-sanjiao"></use>
            </svg>
          </div>
          <div class="filterContent">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <!-- 小时tab -->
              <el-tab-pane :label="$t('label.ems.hour')" name="first">
                <div class="timeScale">
                  <!-- 选择在甘特图中显示的时间刻度 -->
                  {{ $t("label.service.gantt.timeScale") }}
                </div>
                <div class="timeBox">
                  <div class="minuteTime">
                    <!-- 日视图上显示的时间刻度： -->
                    {{ $t("label.service.gantt.day.timescale") }}
                    <el-select v-model="chooseDayTime" placeholder="请选择">
                      <el-option
                        v-for="item in dayTimeData"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="hourTime">
                    <!-- 周视图上显示的时间刻度： -->
                    {{ $t("label.service.gantt.week.timescale") }}
                    <el-select v-model="chooseWeekTime" placeholder="请选择">
                      <el-option
                        v-for="item in weekTimeData"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <!-- <div class="ganttHour">
                      <p>选择在甘特图中显示的小时数</p>
                      <div class="block">
                        <el-slider
                          v-model="timeValue"
                          range
                          :show-tooltip="false"
                          :max="24"
                        >
                        </el-slider>
                      </div>
                      {{ timeValue }}
                    </div> -->
                <!-- 周末显示控制 -->
                <div class="weekendColor">
                  <div class="weekend">
                    <el-checkbox v-model="checkedWeekend">{{
                      $t("show.week")
                    }}</el-checkbox>
                  </div>
                  <!--  高亮显示周末 -->
                  <!-- <div class="weekendHight">
                    <el-checkbox v-model="checkedWeekendHight"
                      >

                      {{$t("label.service.gantt.weekend.highlight")}}
                      </el-checkbox
                    >
                  </div> -->
                </div>
                <div class="cancleAndSaveHour">
                  <el-button @click="cancleServiceBtn">{{
                    $t("label.emailsync.button.cancel")
                  }}</el-button>
                  <el-button type="primary" @click="saveServiceButton">{{
                    $t("label.chatter.save")
                  }}</el-button>
                </div>
                <!-- 长期视图设置 -->
                <!-- <div class="longViewSettings">
                      <p>长期视图设置</p>
                      <p>
                        <el-checkbox v-model="checkedWeek3"
                          >仅显示多日服务预约</el-checkbox
                        >
                      </p>
                      <p class="monthNumber">
                        要显示到月数：
                        <el-input-number
                          v-model="monthNum"
                          controls-position="right"
                          @change="handleChange"
                          :min="1"
                          :max="10"
                          size="mini"
                        ></el-input-number>
                      </p>
                      <p>要隐藏短期内预约和缺勤，设置最小持续时间（小时）。</p>
                      <p class="appointmentNumber">
                        适合服务预约：<el-input-number
                          v-model="appointmentNum"
                          controls-position="right"
                          @change="handleChange"
                          :min="1"
                          :max="10"
                          size="mini"
                        ></el-input-number>
                      </p>
                      <p class="resourceNumber">
                        适合资源缺勤：<el-input-number
                          v-model="resourceNum"
                          controls-position="right"
                          @change="handleChange"
                          :min="1"
                          :max="10"
                          size="mini"
                        ></el-input-number>
                      </p>
                    </div> -->
              </el-tab-pane>
              <!-- 资源tab   $t('label.service.resources')-->
              <el-tab-pane :label="$t('label.service.resources')" name="second">
                <!-- 排序方式： -->
                <!-- <div class="theSortingWay">
                      <el-form ref="form" :model="form">
                        <el-form-item label="排序方式：">
                          <el-select
                            v-model="form.region"
                            placeholder="资源类型"
                            size="mini"
                          >
                            <el-option label="资源类型" value="1"></el-option>
                            <el-option label="技术类型" value="2"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-form>
                    </div> -->
                <!-- 资源显示 -->
                <!--仅限为$t('label.service.gantt.ziyuan')  仅显示工作资源$t('label.service.gantt.jobresour')  和$t("lable.quote.syncing.and")
                  基于工时$t('label.service.gantt.workhours')  有效$t('label.service.gantt.effective')   和资源类型是$t('label.service.gantt.and.resourcestype')
                  或$t('label.group.no.recent3')      的资源$t('label.service.gantt.resources') -->
                <div class="resourcesShow">
                  <!-- 显示 被分配工作 -->
                  {{ $t("label.email.show") }}
                  <div
                    v-show="
                      this.checkList.includes(
                        $t('label.service.gantt.resources.assigned.work')
                      ) && checkListOne.length <= 0
                    "
                  >
                    {{ $t("label.service.gantt.resources.assigned.work") }}
                  </div>
                  <div
                    v-show="
                      this.checkList.includes(
                        $t('label.service.gantt.resources.assigned.work')
                      ) && checkListOne.length > 0
                    "
                  >
                    {{ $t("label.service.gantt.assigned.work") }}
                  </div>
                  <span
                    v-show="
                      this.checkList.includes(
                        $t('label.service.gantt.resources.assigned.work')
                      ) &&
                      this.checkList.includes(
                        $t('label.service.gantt.work.based.resources')
                      ) &&
                      checkListOne.length <= 0
                    "
                  >
                    <!-- 和 -->
                    {{ $t("lable.quote.syncing.and") }}
                  </span>
                  <span
                    v-show="
                      this.checkList.includes(
                        $t('label.service.gantt.resources.assigned.work')
                      ) &&
                      this.checkList.includes(
                        $t('label.service.gantt.work.based.resources')
                      ) &&
                      checkListOne.length > 0
                    "
                  >
                    <!-- 且 -->
                    {{ $t("lable.service.gantt.and") }}
                  </span>
                  <div
                    v-show="
                      this.checkList.includes(
                        $t('label.service.gantt.work.based.resources')
                      ) && checkListOne.length <= 0
                    "
                  >
                    {{ $t("label.service.gantt.work.based.resources") }}
                    <!-- 基于工时的资源 -->
                  </div>
                  <div
                    v-show="
                      this.checkList.includes(
                        $t('label.service.gantt.work.based.resources')
                      ) && checkListOne.length > 0
                    "
                  >
                    <!-- 基于工时 -->
                    {{ $t("label.service.gantt.workhours") }}
                  </div>
                  <span
                    v-show="
                      (this.checkList.includes(
                        $t('label.service.gantt.resources.assigned.work')
                      ) ||
                        this.checkList.includes(
                          $t('label.service.gantt.work.based.resources')
                        )) &&
                      this.checkList.includes(
                        $t('label.service.gantt.enabled.resources')
                      ) &&
                      checkListOne.length <= 0
                    "
                  >
                    <!-- 和 -->
                    {{ $t("lable.quote.syncing.and") }}
                  </span>
                  <span
                    v-show="
                      (this.checkList.includes(
                        $t('label.service.gantt.resources.assigned.work')
                      ) ||
                        this.checkList.includes(
                          $t('label.service.gantt.work.based.resources')
                        )) &&
                      this.checkList.includes(
                        $t('label.service.gantt.enabled.resources')
                      ) &&
                      checkListOne.length > 0
                    "
                  >
                    <!-- 且 -->
                    {{ $t("lable.service.gantt.and") }}
                  </span>
                  <div
                    v-show="
                      this.checkList.includes(
                        $t('label.service.gantt.enabled.resources')
                      ) && checkListOne.length <= 0
                    "
                  >
                    <!-- 已启用的资源 -->
                    {{ $t("label.service.gantt.enabled.resources") }}
                  </div>
                  <div
                    v-show="
                      this.checkList.includes(
                        $t('label.service.gantt.enabled.resources')
                      ) && checkListOne.length > 0
                    "
                  >
                    {{ $t("label.inusing") }}
                  </div>
                  <!-- 和资源类型是 -->
                  <!-- 的资源 -->
                  <!-- 或 -->
                  <div v-show="checkListOne.length > 0" class="andorbox">
                    <div class="font-nor" v-if="this.checkList.length > 0">
                      {{ $t("label.forecast.detailforecast.de") }}
                    </div>
                    <div
                      v-for="(itemBase, indexBase) in checkListOne"
                      :key="indexBase"
                      class="orbox"
                    >
                      <div
                        class="font-nor"
                        v-show="checkListOne.length > 1 && indexBase !== 0"
                      >
                        {{ $t("label.group.no.recent3") }}
                      </div>
                      <div class="font-bor">{{ reRoles[itemBase] }}</div>
                    </div>
                    <div v-show="checkList.length == 0" class="font-nor">
                      {{ $t("label.emailtocloudcc.filed.type")
                      }}{{ $t("label.service.gantt.resources") }}
                    </div>
                  </div>
                </div>
                <div class="resourcesShowItems">
                  <div class="skillMode">
                    {{ $t("label.group.filter.resources.and") }}
                    <!-- 选择筛选资源的方式，且的关系 -->
                  </div>
                  <el-checkbox-group
                    v-model="checkList"
                    @change="handleCheckedChange"
                  >
                    <div>
                      <el-checkbox
                        :label="
                          $t('label.service.gantt.resources.assigned.work')
                        "
                      ></el-checkbox>
                    </div>
                    <el-checkbox
                      :label="$t('label.service.gantt.work.based.resources')"
                    ></el-checkbox>
                    <el-checkbox
                      :label="$t('label.service.gantt.enabled.resources')"
                    ></el-checkbox>
                  </el-checkbox-group>
                </div>
                <!-- 其他属性 -->
                <!-- 资源类型  label.service.gantt.resourcestype-->
                <div class="OtherAttributes">
                  <!-- <p>一级以下其中一种属性</p> -->
                  <!-- <el-form ref="form" :model="form">
                        <el-form-item label="">
                          <el-select
                            v-model="form.resourceType"
                            placeholder="选择字段"
                            size="mini"
                          >
                            <el-option label="选择字段" value="1"></el-option>
                            <el-option label="技术类型" value="2"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-form> -->
                  <p>
                    <!-- 资源类型  以及以下其中一种资源类型-->
                    {{ $t("label.service.gantt.resourcestype") }}
                  </p>
                  <span>
                    {{ $t("label.service.gantt.one.esource.types") }}
                  </span>
                  <div class="getfilterreSources">
                    <div class="getfilterreSourcesItems">
                      <el-checkbox-group v-model="checkListOne">
                        <el-checkbox
                          v-for="(item, index) in getfilterreSourcesData"
                          :key="index"
                          :label="item.id"
                          >{{ item.codevalue }}</el-checkbox
                        >
                      </el-checkbox-group>
                    </div>
                  </div>
                </div>
                <!-- 班组筛选 -->
                <!-- <div class="teamScreening">
                      <el-form ref="form" :model="form">
                        <el-form-item label="班组筛选：">
                          <el-select
                            v-model="form.teamScreen"
                            placeholder="全部显示"
                            size="mini"
                          >
                            <el-option label="全部显示" value="1"></el-option>
                            <el-option label="隐藏服务班组成员" value="2"></el-option>
                            <el-option
                              label="显示班组及其服务班组成员"
                              value="3"
                            ></el-option>
                            <el-option label="仅显示班组" value="4"></el-option>
                            <el-option
                              label="隐藏班组和服务班组成员"
                              value="5"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-form>
                    </div> -->
                <!-- 取消 label.emailsync.button.cancel 和 保存 label.chatter.save -->
                <div class="cancleAndSaveResources">
                  <el-button @click="cancleServiceBtn">{{
                    $t("label.emailsync.button.cancel")
                  }}</el-button>
                  <el-button type="primary" @click="saveServiceButton">{{
                    $t("label.chatter.save")
                  }}</el-button>
                </div>
              </el-tab-pane>
              <!-- 技能tab  label.service.dispatchingUnits.skills    搜索技能 label.service.gantt.searchSkills
                   选择全部label.h5.similar.opportunity.select.all   取消全选label.cancelall  -->
              <el-tab-pane
                :label="$t('label.service.dispatchingUnits.skills')"
                name="third"
              >
                <div class="skillScreening">
                  <div>
                    <el-checkbox v-model="useScreening" disabled>
                      <!-- 使用技能筛选 -->
                      {{ $t("label.service.gantt.skill.screening") }}
                    </el-checkbox>
                  </div>
                  <div class="filterBox">
                    <!-- 筛选器逻辑： -->
                    {{ $t("label.service.gantt.filter.logic") }}
                    <el-select v-model="filterChooseLogic" placeholder="请选择">
                      <el-option
                        v-for="item in filterLogicData"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="skillsControl">
                  <el-input
                    v-model="searchSkills"
                    :placeholder="
                      $t('label.service.gantt.searchSkills') + '...'
                    "
                  ></el-input>
                  <div class="skillsCheckAllAndCancel">
                    <el-button @click="skillsCheckAll">{{
                      $t("label.h5.similar.opportunity.select.all")
                    }}</el-button>
                    <el-button @click="cancleSkillsCheckAll">{{
                      $t("label.cancelall")
                    }}</el-button>
                  </div>
                </div>
                <div class="getfilterreSkills">
                  <!-- getfilterData -->
                  <div class="getfilterreSkillsItems">
                    <el-checkbox-group v-model="checkListTwo">
                      <el-checkbox
                        v-for="(item, index) in getfilterreSkillsData"
                        :key="index"
                        :label="item.id"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </div>
                <!-- 筛选技能提示 -->
                <!-- 在使用技能筛选时，仅拥有选定技能的资源才会显示到甘特图上  label.service.gantt.filter-->
                <div class="filterTip">
                  {{ $t("label.service.gantt.filter") }}
                  <!-- 在使用技能筛选时，仅拥有选定技能的资源才会显示到甘特图上 -->
                </div>
                <!-- 取消 label.emailsync.button.cancel 和 保存 label.chatter.save -->
                <div class="cancleAndSaveSkills">
                  <el-button @click="cancleServiceBtn">{{
                    $t("label.emailsync.button.cancel")
                  }}</el-button>
                  <el-button type="primary" @click="saveServiceButton">{{
                    $t("label.chatter.save")
                  }}</el-button>
                </div>
              </el-tab-pane>
              <!-- 利用率tab -->
              <!-- <el-tab-pane label="利用率" name="fourth">
                    <div class="utilization">
                      <p>以及以下其中一个属性</p>
                      <div class="utilizationItemone">
                        <el-checkbox v-model="checked">预约</el-checkbox>
                        <el-checkbox v-model="checked">出差</el-checkbox>
                      </div>
                      <div class="utilizationItemtwo">
                        <el-checkbox v-model="checked">缺席</el-checkbox>
                        <el-checkbox v-model="checked">休息</el-checkbox>
                      </div>
                      <div class="utilizationItemthree">
                        <el-checkbox v-model="checked">加班</el-checkbox>
                      </div>
                      <div class="viewDayNumber">
                        使用率视图中的天数：<el-input-number
                          v-model="viewDayNum"
                          controls-position="right"
                          @change="handleChange"
                          :min="1"
                          :max="10"
                          size="mini"
                        ></el-input-number>
                      </div>
                    </div>
                  </el-tab-pane> -->
            </el-tabs>
          </div>
        </el-popover>
      </div>
      <!-- 日期选择 -->
      <!-- 选择日期 label.tabpage.selectdate -->
      <div class="theCalendar">
        <div class="block">
          <el-date-picker
            v-model="ganttDateValue"
            @change="ganttDateValueMethods"
            type="date"
            :placeholder="$t('label.tabpage.selectdate')"
            :clearable="false"
            :picker-options="pickerStartOptions"
          >
          </el-date-picker>
        </div>
      </div>
    </div>

    <!-- 甘特表 -->
    <div class="gantt-container" v-loading="isShowLoading">
      <FullCalendar
        ref="myCalendar"
        style="width: 100%; height: 100%"
        :options="calendarOptions"
      />
      <!--选择的服务区域下没找到任何可用的服务资源 label.service.gantt.noservice.resource -->
      <div class="no-data" v-show="calendarOptions.resources.length == 0">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-noDatas"></use>
        </svg>

        <div class="word">
          <!-- 选择的服务区域下没找到任何可用的服务资源 -->
          {{ $t("label.service.gantt.noservice.resource") }}
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="page" v-if="false">
      <svg class="icon img-grey" aria-hidden="true" v-show="currentPage == 1">
        <use href="#icon-leftArrow"></use>
      </svg>
      <!-- <img
        src="@/assets/dispatchingUnits/leftArrow"
        class="img-grey"
        alt=""
        v-show="currentPage == 1"
      /> -->
      <svg class="icon" aria-hidden="true" v-show="currentPage > 1">
        <use href="#icon-leftArrowLiang"></use>
      </svg>
      <!-- <img
        src="@/assets/dispatchingUnits/leftArrowLiang"
        alt=""
        v-show="currentPage > 1"
        @click="reduce"
      /> -->
      <div class="page-number">{{ currentPage }}</div>
      <svg
        class="icon img-grey"
        aria-hidden="true"
        v-show="currentPage == maxPage"
      >
        <use href="#icon-rightArrow"></use>
      </svg>
      <!-- <img
        src="@/assets/dispatchingUnits/rightArrow"
        class="img-grey"
        alt=""
        v-show="currentPage == maxPage"
      /> -->
      <svg
        class="icon"
        aria-hidden="true"
        v-show="currentPage < maxPage"
        @click="add"
      >
        <use href="#icon-rightArrowLiang"></use>
      </svg>
      <!-- <img
        src="@/assets/dispatchingUnits/rightArrowLiang"
        alt=""
        v-show="currentPage < maxPage"
        @click="add"
      /> -->
    </div>

    <!--    tippy悬浮窗html，仅支持行内样式---start-->
    <div
      id="service-popover"
      style="
        display: none;
        background: #ffffff;
        padding: 16px 12px 12px 12px;
        width: 320px;
      "
    >
      <div style="border-bottom: 1px solid #dddfe8">
        <div style="display: flex; align-items: center; margin-bottom: 5px">
          <div
            style="
              width: 34px;
              height: 34px;
              border-radius: 2px;
              margin-right: 10px;
            "
          >
            <svg
              class="icon"
              aria-hidden="true"
              style="display: inline-block; width: 32px; height: 32px"
            >
              <use href="#icon-smallCar"></use>
            </svg>
            <!-- <img src="@/assets/dispatchingUnits/smallCar" alt=""> -->
          </div>
          <div>
            <span
              style="
                font-size: 18px;
                font-weight: bold;
                color: #1d2033;
                line-height: 24px;
              "
            >
              {{ popoverEventData.title }}
            </span>
            <span style="font-size: 12px; color: #1d2033; ine-height: 16px">
              （{{
                popoverEventData.popoverStatus ||
                $t("label.projectManagement.planned")
              }}）
            </span>
          </div>
        </div>
        <div>
          <div
            style="
              display: flex;
              font-size: 12px;
              line-height: 16px;
              margin-bottom: 10px;
            "
          >
            <div style="color: #1d2033; font-weight: bold">
              {{ $t("label.service.appointmentList.earliest.allowedStart") }}:
            </div>
            <div style="color: #1d2033">
              {{ popoverEventData.popoverEarly }}
            </div>
          </div>
          <div
            style="
              display: flex;
              font-size: 12px;
              line-height: 16px;
              margin-bottom: 10px;
            "
          >
            <div style="color: #1d2033; font-weight: bold">
              {{ $t("label.chatter.maturiyDate") }}:
            </div>
            <div style="color: #1d2033">
              {{ popoverEventData.popoverDuedate }}
            </div>
          </div>
          <div
            style="
              display: flex;
              font-size: 12px;
              line-height: 16px;
              margin-bottom: 10px;
            "
          >
            <div style="color: #1d2033; font-weight: bold">
              <!--              大约出行距离/预计出行时间:-->
              {{ $t("label.service.gantt.popoverTip") }}:
            </div>
            <div style="color: #1d2033" v-if="popoverEventData.popoverDistance">
              {{ popoverEventData.popoverDistance || "0" }} km /
              {{ popoverEventData.popoverHours || "0" }} h
            </div>
          </div>
        </div>
      </div>
      <div style="border-bottom: 1px solid #dddfe8; padding-top: 16px">
        <div style="font-size: 12px; line-height: 16px; margin-bottom: 10px">
          <div style="">
            <span style="color: #1d2033; font-weight: bold; width: 100%">
              {{ $t("label.ServiceCloud.jinengyaoqiu") }}:</span
            >
            <span style="color: #1d2033">
              {{ skillStr }}
            </span>
          </div>
        </div>
        <div style="font-size: 12px; line-height: 16px; margin-bottom: 10px">
          <div style="">
            <span style="color: #1d2033; font-weight: bold; width: 100%">
              {{ $t("label.ServiceCloud.suoxuchanpin") }}:</span
            >
            <span style="color: #1d2033">
              {{ productStr }}
            </span>
          </div>
        </div>
      </div>
      <div style="padding-top: 16px">
        <div
          style="
            display: flex;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 10px;
            justify-content: space-between;
          "
        >
          <div style="color: #1d2033; font-weight: bold">
            {{ $t("label.service.appointmentList.plansToStart") }}:
          </div>
          <div style="color: #1d2033">{{ popoverEventData.start }}</div>
        </div>
        <div
          style="
            display: flex;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 10px;
            justify-content: space-between;
          "
        >
          <div style="color: #1d2033; font-weight: bold">
            {{ $t("label.service.appointmentList.plansToEnd") }}:
          </div>
          <div style="color: #1d2033">{{ popoverEventData.end }}</div>
        </div>
      </div>
    </div>

    <div id="eventImg" style="display: none">
      <div style="padding: 10px 0 0 0; border-top: 1px solid #dddfe8">
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <svg
            class="icon"
            aria-hidden="true"
            style="
              display: inline-block;
              width: 16px;
              height: 16px;
              margin-right: 5px;
            "
          >
            <use href="#icon-warnings"></use>
          </svg>
          <!-- <img
              src="@/assets/dispatchingUnits/warnings"
              style="width: 16px; height: 16px; margin-right: 5px; "
          /> -->
          <!--违反规则 label.service.gantt.break.rules -->
          <span
            style="
              font-weight: bold;
              font-size: 12px;
              color: #1d2033;
              line-height: 16px;
            "
          >
            <!-- 违反规则 -->
            {{ $t("label.service.gantt.break.rules") }}</span
          >
        </div>
        <!-- <div
          v-for="(item, index) in currentViolation"
          :key="index"
          style="
            font-size: 12px;
            color: #1d2033;
            line-height: 16px;
            margin-bottom: 5px;
          "
        >
          {{ item }}
        </div> -->
      </div>
    </div>
    <!--    tippy悬浮窗html，仅支持行内样式---end-->

    <div id="eventNone" style="display: none"></div>
    <!-- <div class="fc-today-button" >
      今天
    </div> -->
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

import tippy, { followCursor } from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/scale.css";
import {
  getAppoViolationList,
  getFilter,
  saveFilter,
  getAllResource,
  saveServiceAppo,
  getResourceAbsence,
  save,
  getPermissionById,
  getServiceAppOnTerritory,
  serviceAreaAssignment,
  // serviceAppoDispatch,
  dragPlan,
  findMyServiceTerritory,
} from "../api";

export default {
  props: {
    // treeString: {
    //   type: String,
    //   default: "",
    // },
    appoJurisdiction: {
      type: Object,
      default: () => ({}),
    },
    // ResourceJurisdiction: {
    //   type: Object,
    //   default: ()=>({}),
    // },
  },
  name: "gantt",
  data() {
    let that = this;
    return {
      popoverEventData: {},
      productStr: "",
      skillStr: "",
      dayTimeData: [
        {
          value: "15",
          label: this.$i18n.t("label.appointment.wizard.subtitle4-3-2"), //15分钟
        },
        {
          value: "1",
          label: this.$i18n.t("label.appointment.wizard.subtitle1-5"), //1小时
        },
      ], //日视图时间刻度
      chooseDayTime: "15", //日视图时间刻度，默认15分钟
      weekTimeData: [
        {
          value: "1",
          label: this.$i18n.t("label.appointment.wizard.subtitle1-5"), //1小时
        },
        {
          value: "2",
          label: this.$i18n.t("label.appointment.wizard.subtitle1-7"), //2小时
        },
        {
          value: "4",
          label: this.$i18n.t("label.appointment.wizard.subtitle1-9"), //4小时
        },
      ],
      //周视图时间刻度
      chooseWeekTime: "4", //周视图时间刻度默认4小时
      useScreening: true, //使用技能筛选
      filterChooseLogic: "OR", //筛选器逻辑
      checkedWeekend: true, //显示周末
      isShowRedSpot: false, //筛选器资源或技能是否有筛选条件
      checkedWeekendHight: false, //高亮显示周末
      currentDayOrWeek: "resourceTimeDay", //记录当前是日还是周,默认是日
      //禁用周末日期范围
      pickerStartOptions: {
        disabledDate(time) {
          if (time && that.checkedWeekend == false) {
            return time.getDay() == "6" || time.getDay() == "0";
          } else {
            return "";
          }
        },
      },
      // 筛选条件
      filterLogicData: [
        {
          value: "AND",
          label: this.$i18n.t("lable.service.gantt.and"), //且
        },
        {
          value: "OR",
          label: this.$i18n.t("or"), //或
        },
      ],
      //筛选器逻辑数据
      reRoles: {},
      // 加载loading
      isShowLoading: false,
      // 日历对象
      calendarApi: null,
      // 日历参数配置
      calendarOptions: {
        // 许可
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        // 插件
        plugins: [
          resourceTimelinePlugin,
          interactionPlugin,
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
        ],
        // 默认全部收起
        resourcesInitiallyExpanded: false,
        // 是否启用拖拽
        droppable: true,
        // 时区
        // timeZone: "UTC",
        // 当前时间标识
        nowIndicator: true,
        // 多语言
        locale:
          this.$store.state.userInfoObj.language === "jp"
            ? "ja"
            : this.$store.state.userInfoObj.language,
        // 加载日历时的初始视图 resourceTimelineDay天、resourceTimelineWeek周、resourceTimelineMonth月
        initialView: "resourceTimelineDay",
        // Event日程开始时间可以改变，默认true，如果是false其实就是指日程块不能随意拖动，只能上下拉伸改变他的endTime
        // eventStartEditable: true,
        // Event日程的开始结束时间距离是否可以改变，默认true，如果是false则表示开始结束时间范围不能拉伸，只能拖拽
        // eventDurationEditable: true,
        // 设置一周中显示的第一天是哪天，周日是0，周一是1，类推
        firstDay: 1,
        //设置日历的高度，包括header日历头部，
        height: "0",
        // 设置日历单元格宽度与高度的比例
        aspectRatio: 1.5,
        // 确定是否可以修改日历上的事件。
        editable: false,
        // 初始侧边资源宽度
        resourceAreaWidth: "18%",
        // 侧边资源 标题  服务资源 this.$i18n.t('label.service.gantt.service.resources')
        resourceAreaHeaderContent: this.$i18n.t(
          "label.service.gantt.service.resources"
        ),
        // 确定每天显示的第一个时间段。
        slotMinTime: "00:00:00",
        //时间间隔
        slotDuration: "00:30:00",
        //单击日历日程事件
        eventClick: this.handleEventClick,
        //拖动日历日程事件
        eventDrop: this.eventDrop,
        //日程大小调整完成并已经执行更新时触发
        eventResize: this.eventResize,
        //鼠标移入事件
        // eventMouseEnter: this.eventMouse,
        eventMouseEnter: () => {},
        //鼠标移入事件
        eventMouseLeave: this.eventLeave,
        // 外部拖拽事件
        drop: this.drop,
        // 外部事件接收
        eventReceive: this.eventReceive,
        // 在日历的日期范围最初设置或以某种方式更改并且 DOM 已更新后调用。
        datesSet: this.datesSet,
        // 营业时间 强调日历上的某些时间段
        // businessHours: {
        //   daysOfWeek: [1, 2, 3, 4, 5], // 周一 - 周四
        //   startTime: "6:00", // 上午7点开始
        //   endTime: "20:00", // 下午8点结束
        // },
        // 服务资源侧边挂载方法
        resourceLabelDidMount: (info) => {
          info.el.addEventListener("click", () => {
            this.handleAsideClick(info);
          });
        },
        //是否允许拖动和调整大小的事件相互重叠
        eventOverlap: (stillEvent, movingEvent) => {
          this.movingTitle = movingEvent.title;
          return true;
        },
        // 设置日历头部信息
        headerToolbar: {
          right: "prev,today,next",
          center: "title",
          left: "resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth",
        },
        // 自定义操作按钮
        customButtons: {
          prev: {
            click: () => {
              this.switchDate(-1);
            },
          },
          today: {
            text: this.$i18n.t("today"), //今天 this.$i18n.t('today')
            click: () => {
              this.switchDate(0);
            },
          },
          next: {
            click: () => {
              this.switchDate(1);
            },
          },
          resourceTimelineDay: {
            text: this.$i18n.t("label.schedulejob.setup.schedule.day"), //日 this.$i18n.t('label.schedulejob.setup.schedule.day')
            click: () => {
              this.resourceDayMethod();
            },
          },
          resourceTimelineWeek: {
            text: this.$i18n.t("label.week"), //周 this.$i18n.t('label.week')
            click: () => {
              this.resourceWeekMethod();
            },
          },
          resourceTimelineMonth: {
            text: this.$i18n.t("label.login.access.month"), //月 this.$i18n.t('label.login.access.month')
            click: () => {
              this.resourceMonthMethod();
            },
          },
        },
        // 按钮文本
        buttonText: {
          today: this.$i18n.t("today"),
          month: this.$i18n.t("label.login.access.month"),
          week: this.$i18n.t("label.week"),
          day: this.$i18n.t("label.schedulejob.setup.schedule.day"),
        },
        // 将事件拖动和调整大小限制在某些时间范围内。
        // eventConstraint: {
        //   daysOfWeek: [1, 2, 3, 4, 5], // 周一 - 周四
        //   startTime: "6:00", // 上午7点开始
        //   endTime: "20:00", // 下午8点结束
        // },
        // 左侧对象
        resources: [],
        //显示服务预约区域
        // resourceGroupField: "building",
        // 右侧事件
        events: [],
        //设置日历主体内容的高度，不包括header部分
        // contentHeight:400,
        // buttonIcons: true,
        //是否显示周末，设为false则不显示周六和周日。
        weekends: that.checkedWeekend,
        //是否在日历中显示周次(一年中的第几周)
        // weekNumbers:true,
      },
      searchSkills: "", // 搜索技能
      searchPersonnel: "", // 搜索人员
      isShowganttFilterMenu: false, // 甘特图筛选器下拉展开
      activeName: "second", // Tabs 默认选中
      // timeValue: [0, 24],
      // checkedWeek1: false,
      // checkedWeek2: false,
      // checkedWeek3: false,
      checkList: [], //存储筛选器资源中 "仅显示工作资源" "基于工时" "有效"  被选中的数组
      checkListOne: [], //存储筛选器的  选中资源类型ID的数组
      checkListTwo: [], //存储筛选器的   选中技能ID的数组
      skillsCheckAllArray: [], //存储筛选器的 所有技能ID的数组
      // monthNum: 1, // 要显示到月数
      // appointmentNum: 1, // 服务预约
      // resourceNum: 1, // 资源缺勤
      // viewDayNum: 1, // 使用率视图中的天数
      ganttDateValue: "", // 甘特表日历
      // form: {
      //   region: "",
      //   resourceType: "",
      //   teamScreen: "",
      // },
      getfilterData: [], //甘特筛选器下拉数据
      getfilterreSourcesData: [], //甘特筛选器资源类型数据
      searchGetfilterSkillsData: [], //所有甘特筛选器技术类型数据 用来搜索查询的数组
      ganttData: {},
      //当前页数
      currentPage: 1,
      //每页数量
      pageNumber: 20,
      //最早允许开始时间
      // earlieststarttime: new Date("2021-3-10").getTime(),
      //计划结束时间
      // duedate: new Date("2021-3-16").getTime(),
      //当前移动后重叠的条目名称
      movingTitle: "",
      onlyshowresourceData: "", //被分配工作的资源（勾选时传值1）
      iscapacitybasedData: "", //基于工时的资源（勾选传1）
      isactiveData: "", //已启用的资源（勾选传1）
      ganttStartTime: "",
      ganttEndTime: "",
      //服务区域id与服务资源id匹配
      idFit: [],
      //最大页数
      maxPage: 0,
      //总页数
      totalCount: 0,
      //违规信息
      violationList: [],
      //当前违规信息
      currentViolation: [],
      instance: {},
      assidArr: [],
      // 服务区域ID
      treeString: "",
    };
  },
  components: {
    FullCalendar,
  },
  watch: {
    // treeString(newVal, oldVal) {
    //   if (newVal != oldVal && newVal != "") {
    //     this.currentPage = 1;
    //     this.getFilter();
    //   }
    //   if (newVal != oldVal && newVal == "") {
    //     this.calendarOptions.resources = [];
    //     this.calendarOptions.events = [];
    //     this.calendarOptions.height = "0";
    //   }
    // },
    searchPersonnel(newVal, oldVal) {
      if (newVal != oldVal && this.treeString != "") {
        this.currentPage = 1;
        this.getResource();
      }
    },
  },
  computed: {
    // 筛选器 技能 搜索
    getfilterreSkillsData() {
      if (!this.searchSkills) {
        return this.searchGetfilterSkillsData;
      }
      return this.searchGetfilterSkillsData.filter((v) => {
        return v.name.includes(this.searchSkills);
      });
    },
  },
  methods: {
    // 获取区域
    async getDefaultArea() {
      let res = await findMyServiceTerritory();
      let idArr = [];
      if (res.result) {
        res.data.map((item) => {
          if (item.ischecked === "true") {
            idArr.push(item.id);
            if (
              item.childServiceTerritory &&
              item.childServiceTerritory.length > 0
            ) {
              item.childServiceTerritory.map((itemChild) => {
                idArr.push(itemChild.id);
              });
            }
          }
        });
      }
      if (idArr.length > 0) {
        this.treeString = idArr.toString();
        this.getFilter();
      } else {
        this.treeString = "";
        this.calendarOptions.resources = [];
        this.calendarOptions.events = [];
        this.calendarOptions.height = "0";
      }
    },
    // 搜索框
    // searchPersonnelFn(){
    //   if(this.searchPersonnel&&this.treeString != ""){
    //     this.currentPage = 1;
    //     this.getResource();
    //   }
    // },
    // 清空搜索框调取接口
    // searchChangePerson(){
    //   if(this.searchPersonnel === ""){
    //     this.currentPage = 1;
    //     this.getResource();
    //   }
    // },
    // 清空搜索
    clearSearch() {
      this.searchPersonnel = "";
      if (this.searchPersonnel === "") {
        this.currentPage = 1;
        this.getResource();
      }
    },
    // 小时，技能,资源取消
    cancleServiceBtn() {
      this.isShowganttFilterMenu = false;
    },
    // 小时，技能,资源保存
    saveServiceButton() {
      let params;
      // 小时
      if (this.activeName == "first") {
        params = {
          type: "hour",
          weekendhighlight: this.checkedWeekendHight ? "true" : "false", //	String	高亮显示周末，true或者false
          weekendshow: this.checkedWeekend ? "true" : "false", //String	显示周末，true或者false
          weektimescale: this.chooseWeekTime, //	String	周视图上显示的时间刻度
          daytimescale: this.chooseDayTime, //String	日视图上显示的时间刻度
        };
        // 资源
      } else if (this.activeName == "second") {
        this.checkList.includes(
          this.$i18n.t("label.service.gantt.resources.assigned.work")
        )
          ? (this.onlyshowresourceData = "1")
          : (this.onlyshowresourceData = "0");
        this.checkList.includes(
          this.$i18n.t("label.service.gantt.work.based.resources")
        )
          ? (this.iscapacitybasedData = "1")
          : (this.iscapacitybasedData = "0");
        this.checkList.includes(
          this.$i18n.t("label.service.gantt.enabled.resources")
        )
          ? (this.isactiveData = "1")
          : (this.isactiveData = "0");
        params = {
          type: "resource",
          onlyshowresource: this.onlyshowresourceData,
          iscapacitybased: this.iscapacitybasedData,
          isactive: this.isactiveData,
          resourcetypeid: this.checkListOne.toString(),
          // skillid: this.checkListTwo.toString(),
        };
      } else {
        //技能
        params = {
          type: "skill",
          skillid: this.checkListTwo.toString(),
          filterlogic: this.filterChooseLogic,
          usefilterlogic: "true",
        };
      }
      saveFilter(params)
        .then(() => {
          // 保存小时选项卡时，刷新甘特图刻度，日，周，月，tab:为小时不掉接口
          if (this.activeName == "first") {
            if (this.currentDayOrWeek == "resourceTimeDay") {
              this.DayTimeScale();
            } else if (this.currentDayOrWeek == "resourceTimeWeek") {
              this.WeekTimeScale();
            } else if (this.currentDayOrWeek == "resourceTimeMonth") {
              this.monthTimeScale();
            }
            this.calendarOptions.weekends = this.checkedWeekend;
          } else {
            //tab:为资源和技能的时候调取接口
            if (this.treeString != "") {
              this.getResource();
            }
          }
          // 判断资源和技能中是否勾选条件
          if (
            this.checkList.length > 0 ||
            this.checkListOne.length > 0 ||
            this.checkListTwo.length > 0
          ) {
            this.isShowRedSpot = true;
          } else {
            this.isShowRedSpot = false;
          }
        })
        .catch(() => {});
      this.isShowganttFilterMenu = false;
    },
    // 日的刻度
    DayTimeScale() {
      this.calendarOptions.slotDuration =
        this.chooseDayTime == "15" ? "00:15:00" : "01:00:00";
    },
    // 周的刻度
    WeekTimeScale() {
      this.calendarOptions.slotDuration = "0" + this.chooseWeekTime + ":00:00";
    },
    // 月的刻度
    monthTimeScale() {
      this.calendarOptions.slotDuration = "00:00:00";
    },
    /**
     * 父组件ref调用
     * 跳转至指定日期
     */
    locationToGrantt(dd) {
      this.calendarApi.gotoDate(dd);
      this.ganttDateValue = dd;
      this.$emit("ganttDateValueMethod", this.ganttDateValue);
      if (this.treeString != "") {
        this.getResource();
      }
    },
    //左箭头
    // reduce() {
    //   if (this.currentPage > 1) {
    //     this.currentPage--;
    //   }
    //   //
    //   // this.$refs.myCalendar.getApi().refetchEvents()
    // },
    //右箭头
    add() {
      if (this.currentPage < this.maxPage) {
        this.currentPage++;
      }
    },
    // handleDatesRender(arg) {},
    resourceDayMethod() {
      this.calendarApi.changeView("resourceTimelineDay");
      if (this.treeString != "") {
        this.getResource();
      }
      this.DayTimeScale();
      this.currentDayOrWeek = "resourceTimeDay";
    },
    resourceWeekMethod() {
      this.calendarApi.changeView("resourceTimelineWeek");
      if (this.treeString != "") {
        this.getResource();
      }
      this.WeekTimeScale();
      this.currentDayOrWeek = "resourceTimeWeek";
    },
    resourceMonthMethod() {
      this.calendarApi.changeView("resourceTimelineMonth");
      if (this.treeString != "") {
        this.getResource();
      }
      this.monthTimeScale();
      this.currentDayOrWeek = "resourceTimeMonth";
    },
    // 获取视图的起始和结束时间
    datesSet(dateInfo) {
      this.ganttStartTime = dateInfo.startStr;
      this.ganttEndTime = dateInfo.endStr;
    },
    // 中国标准时间转化成日期2021-03-21 减八小时
    conversionDateMethod(dateData) {
      let date = new Date(dateData);
      let realDate = new Date(date.getTime());
      // let realDate = new Date(date.getTime() - 8 * 60 * 60 * 1000)
      let y = realDate.getFullYear();
      let m =
        realDate.getMonth() + 1 < 10
          ? "0" + (realDate.getMonth() + 1)
          : realDate.getMonth() + 1;
      let d =
        realDate.getDate() < 10 ? "0" + realDate.getDate() : realDate.getDate();
      let hh =
        realDate.getHours() < 10
          ? "0" + realDate.getHours()
          : realDate.getHours();
      let mm =
        realDate.getMinutes() < 10
          ? "0" + realDate.getMinutes()
          : realDate.getMinutes();
      let ss =
        realDate.getSeconds() < 10
          ? "0" + realDate.getSeconds()
          : realDate.getSeconds();
      // return y + '-' + m + '-' + d
      return y + "-" + m + "-" + d + " " + hh + ":" + mm + ":" + ss;
    },
    // -8小时
    getRealDate(dateData) {
      let date = new Date(dateData);
      let realDate = new Date(date.getTime() - 8 * 60 * 60 * 1000);
      let y = realDate.getFullYear();
      let m =
        realDate.getMonth() + 1 < 10
          ? "0" + (realDate.getMonth() + 1)
          : realDate.getMonth() + 1;
      let d =
        realDate.getDate() < 10 ? "0" + realDate.getDate() : realDate.getDate();
      // let hh = realDate.getHours() < 10 ? "0" + date.getHours() : realDate.getHours();
      // let mm = realDate.getMinutes()
      // let ss = realDate.getSeconds()
      return y + "-" + m + "-" + d;
    },
    switchDate(flag) {
      switch (flag) {
        case -1:
          this.calendarApi.prev();
          // 子组件日期传给父组件
          break;
        case 0:
          this.calendarApi.today();
          // 子组件日期传给父组件
          break;
        case 1:
          this.calendarApi.next();
          // 子组件日期传给父组件
          break;
      }
      this.ganttDateValue = this.getRealDate(
        this.calendarApi.currentData.currentDate
      );
      if (this.treeString != "") {
        this.getResource();
      }
      this.$emit("ganttDateValueMethod", this.ganttDateValue);
    },
    //获取缺勤记录
    async getResourceAbsence(id) {
      let ganttDateStart = this.conversionDateMethod(this.ganttStartTime);
      let ganttDateEnd = this.conversionDateMethod(this.ganttEndTime);
      let option = {
        id: id,
        startTime: ganttDateStart,
        endTime: ganttDateEnd,
      };
      let result = await getResourceAbsence(option);
      if (result.result == true) {
        result.data.forEach((res) => {
          let obj = {
            resourceId: id,
            id: res.id,
            title:
              res.type && res.type.length > 0
                ? res.type
                : this.$i18n.t("label.service.gantt.absenteeism"), // 缺勤 this.$i18n.t('label.service.gantt.absenteeism')
            start: res.starttime,
            end: res.endtime,
            color: "#888888",
          };
          this.calendarOptions.events.push(obj);
        });
      }
    },
    // 获取全部服务资源及服务预约
    async getResource() {
      this.isShowLoading = true;
      this.calendarOptions.resources = [];
      this.calendarOptions.events = [];
      let ganttDateStart = this.conversionDateMethod(this.ganttStartTime);
      let ganttDateEnd = this.conversionDateMethod(this.ganttEndTime);
      let res = await getServiceAppOnTerritory({
        territoryIds: this.treeString,
        startTime: ganttDateStart,
        endTime: ganttDateEnd,
        keyWord: this.searchPersonnel,
        onlyShowResource: this.onlyshowresourceData == "1" ? "1" : "0",
        isCapacityBased: this.iscapacitybasedData == "1" ? "1" : "0",
        isactive: this.isactiveData == "1" ? "1" : "0",
        resourceTypeId: this.checkListOne.toString(),
        skillId: this.checkListTwo.toString(),
        filterlogic: this.filterChooseLogic, //技能筛选器逻辑，AND或者OR
        usefilterlogic: "true", //技能筛选器逻辑是否启用
      });

      res.data &&
        res.data.forEach((item) => {
          let obj = {
            id: item.id,
            title: item.name,
            resourceList: item.resourceList || false,
            // businessHours: null,
          };
          if (item.resourceList) {
            obj.children = [
              {
                id: item.id + "tempChildren",
                title: "loading...",
              },
            ];
          }
          if (item.sappList?.length > 0) {
            item.sappList.forEach((appo) => {
              // let color = "#A0E0FD";
              let obj2 = {
                resourceId: item.id,
                title: appo.subject
                  ? appo.name + "(" + appo.subject + ")"
                  : appo.name,
                start: appo.schedstarttime,
                end: appo.schedendtime,
                color: appo.legend,
                id: appo.id,
                statustype: appo.statustype,
              };
              //服务预约获取
              this.calendarOptions.events.push(obj2);
            });
          }
          this.calendarOptions.resources.push(obj);
        });
      let sourceArr = this.calendarOptions.events;
      this.calendarOptions.events = this.deWeight(
        sourceArr,
        "id",
        "resourceId"
      );
      if (this.calendarOptions.resources.length == 0) {
        this.calendarOptions.height = "0";
      } else {
        this.calendarOptions.height = "100%";
      }
      let openList =
        JSON.parse(localStorage.getItem("dispatchOpendArea")) || [];
      openList.length > 0 &&
        openList.forEach((item) => {
          this.getResourceByArea(item);
        });
      this.isShowLoading = false;

      // 原先的方法
      // let option = {
      //   id: this.treeString,
      //   startTime: ganttDateStart,
      //   endTime: ganttDateEnd,
      //   keyWord: this.searchPersonnel,
      //   onlyShowResource: this.onlyshowresourceData == "1" ? "1" : "0",
      //   isCapacityBased: this.iscapacitybasedData == "1" ? "1" : "0",
      //   isactive: this.isactiveData == "1" ? "1" : "0",
      //   resourceTypeId: this.checkListOne.toString(),
      //   skillId: this.checkListTwo.toString(),
      //   page: this.currentPage,
      //   pageSize: this.pageNumber,
      //   filterlogic: this.filterChooseLogic, //技能筛选器逻辑，AND或者OR
      //   usefilterlogic: "true", //技能筛选器逻辑是否启用
      // };
      // let result = await getAllResource(option);
      // if (result.result == true) {
      //   this.isShowLoading = false;
      //   result.data.forEach((res) => {
      //     if (res.totalCount > this.totalCount) {
      //       this.totalCount = res.totalCount;
      //       this.maxPage = Math.ceil(this.totalCount / this.pageNumber);
      //     }
      //     res.data.forEach((ele) => {
      //       let timeArr = [];
      //       ele.timeList.forEach((timeItem) => {
      //         let weekName = "";
      //         // 此地禁止替换多语言
      //         switch (timeItem.dayofweek) {
      //           case "周一": // 周一 this.$i18n.t('label.appointment.wizard.subtitle3-1')
      //             weekName = 1;
      //             break;
      //           case "周二": // 周二 this.$i18n.t('label.appointment.wizard.subtitle3-2')
      //             weekName = 2;
      //             break;
      //           case "周三": // 周三 this.$i18n.t('label.appointment.wizard.subtitle3-3')
      //             weekName = 3;
      //             break;
      //           case "周四": // 周四 this.$i18n.t('label.appointment.wizard.subtitle3-4')
      //             weekName = 4;
      //             break;
      //           case "周五": // 周五 this.$i18n.t('label.appointment.wizard.subtitle3-5')
      //             weekName = 5;
      //             break;
      //           case "周六": // 周六 this.$i18n.t('label.appointment.wizard.subtitle3-6')
      //             weekName = 6;
      //             break;
      //           case "周日": // 周日 this.$i18n.t('label.appointment.wizard.subtitle3-7')
      //             weekName = 0;
      //             break;
      //         }
      //         let objTime = {
      //           daysOfWeek: [weekName], // 周一 - 周五
      //           startTime: timeItem.starttime, // 上午7点开始
      //           endTime: timeItem.endtime, // 下午8点结束
      //         };
      //         timeArr.push(objTime);
      //       });
      //       let obj1 = { id: ele.id, resourceid: ele.resourceid };
      //       this.getResourceAbsence(ele.resourceid);
      //       this.idFit.push(obj1);
      //       // 资源负载情况
      //       let doneNum = 0;
      //       let allNum = ele.appoList.length;
      //       let loadNum;
      //       ele.appoList.forEach((appo) => {
      //         //已完成,已取消,无法完成
      //         if (
      //           appo.status == this.$i18n.t("label.htyj.completed") ||
      //           appo.status ==
      //             this.$i18n.t("label.projectManagement.cancelled") ||
      //           appo.status ==
      //             this.$i18n.t("label.projectManagement.unable.to.complete")
      //         ) {
      //           doneNum++;
      //         }
      //         let findIndex = this.assidArr.findIndex(
      //           (res) => res == appo.assId
      //         );
      //         if (findIndex < 0) {
      //           let color = "";
      //           // 此地禁止替换多语言
      //           switch (appo.status) {
      //             case "无": // 无 this.$i18n.t('label.tabpage.nothingz')
      //               color = "#A5E2D6";
      //               break;
      //             case "已计划": // 已计划 this.$i18n.t('label.projectManagement.planned')
      //               color = "#A0E0FD";
      //               break;
      //             case "已派遣": // 已派遣 this.$i18n.t('label.projectManagement.dispatched')
      //               color = "#A5B8FF";
      //               break;
      //             case "进行中": // 进行中 this.$i18n.t('label.starting')
      //               color = "#FFE085";
      //               break;
      //             case "已完成": // 已完成 this.$i18n.t('label.htyj.completed')
      //               color = "#FFB485";
      //               break;
      //             case "无法完成": // 无法完成 this.$i18n.t('label.projectManagement.unable.to.complete')
      //               color = "#F7A7A7";
      //               break;
      //             case "已取消": // 已取消 this.$i18n.t('label.projectManagement.cancelled')
      //               color = "#DDDBDA";
      //               break;
      //           }
      //           let obj2 = {
      //             resourceId: ele.resourceid,
      //             title: appo.name,
      //             start: appo.schedstarttime,
      //             end: appo.schedendtime,
      //             color: color,
      //             id: appo.id,
      //             assid: appo.assId,
      //             popoverStatus: appo.status,
      //             popoverEarly: appo.earlieststarttime,
      //             popoverDuedate: appo.duedate,
      //             popoverInjeopardy: appo.injeopardy,
      //             popoverHours: appo.estimatedtraveltime,
      //             popoverDistance: appo.estimatedtraveldistance,
      //             skillList: appo.skillList,
      //             productList: appo.productList,
      //           };
      //           //服务预约获取
      //           this.calendarOptions.events.push(obj2);
      //         }
      //       });
      //       loadNum = " " + "(" + doneNum + "/" + allNum + ")";
      //       let obj = {
      //         id: ele.resourceid,
      //         title: ele.resourcename + loadNum,
      //         building: ele.name,
      //         businessHours: timeArr,
      //       };
      //       this.calendarOptions.resources = [
      //         {
      //           id: "a",
      //           title: "Auditorium A",
      //           children: [],
      //         },
      //         {
      //           id: "b",
      //           title: "Auditorium B",
      //           eventColor: "green",
      //         },
      //         {
      //           id: "c",
      //           title: "Auditorium C",
      //           eventColor: "orange",
      //         },
      //         {
      //           id: "d",
      //           title: "Auditorium D",
      //           children: [
      //             {
      //               id: "d1",
      //               title: "Room D1",
      //             },
      //             {
      //               id: "d2",
      //               title: "Room D2",
      //             },
      //           ],
      //         },
      //       ];
      //     });
      //   });
      //   //计算预约KPI的持续时间，违规数量，危险数量，服务预约数量,已完成服务数量,出行时间
      //   let KPITime = {
      //     sumTime: 0,
      //     violationListSum: 0,
      //     injeopardySum: 0,
      //     orderSum: 0,
      //     completeSum: 0,
      //     estimatedtraveltime: 0,
      //   };
      //   result.data.forEach((res) => {
      //     if (res.totalCount > 0) {
      //       res.data.forEach((item) => {
      //         if (item.appoList) {
      //           //服务预约数量
      //           KPITime.orderSum += item.appoList.length;
      //           item.appoList.forEach((appitem) => {
      //             //计算当前时间范围内持续时间
      //             KPITime.sumTime += this.getHour(
      //               appitem.schedendtime,
      //               appitem.schedstarttime
      //             );
      //             // 累加平均出行时间
      //             if (appitem.estimatedtraveltime) {
      //               KPITime.estimatedtraveltime += Number(
      //                 appitem.estimatedtraveltime
      //               );
      //             }
      //             //违规
      //             if (appitem.violationList?.length > 0) {
      //               KPITime.violationListSum += 1;
      //             }
      //             //危险数量
      //             if (appitem.injeopardy == "true") {
      //               KPITime.injeopardySum += 1;
      //             }
      //             //已完成服务预约数量已取消，无法完成，已完成
      //             if (
      //               appitem.status == this.$i18n.t("label.htyj.completed") ||
      //               appitem.status ==
      //                 this.$i18n.t("label.projectManagement.cancelled") ||
      //               appitem.status ==
      //                 this.$i18n.t("label.projectManagement.unable.to.complete")
      //             ) {
      //               KPITime.completeSum += 1;
      //             }
      //           });
      //         }
      //       });
      //     }
      //   });
      //   this.$emit("getKPITime", KPITime);
      // }
      // let tempArr = this.calendarOptions.events;
      // this.calendarOptions.events = this.deWeight(tempArr, "id", "resourceId");
      // if (this.calendarOptions.resources.length == 0) {
      //   this.calendarOptions.height = "0";
      // } else {
      //   this.calendarOptions.height = "100%";
      // }
    },
    // 去重
    deWeight(arr, ...arguements) {
      function getBoolean(o, m) {
        let list = arguements.map((x) => o[x] == m[x]); //值均为布尔
        return list.every((i) => i); //要使这些布尔值都真才能满足条件，因为要求的条件是 并且
      }
      let result = []; //新数组
      //总数组与新数组比较，遍历总数组时用新数组的some方法进行判断
      arr.map((o) =>
        !result.some((m) => getBoolean(o, m)) ? result.push(o) : ""
      );
      return result;
    },
    //计算时间相差小时数
    getHour(s1, s2) {
      s1 = new Date(s1.replace(/-/g, "/"));
      s2 = new Date(s2.replace(/-/g, "/"));
      var ms = Math.abs(s1.getTime() - s2.getTime());
      return ms / 1000 / 60 / 60;
    },
    // 甘特筛选器资源中的多选
    handleCheckedChange() {},
    //鼠标移chu事件
    eventLeave() {},
    //鼠标移入事件
    eventMouse(info) {
      this.productStr = "";
      this.skillStr = "";
      let option = {
        resourceid: info.event._def.resourceIds[0],
        appoid: info.event.extendedProps.fatterId || info.event.id,
        territoryid: info.event.extendedProps.areaId,
        startTime: this.conversionDateMethod(info.event.start),
        endTime: this.conversionDateMethod(info.event.end),
      };
      // getSkills({
      //   id: info.event.id
      // }).then(res=>{
      //   if (res.result) {
      //     res.data.productList.length > 0 && res.data.productList.map(item=>{
      //       this.productStr += item.name + ', '
      //     })
      //     res.data.skillList.length > 0 && res.data.skillList.map(item=>{
      //       this.skillStr += item.name + ', '
      //     })
      //   }
      // })
      info.event.extendedProps.productList &&
        info.event.extendedProps.productList.length > 0 &&
        info.event.extendedProps.productList.map((item, index) => {
          if (index < 4) {
            this.productStr += item.name + ", ";
          } else if (index === 4) {
            this.productStr += item.name + "...";
          }
        });
      info.event.extendedProps.skillList &&
        info.event.extendedProps.skillList.length > 0 &&
        info.event.extendedProps.skillList.map((item, index) => {
          if (index < 4) {
            this.skillStr += item.name + ", ";
          } else if (index === 4) {
            this.skillStr += item.name + "...";
          }
        });
      this.popoverEventData = {
        title: info.event.title,
        id: info.event.extendedProps.fatterId || info.event.id,
        assid: info.event.extendedProps.assid,
        popoverStatus: info.event.extendedProps.popoverStatus,
        popoverEarly: info.event.extendedProps.popoverEarly,
        popoverDuedate: info.event.extendedProps.popoverDuedate,
        popoverInjeopardy: info.event.extendedProps.popoverInjeopardy,
        popoverHours: info.event.extendedProps.popoverHours,
        popoverDistance: info.event.extendedProps.popoverDistance,
        start: this.conversionDateMethod(info.event.start),
        end: this.conversionDateMethod(info.event.end),
      };
      let eventImg = document.getElementById("eventNone");
      const template = document.getElementById("service-popover");
      let _this = this;
      // 事件悬浮窗设置
      this.$nextTick(() => {
        this.instance = tippy(info.el, {
          content:
            "<div style=' width:100%; height:100%;'>" +
            template.innerHTML +
            "</div>" +
            "<div style=' width:100%; height:100%;'>" +
            eventImg.innerHTML +
            "</div>",
          theme: "light-border", //悬浮框主题
          // trigger: 'click',
          // delay: 500,
          animation: "scale", //悬浮框展示动画
          // interactive: true,
          followCursor: "initial",
          plugins: [followCursor],
          placement: "bottom", //悬浮框位置
          allowHTML: true, //该content道具可以接受字符串，元素或函数(就是内容可拼接)
          onCreate(instance) {
            _this.currentViolation = [];
            if (info.event.extendedProps.areaId) {
              getAppoViolationList(option).then((res) => {
                _this.currentViolation = res.data.violationList || [];
                if (_this.currentViolation.length != 0) {
                  eventImg = document.getElementById("eventImg");
                } else {
                  eventImg = document.getElementById("eventNone");
                }
                let contentNew =
                  "<div style=' width:100%; height:100%;'>" +
                  template.innerHTML +
                  "</div>" +
                  "<div style=' width:100%; height:100%;'>" +
                  eventImg.innerHTML +
                  "</div>";
                _this.currentViolation.forEach((item) => {
                  let str = `<div
                  style="
                    font-size: 12px;
                    color: #1d2033;
                    line-height: 16px;
                    margin-bottom: 5px;
                  "
                >
                  ${item}
                </div>`;
                  contentNew += str;
                });
                instance.setContent(contentNew);
              });
            }
          },
          onHidden(instance) {
            instance.setContent("Loading...");
            _this.currentViolation = [];
          },
        });
      });
      if (this.instance.id) {
        this.instance.destroy();
      }
    },
    handleEventClick() {},
    //添加或更新对象记录
    // async save(objectApi, data, violationOption, backgroundColor) {
    async save(objectApi, data) {
      let option = {
        objectApi: objectApi,
        data: data,
      };
      let result = await save(option);
      if (result.result == true) {
        this.$Bus.$emit("update-active-appo", result.data[0].id);
        // if (backgroundColor != "#888888") {
        //   this.getAppoViolationList(violationOption);
        // }
        this.$message.success(this.$i18n.t("vue_label_notice_modify_success")); //修改成功  this.$i18n.t('vue_label_notice_modify_success')
      }
    },
    eventResize(a) {
      let durationTime = this.getDateDiff(a.event.start, a.event.end);
      let violationOption = {
        resourceid: a.event._def.resourceIds[0],
        appoid: a.event.id,
        territoryid: this.treeString,
        startTime: this.conversionDateMethod(a.event.start),
        endTime: this.conversionDateMethod(a.event.end),
      };
      let objApi = "";
      let option = {};
      if (a.event.id.substring(0, 3) === "s37") {
        objApi = "ResourceAbsence";
        option = {
          id: a.event.id,
          starttime: this.conversionDateMethod(a.event.start),
          endtime: this.conversionDateMethod(a.event.end),
        };
      } else if (a.event.id.substring(0, 3) === "s38") {
        objApi = "AssignedResource";
        option = {
          id: a.event.id,
          schedstarttime: this.conversionDateMethod(a.event.start),
          schedendtime: this.conversionDateMethod(a.event.end),
          duration: durationTime,
        };
      } else if (a.event.id.substring(0, 3) === "s41") {
        objApi = "ServiceAppointment";
        option = {
          id: a.event.id,
          schedstarttime: this.conversionDateMethod(a.event.start),
          schedendtime: this.conversionDateMethod(a.event.end),
          duration: durationTime,
        };
      }
      this.save(
        objApi,
        JSON.stringify([option]),
        violationOption,
        a.event.backgroundColor
      );
    },
    //获取服务预约违规信息
    async getAppoViolationList(option) {
      let result = await getAppoViolationList(option);
      if (result.result == true) {
        this.violationList.forEach((res) => {
          if (res.assId == result.data.Assid) {
            // this.$set(this.violationList[index],'violationList',result.data.violationList)
            res.violationList = result.data.violationList;
          }
        });
        this.instance.destroy();
      }
    },
    /**
     * 外部拖拽调用
     * @param a
     * @desc 暂时用不到
     */
    async drop() {
      //是否有新建权限
      // if (this.appoJurisdiction.add) {
      // let id = "";
      // this.idFit.forEach((res) => {
      //   if (res.resourceid == a.resource._resource.id) {
      //     id = res.id;
      //   }
      // });
      // let option = {
      //   resourceid: a.resource._resource.id,
      //   appoid: this.ganttData.id,
      //   territoryid: id,
      //   startTime: this.conversionDateMethod(a.date),
      //   endTime: this.conversionDateMethod(
      //     new Date(a.date).setHours(
      //       new Date(a.date).getHours() + Number(this.ganttData.durationIns)
      //     )
      //   ),
      //   duration: this.ganttData.durationIns.toString(),
      // };
      // let result = await saveServiceAppo(option);
      // if (result.result == true) {
      //   this.assidArr.push(result.data.Assid);
      //   this.$Bus.$emit("get-serviceList-again", true);
      //   this.$message.success(
      //     this.$i18n.t("label.service.gantt.distribution.success")
      //   );
      // }
      // } else {
      //   return;
      // }
    },
    /**
     * 点击侧边事件
     */
    handleAsideClick(info) {
      // 区分服务区域和服务资源
      let clickId = info.resource.id;
      if (clickId.substring(0, 3) === "s22") {
        let openList =
          JSON.parse(localStorage.getItem("dispatchOpendArea")) || [];
        let curArea = this.calendarOptions.resources.find((item) => {
          return item.id === clickId;
        });
        // 展开收起服务区域
        if (curArea.requested) {
          // 无需再次请求接口
        } else {
          // 第一次请求接口 赋值
          // 请求服务区域下的服务资源
          this.calendarOptions.resources.forEach((item) => {
            if (item.id === clickId && item.resourceList) {
              item.requested = true;
              this.getResourceByArea(clickId);
              openList.push(item.id);
              localStorage.setItem(
                "dispatchOpendArea",
                JSON.stringify(openList)
              );
            }
          });
        }
      } else {
        if (this.$store.state.navigationStyle) {
          this.$CCDK.CCPage.openDetailPage(
            // eslint-disable-next-line no-unexpected-multiline
            {
              oprateType: "DETAIL",
              objectName: info.resource.title,
              objId: "account",
            },
            info.resource.id,
            {
              openPlace: "menu2",
              openMode: "_blank",
              pageId: info.resource.id,
            }
          );
        } else {
          if (this.$store.state.navigationStyle) {
            this.$CCDK.CCPage.openDetailPage(
              {
                oprateType: "DETAIL",
                objectName: info.resource.title,
                objId: info.resource.id,
              },
              info.resource.id,
              {
                openPlace: "menu2",
                openMode: "_blank",
                pageId: info.resource.id,
              }
            );
          } else {
            this.$router.push({
              path: `/commonObjects/detail/${info.resource.id}/DETAIL`,
            });
          }
        }
      }
    },
    /**
     * 根据服务区域获取服务资源
     */
    getResourceByArea(id) {
      let openIndex = this.calendarOptions.resources.findIndex((item) => {
        return item.id === id;
      });
      let tempArr = [];
      let option = {
        id: id,
        startTime: this.conversionDateMethod(this.ganttStartTime),
        endTime: this.conversionDateMethod(this.ganttEndTime),
        keyWord: this.searchPersonnel,
        onlyShowResource: this.onlyshowresourceData == "1" ? "1" : "0",
        isCapacityBased: this.iscapacitybasedData == "1" ? "1" : "0",
        isactive: this.isactiveData == "1" ? "1" : "0",
        resourceTypeId: this.checkListOne.toString(),
        skillId: this.checkListTwo.toString(),
        filterlogic: this.filterChooseLogic, //技能筛选器逻辑，AND或者OR
        usefilterlogic: "true", //技能筛选器逻辑是否启用
      };
      getAllResource(option).then((result) => {
        if (result.result == true) {
          result.data.forEach((res) => {
            res.data.forEach((ele) => {
              let timeArr = [];
              ele.timeList.forEach((timeItem) => {
                let weekName = "";
                // 此地禁止替换多语言
                switch (timeItem.dayofweek) {
                  case "周一": // 周一 this.$i18n.t('label.appointment.wizard.subtitle3-1')
                    weekName = 1;
                    break;
                  case "周二": // 周二 this.$i18n.t('label.appointment.wizard.subtitle3-2')
                    weekName = 2;
                    break;
                  case "周三": // 周三 this.$i18n.t('label.appointment.wizard.subtitle3-3')
                    weekName = 3;
                    break;
                  case "周四": // 周四 this.$i18n.t('label.appointment.wizard.subtitle3-4')
                    weekName = 4;
                    break;
                  case "周五": // 周五 this.$i18n.t('label.appointment.wizard.subtitle3-5')
                    weekName = 5;
                    break;
                  case "周六": // 周六 this.$i18n.t('label.appointment.wizard.subtitle3-6')
                    weekName = 6;
                    break;
                  case "周日": // 周日 this.$i18n.t('label.appointment.wizard.subtitle3-7')
                    weekName = 0;
                    break;
                }
                let objTime = {
                  daysOfWeek: [weekName], // 周一 - 周五
                  startTime: timeItem.starttime, // 上午7点开始
                  endTime: timeItem.endtime, // 下午8点结束
                };
                timeArr.push(objTime);
              });
              let obj1 = { id: ele.id, resourceid: ele.resourceid };
              this.getResourceAbsence(ele.resourceid);
              this.idFit.push(obj1);
              // 资源负载情况
              let doneNum = 0;
              let allNum = ele.appoList.length;
              let loadNum;
              ele.appoList.forEach((appo) => {
                //已完成,已取消,无法完成
                if (
                  appo.status == this.$i18n.t("label.htyj.completed") ||
                  appo.status ==
                    this.$i18n.t("label.projectManagement.cancelled") ||
                  appo.status ==
                    this.$i18n.t("label.projectManagement.unable.to.complete")
                ) {
                  doneNum++;
                }
                let findIndex = this.assidArr.findIndex(
                  (res) => res == appo.assid
                );
                if (findIndex < 0) {
                  let color = "";
                  // 此地禁止替换多语言
                  switch (appo.status) {
                    case "无": // 无 this.$i18n.t('label.tabpage.nothingz')
                      color = "#A5E2D6";
                      break;
                    case "已计划": // 已计划 this.$i18n.t('label.projectManagement.planned')
                      // color = "#A0E0FD";
                      color = "#4CB1EA";
                      break;
                    case "已分配":
                      color = "#de5060";
                      break;
                    case "已超时":
                      color = "#e77d7d";
                      break;
                    case "已接单":
                      color = "#81d194";
                      break;
                    case "已拒单":
                      color = "#e77d7d";
                      break;
                    case "已转单":
                      color = "#73cece";
                      break;
                    case "已逾期":
                      color = "#e77d7d";
                      break;
                    case "已派遣": // 已派遣 this.$i18n.t('label.projectManagement.dispatched')
                      // color = "#A5B8FF";
                      color = "#9386de";
                      break;
                    case "进行中": // 进行中 this.$i18n.t('label.starting')
                      // color = "#FFE085";
                      color = "#f4bc7d";
                      break;
                    case "已完成": // 已完成 this.$i18n.t('label.htyj.completed')
                      // color = "#FFB485";
                      color = "#6286eb";
                      break;
                    case "无法完成": // 无法完成 this.$i18n.t('label.projectManagement.unable.to.complete')
                      // color = "#F7A7A7";
                      color = "#e77d7d";
                      break;
                    // case "已取消": // 已取消 this.$i18n.t('label.projectManagement.cancelled')
                    //   color = "#DDDBDA";
                    //   break;
                  }
                  let obj2 = {
                    resourceId: ele.resourceid,
                    title: appo.subject
                      ? appo.assname + "(" + appo.subject + ")"
                      : appo.assname,
                    start: appo.agschedstarttime || appo.schedstarttime,
                    end: appo.agschedendtime || appo.schedendtime,
                    color: color,
                    fatterId: appo.id,
                    areaId: ele.id,
                    id: appo.assid,
                    assid: appo.assid,
                    popoverStatus: appo.status,
                    popoverEarly: appo.earlieststarttime,
                    popoverDuedate: appo.duedate,
                    popoverInjeopardy: appo.injeopardy,
                    popoverHours: appo.estimatedtraveltime,
                    popoverDistance: appo.estimatedtraveldistance,
                    skillList: appo.skillList,
                    productList: appo.productList,
                  };
                  //服务预约获取
                  this.calendarOptions.events.push(obj2);
                }
              });
              let sourceArr = this.calendarOptions.events;
              this.calendarOptions.events = this.deWeight(
                sourceArr,
                "id",
                "resourceId"
              );
              loadNum = " " + "(" + doneNum + "/" + allNum + ")";
              let obj = {
                id: ele.resourceid,
                title: ele.resourcename + loadNum,
                // building: ele.name,
                businessHours: timeArr,
              };
              tempArr.push(obj);
            });
            this.calendarOptions.resources[openIndex].children = tempArr;
          });
        }
      });
    },
    //视图接受外部事件拖拽
    eventReceive(e) {
      let toId = e.event._def.resourceIds[0];
      if (this.appoJurisdiction.add) {
        if (toId.substring(0, 3) === "s22") {
          e.revert();
          this.isShowLoading = true;
          let op = {
            serviceAppointmentId: this.ganttData.id,
            serviceTerritoryId: toId,
            isDispatch: "1",
            startTime: this.conversionDateMethod(e.event.start),
          };
          serviceAreaAssignment(op)
            .then((res) => {
              if (res.result) {
                if (res.returnCode == 300) {
                  this.isShowLoading = false;
                  this.$message.warning(res.data);
                } else {
                  this.getResource();
                  this.$Bus.$emit("get-serviceList-again", true);
                  this.$message.success(
                    this.$i18n.t("label.service.gantt.distribution.success")
                  );
                }
              } else {
                this.isShowLoading = false;
              }
            })
            .catch(() => {
              this.isShowLoading = false;
            });
        } else if (toId.substring(0, 3) === "s34") {
          // 计划
          e.revert();
          this.isShowLoading = true;
          let id = "";
          this.idFit.forEach((res) => {
            if (res.resourceid == toId) {
              id = res.id;
            }
          });
          let option = {
            resourceid: toId,
            appoid: this.ganttData.id,
            territoryid: id,
            startTime: this.conversionDateMethod(e.event.start),
            endTime: this.conversionDateMethod(
              new Date(e.event.start).setHours(
                new Date(e.event.start).getHours() +
                  Number(this.ganttData.durationIns)
              )
            ),
            duration: this.ganttData.durationIns.toString(),
          };
          saveServiceAppo(option).then((res) => {
            if (res.result == true) {
              if (res.returnCode == 300) {
                this.isShowLoading = false;
                this.$message.warning(res.data);
              } else {
                this.assidArr.push(res.data.Assid);
                this.$Bus.$emit("get-serviceList-again", true);
                this.getResource();
                this.$message.success(
                  this.$i18n.t("label.service.gantt.distribution.success")
                );
              }
            }
          });
        }
      } else {
        this.$message({
          message: this.$i18n.t("label.service.gantt.drop.tip"),
          type: "warning",
        });
        e.revert();
      }
    },
    /**
     * 事件拖拽
     */
    eventDrop(a) {
      if (a.oldResource === null && a.newResource === null) {
        // 在同一个服务区域、资源上拖拽
        if (a.event._def.resourceIds[0].substring(0, 3) === "s22") {
          // 服务预约的拖拽
          if (a.event._def.extendedProps.statustype === "inprogress") {
            this.eventDropFun(a);
          } else {
            a.revert();
          }
        } else if (a.event._def.resourceIds[0].substring(0, 3) === "s34") {
          getPermissionById({
            id: a.event.id,
          }).then((res) => {
            if (res.result && res.data.isEdit) {
              // 缺勤资源
              if (a.event.backgroundColor == "#888888") {
                let obj = [
                  {
                    id: a.event.id,
                    starttime: this.conversionDateMethod(a.event.start),
                    endtime: this.conversionDateMethod(a.event.end),
                  },
                ];
                let option = {
                  objectApi: "ResourceAbsence",
                  data: JSON.stringify(obj),
                };
                save(option).then((res) => {
                  if (res.result) {
                    this.$message.success(
                      this.$i18n.t("label.projectManagement.update.succeeded")
                    );
                  }
                });
              } else {
                // 已分配资源的拖拽
                let durationTime = this.getDateDiff(a.event.start, a.event.end);
                let obj = [
                  {
                    id: a.event.id,
                    agschedendtime: this.conversionDateMethod(a.event.start),
                    schedendtime: this.conversionDateMethod(a.event.end),
                    duration: durationTime,
                  },
                ];
                let option = {
                  objectApi: "AssignedResource",
                  data: JSON.stringify(obj),
                };
                save(option).then((res) => {
                  if (res.result) {
                    this.$message.success(
                      this.$i18n.t("label.projectManagement.update.succeeded")
                    );
                  }
                });
              }
            } else {
              this.$message({
                message: this.$i18n.t("label.service.gantt.drop.tip"),
                type: "warning",
              });
              a.revert();
            }
          });
        }
      } else if (a.oldResource.id.substring(0, 3) === "s22") {
        // 从服务区域拖拽
        if (a.newResource.id.substring(0, 3) === "s22") {
          if (a.event._def.extendedProps.statustype !== "inprogress") {
            a.revert();
            return;
          }
          // 重新分配
          let op = {
            serviceAppointmentId: a.event.id,
            serviceTerritoryId: a.newResource.id,
            isDispatch: "1",
            startTime: this.conversionDateMethod(a.event.start),
          };
          serviceAreaAssignment(op).then((res) => {
            if (res.result) {
              this.$message.success(
                this.$i18n.t("label.service.gantt.distribution.success")
              );
            }
          });
        } else if (a.newResource.id.substring(0, 3) === "s34") {
          a.revert();
          // 计划
          this.isShowLoading = true;
          let id = "";
          this.idFit.forEach((res) => {
            if (res.resourceid == a.newResource.id) {
              id = res.id;
            }
          });
          let option = {
            resourceid: a.newResource.id,
            appoid: a.event.id,
            territoryid: id,
            startTime: this.conversionDateMethod(a.event.start),
            endTime: this.conversionDateMethod(a.event.end),
          };
          saveServiceAppo(option).then((res) => {
            if (res.result == true) {
              if (res.returnCode == 300) {
                this.isShowLoading = false;
                this.$message.warning(res.data);
              } else {
                this.assidArr.push(res.data.Assid);
                this.$Bus.$emit("get-serviceList-again", true);
                this.getResource();
                this.$message.success(
                  this.$i18n.t("label.service.gantt.distribution.success")
                );
              }
            }
          });
        }
      } else if (a.oldResource.id.substring(0, 3) === "s34") {
        // 从服务资源拖拽
        if (a.newResource.id.substring(0, 3) === "s22") {
          a.revert();
          this.$message.warning("不支持此操作");
        } else if (a.newResource.id.substring(0, 3) === "s34") {
          // 重新计划
          // 缺勤
          getPermissionById({
            id: a.event.id,
          }).then((res) => {
            if (res.result && res.data.isEdit) {
              // 缺勤资源
              if (a.event.backgroundColor == "#888888") {
                let obj = [
                  {
                    id: a.event.id,
                    starttime: this.conversionDateMethod(a.event.start),
                    endtime: this.conversionDateMethod(a.event.end),
                    serviceresourceid: a.newResource.id,
                  },
                ];
                let option = {
                  objectApi: "ResourceAbsence",
                  data: JSON.stringify(obj),
                };
                save(option).then((res) => {
                  if (res.result) {
                    this.$message.success(
                      this.$i18n.t("label.projectManagement.update.succeeded")
                    );
                  }
                });
              } else {
                // 已分配资源的拖拽
                let option = {
                  assignedresourceId: a.event.id,
                  serviceappointmentId: a.event._def.extendedProps.fatterId,
                  resourceId: a.newResource.id,
                  startTime: this.conversionDateMethod(a.event.start),
                  endTime: this.conversionDateMethod(a.event.end),
                };
                dragPlan(option).then((res) => {
                  if (res.result) {
                    if (res.returnCode == 300) {
                      a.revert();
                      this.$message.warning(res.data);
                    } else {
                      this.$message.success(
                        this.$i18n.t("label.projectManagement.update.succeeded")
                      );
                    }
                  } else {
                    a.revert();
                  }
                });
              }
            } else {
              this.$message({
                message: this.$i18n.t("label.service.gantt.drop.tip"),
                type: "warning",
              });
              a.revert();
            }
          });
        }
      }
    },
    // 获取时间差
    getDateDiff(startTime, endTime) {
      let sTime = new Date(startTime);
      let eTime = new Date(endTime);
      let divNum = 1000 * 3600;
      return ((eTime.getTime() - sTime.getTime()) / parseInt(divNum)).toFixed(
        2
      );
    },
    eventDropFun(a) {
      let violationOption = {
        resourceid: a.event._def.resourceIds[0],
        appoid: a.event.id,
        territoryid: this.treeString,
        startTime: this.conversionDateMethod(a.event.start),
        endTime: this.conversionDateMethod(a.event.end),
      };
      let objApi =
        a.event.backgroundColor == "#888888"
          ? "ResourceAbsence"
          : "ServiceAppointment";
      if (a.newResource == null) {
        let option = {
          id: a.event.id,
          schedstarttime: this.conversionDateMethod(a.event.start),
          schedendtime: this.conversionDateMethod(a.event.end),
        };
        this.save(
          objApi,
          JSON.stringify([option]),
          violationOption,
          a.event.backgroundColor
        );
      } else {
        let option = {
          id: a.event.id,
          schedstarttime: this.conversionDateMethod(a.event.start),
          schedendtime: this.conversionDateMethod(a.event.end),
        };
        this.save(
          objApi,
          JSON.stringify([option]),
          violationOption,
          a.event.backgroundColor
        );
        if (a.event._def.extendedProps.assid) {
          let option1 = {
            id: a.event._def.extendedProps.assid,
            serviceresourceid: a.event._def.resourceIds[0],
          };
          this.save(
            "AssignedResource",
            JSON.stringify([option1]),
            violationOption,
            a.event.backgroundColor
          );
        } else {
          this.violationList.forEach((res) => {
            if (res.id == a.event.id && res.isDrag == true) {
              let option1 = {
                id: res.assId,
                serviceresourceid: a.event._def.resourceIds[0],
              };
              this.save(
                "AssignedResource",
                JSON.stringify([option1]),
                violationOption,
                a.event.backgroundColor
              );
            }
          });
        }
      }
    },
    // 甘特表日历选中日期传个父组件
    ganttDateValueMethods() {
      // 同步甘特表中的日期
      let realDate = new Date(this.ganttDateValue);
      let y = realDate.getFullYear();
      let m =
        realDate.getMonth() + 1 < 10
          ? "0" + (realDate.getMonth() + 1)
          : realDate.getMonth() + 1;
      let d =
        realDate.getDate() < 10 ? "0" + realDate.getDate() : realDate.getDate();
      let tempStr = y + "-" + m + "-" + d;
      this.calendarApi.gotoDate(tempStr);
      // 子组件日期传给父组件
      this.$emit("ganttDateValueMethod", this.ganttDateValue);
      if (this.treeString != "") {
        this.getResource();
      }
    },
    // 展示甘特图筛选器下拉内容
    // ganttFilterMenu() {
    //   this.getFilter();
    // },
    handleClick() {},

    // 甘特图筛选器技能全选和取消全选
    skillsCheckAll() {
      this.checkListTwo = this.skillsCheckAllArray;
    },
    cancleSkillsCheckAll() {
      this.checkListTwo = [];
    },
    // 点击筛选_调用获取资源技能筛选器接口
    async clickFilter() {
      let params = {};
      this.checkList = [];
      this.checkListOne = [];
      this.checkListTwo = [];
      this.skillsCheckAllArray = [];
      let res = await getFilter(params);
      if (res.data.resourceType || res.data.skillList) {
        this.getfilterData = res.data;
        this.getfilterreSourcesData = res.data.resourceType;
        this.getfilterresourceList = res.data.resourceList;
        //获取接口参数，首次获取皆为空，使用默认参数，之后采用接口参数赋值
        //日视图上显示的时间刻度
        if (res.data.daytimescale) {
          this.chooseDayTime = res.data.daytimescale;
        }
        //周视图上显示的时间刻度
        if (res.data.weektimescale) {
          this.chooseWeekTime = res.data.weektimescale;
        }
        //技能筛选器逻辑是否启用；true或者false
        if (res.data.usefilterlogic) {
          this.useScreening = eval(res.data.usefilterlogic);
        }
        // 默认筛选条件或
        if (res.data.filterlogic) {
          this.filterChooseLogic = res.data.filterlogic;
        }
        this.checkedWeekendHight = eval(res.data.weekendhighlight); //高亮显示周末，true或者false
        if (res.data.weekendshow == "true" || res.data.weekendshow == "false") {
          this.checkedWeekend = eval(res.data.weekendshow); //显示周末，true或者false
          this.calendarOptions.weekends = this.checkedWeekend;
        }
        this.reRoles = {};
        // 日，周，月
        if (this.currentDayOrWeek == "resourceTimeDay") {
          this.DayTimeScale();
        } else if (this.currentDayOrWeek == "resourceTimeWeek") {
          this.WeekTimeScale();
        } else if (this.currentDayOrWeek == "resourceTimeMonth") {
          this.monthTimeScale();
        }
        this.getfilterreSourcesData.map((item) => {
          if (item.isSelect == "true") {
            this.checkListOne.push(item.id);
            this.isShowRedSpot = true;
          }
          this.reRoles[item.id] = item.codevalue;
        });
        this.getfilterresourceList.map((item) => {
          if (item.iscapacitybased == "1") {
            this.iscapacitybasedData = "1";
            this.isShowRedSpot = true;
            this.checkList.push(
              this.$i18n.t("label.service.gantt.work.based.resources")
            ); //基于工时的资源
          }
          if (item.onlyshowresource == "1") {
            this.isShowRedSpot = true;
            this.onlyshowresourceData = "1";
            this.checkList.push(
              this.$i18n.t("label.service.gantt.resources.assigned.work")
            ); //被分配工作的资源
          }
          if (item.isactive == "1" || item.isactive === null) {
            this.isShowRedSpot = true;
            this.isactiveData = "1";
            this.checkList.push(
              this.$i18n.t("label.service.gantt.enabled.resources")
            ); //已启用的资源
          }
        });
        this.searchGetfilterSkillsData = res.data.skillList;
        this.getfilterreSkillsData.map((item) => {
          if (item.isSelect == "true") {
            this.checkListTwo.push(item.id);
            this.isShowRedSpot = true;
          }
          // 将筛选器的所有技能ID 保存到skillsCheckAllArray 数组里面
          this.skillsCheckAllArray.push(item.id);
        });
      }
    },
    // 调用获取资源技能筛选器接口
    async getFilter() {
      let params = {};
      this.checkList = [];
      this.checkListOne = [];
      this.checkListTwo = [];
      this.skillsCheckAllArray = [];
      let res = await getFilter(params);
      if (res.data.resourceType || res.data.skillList) {
        this.getfilterData = res.data;
        this.getfilterreSourcesData = res.data.resourceType;
        this.getfilterresourceList = res.data.resourceList;
        //获取接口参数，首次获取皆为空，使用默认参数，之后采用接口参数赋值
        //日视图上显示的时间刻度
        if (res.data.daytimescale) {
          this.chooseDayTime = res.data.daytimescale;
        }
        //周视图上显示的时间刻度
        if (res.data.weektimescale) {
          this.chooseWeekTime = res.data.weektimescale;
        }
        //技能筛选器逻辑是否启用；true或者false
        if (res.data.usefilterlogic) {
          this.useScreening = eval(res.data.usefilterlogic);
        }
        // 默认筛选条件或
        if (res.data.filterlogic) {
          this.filterChooseLogic = res.data.filterlogic;
        }
        this.checkedWeekendHight = eval(res.data.weekendhighlight); //高亮显示周末，true或者false
        if (res.data.weekendshow == "true" || res.data.weekendshow == "false") {
          this.checkedWeekend = eval(res.data.weekendshow); //显示周末，true或者false
          this.calendarOptions.weekends = this.checkedWeekend;
        }
        this.reRoles = {};
        // 日，周，月
        if (this.currentDayOrWeek == "resourceTimeDay") {
          this.DayTimeScale();
        } else if (this.currentDayOrWeek == "resourceTimeWeek") {
          this.WeekTimeScale();
        } else if (this.currentDayOrWeek == "resourceTimeMonth") {
          this.monthTimeScale();
        }
        this.getfilterreSourcesData.map((item) => {
          if (item.isSelect == "true") {
            this.checkListOne.push(item.id);
            this.isShowRedSpot = true;
          }
          this.reRoles[item.id] = item.codevalue;
        });
        this.getfilterresourceList.map((item) => {
          if (item.iscapacitybased == "1") {
            this.iscapacitybasedData = "1";
            this.isShowRedSpot = true;
            this.checkList.push(
              this.$i18n.t("label.service.gantt.work.based.resources")
            ); //基于工时的资源
          }
          if (item.onlyshowresource == "1") {
            this.isShowRedSpot = true;
            this.onlyshowresourceData = "1";
            this.checkList.push(
              this.$i18n.t("label.service.gantt.resources.assigned.work")
            ); //被分配工作的资源
          }
          if (item.isactive == "1" || item.isactive === null) {
            this.isShowRedSpot = true;
            this.isactiveData = "1";
            this.checkList.push(
              this.$i18n.t("label.service.gantt.enabled.resources")
            ); //已启用的资源
          }
        });
        this.searchGetfilterSkillsData = res.data.skillList;
        this.getfilterreSkillsData.map((item) => {
          if (item.isSelect == "true") {
            this.checkListTwo.push(item.id);
            this.isShowRedSpot = true;
          }
          // 将筛选器的所有技能ID 保存到skillsCheckAllArray 数组里面
          this.skillsCheckAllArray.push(item.id);
        });
        this.getResource();
      } else {
        // 用户首次使用派工平台接口返回data={}
        this.getResource();
      }
    },
    //获取资源技能筛选器列表数据
    // getSelectValue(){
    //   let params = {
    //     fieldId:"ffeserviceresource23"
    //   };
    //   getSelectValue(params).then((res)=>{

    //   }).catch((err)=>{

    //   })
    // },
    forHour(hour) {
      let s = parseInt(hour * 60);
      const h = Math.floor(s / 60);
      const m =
        Math.floor(s % 60) < 10 ? "0" + Math.floor(s % 60) : Math.floor(s % 60);
      return h + ":" + m;
    },
    ebFn1(res, data) {
      this.ganttData = data;
    },
    ebFn2() {
      if (this.treeString != "") {
        this.getResource();
      }
    },
    ebFn3() {
      if (this.treeString != "") {
        this.getResource();
      }
    },
    // 获取图例
    initGanttLegends(data) {
      let object = {};
      data?.forEach((item) => {
        object[item.codevalue] = item.legend;
      });
    },
  },
  mounted() {
    if (this.$store.state.userInfoObj.language === "jp") {
      this.calendarOptions.locale = "ja";
    } else {
      this.calendarOptions.locale = this.$store.state.userInfoObj.language;
    }
    this.$Bus.$on("appointment-drag-data", this.ebFn1);
    this.$bus.$on("gantt-appoin-cancelService", this.ebFn2);
    this.$Bus.$on("get-appointList-map", this.ebFn3);
    // this.getSelectValue();
    this.calendarApi = this.$refs.myCalendar.getApi();
    this.ganttDateValue = this.getRealDate(
      this.calendarApi.currentData.currentDate
    );
    this.$emit("ganttDateValueMethod", this.ganttDateValue);

    var containerEl = document.getElementById("external-events");
    new Draggable(containerEl, {
      itemSelector: ".draggable",
      eventData: () => {
        return {
          title: this.ganttData.ganttName,
          duration: this.forHour(this.ganttData.durationIns),
          color: "#A0E0FD",
          id: this.ganttData.id,
          assid: this.assignedServiceResourceId,
        };
      },
    });
    // document.getElementsByclassname('fc-today-button').addEventListener('click', function() {
    //   calendar.today();
    // });
    // document.addEventListener("click", () => {
    //   this.isShowganttFilterMenu = false;
    // });
    this.$Bus.$on("postGanttLegends", this.initGanttLegends);
    this.$Bus.$on("upadteArea", this.getDefaultArea);
    this.getDefaultArea();
  },
  destroyed() {
    this.$Bus.$off("appointment-drag-data", this.ebFn1);
    this.$bus.$off("gantt-appoin-cancelService", this.ebFn2);
    this.$Bus.$off("get-appointList-map", this.ebFn3);
    this.$Bus.$off("postGanttLegends", this.initGanttLegends);
    this.$Bus.$off("upadteArea", this.getDefaultArea);
    localStorage.setItem("dispatchOpendArea", "[]");
  },
};
</script>
<style lang="scss">
.el-popover.ganttpopperClass {
  padding: 0 !important;
  border: 0 !important;
  // 甘特图筛选器内容
  .filterContent {
    width: 603px;
    padding: 20px 15px;
    background: #ffffff;
    // border: 1px solid #dddfe8;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.25);
    border-radius: 1px;
    z-index: 1000;
    .timeScale {
      font-weight: bold;
      color: #2e324d;
      margin-bottom: 14px;
    }
    .minuteTime {
      margin-bottom: 25px;
    }
    .hourTime {
      margin-bottom: 18px;
      ::v-deep .el-select .el-input__inner {
        width: 95px;
        height: 24px;
      }
      ::v-deep .el-input__inner {
        height: 24px;
        line-height: 24px;
      }
      ::v-deep .el-input__icon {
        height: 24px;
        line-height: 24px;
      }
    }
    // 周末显示控制
    .weekendColor {
      margin-top: 20px;
      padding: 10px 0 0 0;
      border-top: solid 1px #dddfe8;
      font-size: 12px;
      .weekend {
        margin-bottom: 10px;
      }
    }
    // 选择在甘特图中显示的小时数
    .block {
      padding: 0 10px;
    }
    // 长期视图设置
    .longViewSettings {
      padding: 10px 0;
      p {
        margin: 10px 0;
      }
    }
    // 排序方式
    .theSortingWay {
      padding: 10px 0 0 0;
      // border-bottom: solid 1px #dddfe8;
    }
    // 资源显示
    .resourcesShow {
      border-bottom: solid 1px #dddfe8;
      display: flex;
      padding: 0 0 10px;
      div {
        font-weight: 800;
      }
      .andorbox,
      .orbox {
        display: flex;
      }
      .font-nor {
        font-weight: normal;
      }
      .font-bor {
        font-weight: 800;
      }
      // padding: 20px 0 10px 0;
      // border-top: solid 1px #dddfe8;
      // border-bottom: solid 1px #dddfe8;
      // line-height: 2;
      // p {
      //   span {
      //     font-weight: 800;
      //     font-size: 12px;
      //   }
      // }
    }
    .resourcesShowItems {
      padding-bottom: 14px;
      border-bottom: solid 1px #dddfe8;
      .skillMode {
        margin: 10px 0;
      }
    }
    .getfilterreSources {
      display: flex;
      flex-wrap: wrap;
      .getfilterreSourcesItems {
        padding: 10px 10px 0 0;
      }
    }
    // 其他属性
    .OtherAttributes {
      padding: 14px 0 0;
    }
    // 班组筛选
    .teamScreening {
      //  border: solid 1px #dddfe8;
      padding: 0 0 20px 0;
    }
    .skillsControl {
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: solid 1px #dddfe8;
      padding-top: 14px;
      .el-input__inner {
        width: 200px;
        border-radius: 3px;
        height: 30px;
        margin-right: 15px;
        font-size: 12px;
      }
      .skillsCheckAllAndCancel {
        width: 100%;
        display: flex;
        justify-content: end;
        .el-button {
          padding: 4px 8px;
          color: #006dcc;
          height: 24px;
        }
      }
    }
    // // 取消和保存
    // .cancleAndSaveResources {
    //   width: 100%;
    //   display: flex;
    //   // justify-content: flex-end;
    //   padding: 20px 0;
    //   box-sizing: border-box;
    //   .el-button {
    //     padding: 8px 20px;
    //   }
    // }
    // 筛选技能提示
    .filterTip {
      margin-top: 14px;
      padding: 6px 0 0 0;
      border-top: solid 1px #dddfe8;
    }
    // 技能
    // 取消和保存
    // 技能
    // 取消和保存
    .cancleAndSaveHour,
    .cancleAndSaveResources,
    .cancleAndSaveSkills {
      width: 100%;
      display: flex;
      margin: 50px 0 0 0;
      box-sizing: border-box;
      border-top: solid 1px #dddfe8;
      justify-content: flex-end;
      .el-button {
        padding: 5px 18px;
        margin-top: 18px;
        font-size: 14px;
      }
    }
    .getfilterreSkills {
      padding: 10px 0 0 0;
      display: flex;
      flex-wrap: wrap;
      .getfilterreSkillsItems {
        height: 200px;
        overflow-y: auto;
        .el-checkbox {
          display: inline-flex;
          width: 157px;
          .el-checkbox__label {
            white-space: pre-wrap;
          }
        }
      }
    }
    // 利用率tab
    .utilization {
      p {
        padding-bottom: 10px;
      }
      .utilizationItemone {
        padding: 10px 0;
      }
      .utilizationItemtwo {
        padding: 10px 0;
      }
      .utilizationItemthree {
        padding: 10px 0;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.skillScreening {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 14px;
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #409eff !important;
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #409eff !important;
    border-color: #409eff !important;
  }
  ::v-deep
    .el-checkbox__input.is-disabled.is-checked
    .el-checkbox__inner::after {
    border-color: #fff !important;
  }
  .filterBox {
    ::v-deep .el-select {
      width: 60px !important;
    }
    ::v-deep .el-input__inner {
      height: 24px;
      line-height: 24px;
    }
    ::v-deep .el-input__icon {
      height: 24px;
      line-height: 24px;
    }
  }
}
.hourTime,
.minuteTime {
  ::v-deep .el-select .el-input__inner {
    width: 85px;
    height: 24px;
  }
  ::v-deep .el-input__inner {
    height: 24px;
    line-height: 24px;
  }
  ::v-deep .el-input__icon {
    height: 24px;
    line-height: 24px;
  }
}
::v-deep #eventImg {
  width: 30px;
  height: 30px;
  background: #ffe800;
  color: blue;
}
::v-deep .fc .fc-datagrid-cell-cushion {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep td.fc-datagrid-cell.fc-resource {
  color: #006dcc;
}
// ::v-deep .fc-header-toolbar {
//   display: none !important;
// }
::v-deep .fc .fc-scrollgrid-liquid {
  height: 100%;
  overflow: hidden;
}
::v-deep .fc .fc-datagrid-cell-cushion {
  cursor: pointer;
}
::v-deep .fc .fc-toolbar.fc-header-toolbar {
  border: solid 1px #dddfe8 !important;
}

// 日历表头部视图控制样式
::v-deep .fc .fc-toolbar.fc-header-toolbar {
  padding: 10px !important;
  margin-bottom: 0 !important;
  font-size: 0.8em !important;
}

::v-deep .fc-timeline-event .fc-event-main {
  text-align: center !important;
}
// ::v-deep .fc .fc-button {
//   background-color: white !important;
//   color: #606266;

// }
// ::v-deep .fc-timeline-event-harness {
//   top: 3px !important;
// }
// ::v-deep .fc-view-harness {
//   height: 450px !important;
// }
// ::v-deep .fc .fc-view-harness {
//   background-color: white;
// }
.page {
  user-select: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  border-top: 1px solid #dddfe8;
  text-align: center;
  line-height: 40px;
  .icon {
    display: inline-block;
    width: 16px;
    cursor: pointer;
  }
  .img-grey {
    cursor: default;
  }
  .page-number {
    display: inline-block;
    border: 1px solid #dddfe8;
    width: 40px;
    height: 20px;
    line-height: 20px;
    margin: 0 5px;
  }
}
.app-container {
  padding: 0 10px 0 0;
  background-color: white;
  //  甘特图头部的一些功能
  .theHeadFeatures {
    width: 100%;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    // border: 1px solid #dddfe8;
    padding: 15px 0 20px 0;
    // 搜索人员
    .searchPerson {
      position: relative;
      .icon {
        display: inline-block;
        position: absolute;
        top: 8px;
        left: 10px;
      }
      ::v-deep .el-input__inner {
        width: 200px;
        padding-left: 30px;
        border-radius: 3px;
        height: 30px;
        background-repeat: no-repeat;
        background-position: 8px 7px;
      }
      ::v-deep .el-input__icon {
        line-height: 30px;
      }
    }
    // 甘特图筛选器
    .ganttFilter {
      width: 50px;
      height: 30px;
      margin-left: 15px;
      background: #ffffff;
      border: 1px solid #dddfe8;
      // box-shadow: 0 0 4px 0 rgba(0, 109, 204, 0.4);
      border-radius: 3px;
      .redspot {
        background: #c40200;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        margin-left: 44px;
        margin-top: -4px;
      }
      .nuspot {
        width: 8px;
        height: 8px;
        margin-left: 44px;
        margin-top: -4px;
      }
      .filterImg {
        display: flex;
        .filterImgOne {
          width: 14px;
          margin: 3px 0 7px 7px;
        }
        .filterImgTwo {
          width: 14px;
          margin: 2px 0 8px 5px;
        }
      }
    }

    // 日期选择
    .theCalendar {
      margin-left: 50px;
      // 日期输入框
      .el-input {
        width: 140px;
        margin-left: 8px;
        ::v-deep .el-input__inner {
          height: 30px;
          line-height: 30px;
        }
        // 日期输入框内图标
        ::v-deep .el-input__icon {
          line-height: 30px;
        }
      }
    }
  }
}
.gantt-container {
  .no-data {
    user-select: none;
    width: 100%;
    text-align: center;
    margin-top: 140px;
    .icon {
      display: inline-block;
    }
  }
}
::v-deep .fc .fc-datagrid-cell-cushion {
  span:nth-child(3) {
    color: black;
    &:hover {
      color: #006dcc;
    }
  }
}
</style>
