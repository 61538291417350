<template>
  <!--筛选编辑器  {{$t('label.service.newBuildDialog.filter.editor')}}-->
  <el-dialog
    :title="$t('label.service.newBuildDialog.filter.editor')"
    :visible="dialogNewBuild"
    width="60%"
    :before-close="handleClose"
  >
    <div class="detail-container">
      <!-- 详细信息 -->
      <!-- 命名筛选器  {{$t('label.Named.Filter')}}  名称{{$t('label.emailtocloudcc.name')}}-->
      <h1>{{ $t("label.Named.Filter") }}</h1>
      <div class="detail-item detail-input">
        <!-- <label for="">{{$t('label.emailtocloudcc.name')}}</label> -->
        <el-input v-model="detailInfo"></el-input>
      </div>
      <!-- 按照水平日期筛选服务预约  {{$t('label.Horizontal.Date')}} -->
      <h1>{{ $t("label.Horizontal.Date") }}</h1>
      <div class="detail-item data">
        <!-- <el-radio-group v-model="radio"> -->
        <!-- <el-radio :label="1"> -->
        {{ $t("label.Days.Before.Horizontal.Date") }}：<el-input-number
          size="mini"
          v-model="num1"
          :min="0"
          :max="31"
        ></el-input-number>
        <div style="height: 2px"></div>
        {{ $t("label.Days.After.Horizontal.Date") }}：<el-input-number
          size="mini"
          v-model="num2"
          :min="0"
          :max="31"
        ></el-input-number>
        <!-- </el-radio> -->
        <br />
        <!-- <el-radio :label="2">显示仅在甘特图中显示的预约</el-radio> -->
        <!-- </el-radio-group> -->
      </div>
      <!-- 条件筛选 -->
      <!-- 按照附加条件筛选预约服务  {{$t('label.Additional.Conditions')}} -->
      <h1>{{ $t("label.Additional.Conditions") }}</h1>
      <div class="detail-item">
        <!-- 筛选查重范围 -->
        <div class="range">
          <!-- 筛选字段文本框 -->
          <el-form>
            <el-form-item
              v-for="(val, index) in pushData"
              :key="val.id"
              :label="index + 1 + '.'"
              label-width="50px"
            >
              <div class="vl">
                <!-- 筛选字段 开始 -->
                <!-- 选择字段  {{$t('label.selectfield')}} -->
                <el-select
                  v-model="val.fieldId"
                  :placeholder="$t('label.selectfield')"
                  filterable
                  popper-class="option"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="(items, indexs) in choseFieldList"
                    :key="indexs"
                    :label="items.labelName"
                    :value="items.id"
                    @click.native="handleCommand(val, items, items)"
                  >
                    <!-- val:传递当前字段的信息 -->
                    <!-- items:传递 -->
                  </el-option>
                </el-select>
                <!-- 筛选条件 开始 -->
                <!-- 选择条件  {{$t('label.report.select.condition')}} -->
                <el-select
                  v-model="val.op"
                  :placeholder="$t('label.report.select.condition')"
                  class="select"
                  filterable
                  popper-class="option"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="item in conditions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <span>
                {{ $t("label.tabpage.value") }}
                <!-- 值  {{$t('label.tabpage.value')}} -->
              </span>
              <EditableCell
                style="
                  width: 200px;
                  display: inline-block;
                  margin-left: 10px;
                  position: relative;
                "
                ref="editableCell"
                :options="selector"
                :value="val.val"
                :input-type="val.inputType"
                :max="1000000000000000000"
                :origin-type="filter"
                @selectes="val.fieldId === '' ? '' : selectes(val)"
                @remoteSearch="remoteSearch(val, index)"
                @remoteMethods="remoteMethods"
              >
                {{ val.fieldId }}
              </EditableCell>
              <!-- 加减按钮组 -->
              <div style="display: inline-block; margin-top: 5px">
                <el-button
                  type="info"
                  size="mini"
                  @click="updateItem('reduce', index)"
                  v-if="showBut[index]"
                  >-</el-button
                >
                <!-- 清空  {{$t('label.emailtocloudcc.clear')}} -->
                <el-button
                  type="info"
                  size="mini"
                  @click="clear"
                  v-if="index == 0"
                  >{{ $t("label.emailtocloudcc.clear") }}</el-button
                >
                <!-- <button type=" button" @click="updateItem('reduce', index)" v-if="showBut[index]"></button> -->
                <el-button
                  type="info"
                  size="mini"
                  @click="updateItem('add', index)"
                  v-if="index + 1 == pushData.length && pushData.length <= 9"
                  >+</el-button
                >
                <!-- <button type="button" @click="updateItem('add', index)" v-if="index + 1 == pushData.length">
                +
              </button> -->
              </div>
            </el-form-item>
          </el-form>
          <div>
            <!-- 搜索  {{$t('label.searchs')}} -->
            <el-dialog
              :title="$t('label.searchs')"
              custom-class="dialog-form-wrapper"
              width="60%"
              top="5%"
              style="height: 91%"
              :visible.sync="showSearchTable"
              :lock-scroll="true"
              :close-on-click-modal="false"
              :modal="true"
              append-to-body
            >
              <search-table
                ref="searchTable"
                :fieldId="fieldId"
                :checked="checked"
                :relevant-objid="relevantObjId"
                :relevant-prefix="relevantPrefix"
                @changeSelect="changeSelect"
              />
            </el-dialog>
          </div>
        </div>
        <!-- 筛选器逻辑   {{$t('label.Annex.Conditions')}}-->
        <label for="">{{ $t("label.Annex.Conditions") }}</label>
        <div class="filter">
          <el-input v-model="filterValue"></el-input>
        </div>
      </div>
      <!-- 要显示的字段  {{$t('label.Display.Fields')}}-->
      <h1>{{ $t("label.Display.Fields") }}</h1>
      <div class="detail-item detail-wo">
        <shuttle-box
          @deliverFieldId="deliverFieldId"
          :value="value"
          ref="shuttleref"
        ></shuttle-box>
      </div>
    </div>
    <!-- 使此筛选器对所有用户可用  {{$t('label.service.newBuildDialog.available.tousers'}}
           取消{{$t('label.emailsync.button.cancel')}}   保存{{$t('label.chatter.save')}}-->
    <span slot="footer" class="dialog-footer">
      <el-checkbox v-model="isAllUser">{{
        $t("label.service.newBuildDialog.available.tousers")
      }}</el-checkbox>
      <el-button @click="cancel">{{
        $t("label.emailsync.button.cancel")
      }}</el-button>
      <el-button @click="save">{{ $t("label.chatter.save") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import EditableCell from "@/components/TablePanel/EditableCell.vue";
import inputType from "@/config/enumCode/inputType.js";
import operator from "@/config/enumCode/operator.js";
import {
  saveServiceAppointmentView,
  getServiceAppointmentViewInfo,
  GetCurrencyInfo,
  getRecordType,
  GetViewGetSelectValue,
  getLookupInfo,
  queryMoreDataByName,
} from "../api";
import shuttleBox from "@/views/dispatchingUnits/component/shuttle-box.vue";

export default {
  components: {
    EditableCell,
    shuttleBox,
    // SearchTable,
  },
  props: {
    dialogNewBuild: {
      type: Boolean,
      default: false,
    },
    appointName: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      //所有用户可用
      isAllUser: false,
      value: [],
      valueStr: "",
      //筛选逻辑
      filterValue: "",
      filter: "filter",
      prefix: "002",
      // 名称
      detailInfo: "",
      //日期选择
      // radio: 1,
      // 日期选择天数
      num1: 14,
      num2: 14,
      //筛选条件
      choseFieldList: [],
      // 保存筛选字段
      pushData: [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
        },
      ],
      pushDatas: {
        fieldId: "",
        op: "",
        val: {
          value: "",
        },
        inputType: "input",
      },
      fieldId: "",
      relevantObjId: "",
      relevantPrefix: "",
      showSearchTable: false,
      checked: false, //多选框选择和取消
      selector: [],
      conditions: [],
      showBut: [false],
      //筛选字段
      conditionData: "",
    };
  },
  mounted() {
    this.choseField();
    // this.getFieldsList()
    this.getServiceViewInfo();
  },
  watch: {
    pushData(val) {
      if (val.length == 1) {
        this.showBut[0] = false;
      }
    },
  },
  methods: {
    //字段组件传过来的id
    deliverFieldId(id) {
      this.valueStr = id;
    },
    remoteMethods(val, fieldId) {
      let params = {
        fieldId: fieldId,
        objId: "",
        prefix: "",
      };
      getLookupInfo(params).then((res) => {
        let data = {
          name: val,
          objId: res.data.prefix,
          fieldId: params.fieldId,
          lkdp: "['']",
        };
        queryMoreDataByName(data).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.selector = options;
        });
      });
    },
    //清空筛选器数据
    clear() {
      this.pushData = [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
        },
      ];
    },
    //获取服务预约视图详细信息
    async getServiceViewInfo() {
      let result = await getServiceAppointmentViewInfo({
        id: this.appointName.id,
      });
      if (result.result == true) {
        // result.data.fieldList.forEach(res => {
        //   this.value.push(res.fieldid)
        // });
        this.$Bus.$emit("deliver-fields-gantt", this.value);
        this.choseFieldList = result.data.conditionAllFieldList;
        // 获取到已选择字段后调取接口
        this.$refs.shuttleref.getFieldsList();
      }
    },
    //获取对象字段列表
    // async getFieldsList() {
    //   let result = await getFieldList({ objId: "serviceappointment" });
    //   if (result.result == true) {
    //     result.data.forEach((res) => {
    //       res.key = res.id;
    //       res.label = res.label_name;
    //     });
    //     this.data = result.data;
    //     this.data.forEach((res) => {
    //       if (
    //         res.id == "ffe21sappointment008" ||
    //         res.id == "ffe21sappointment038" ||
    //         res.id == "ffe21sappointment039" ||
    //         res.id == "ffe21sappointment044" ||
    //         res.id == "ffe21sappointment055"
    //       ) {
    //         this.value.push(res.id);
    //       }
    //     });
    //   }
    // },
    //保存
    async save() {
      let arrData = [];
      this.pushData.map((item) => {
        let object = {
          fieldId: item.fieldId,
          op: item.op,
          val: "",
        };
        arrData.push(object);
      });
      this.$refs.editableCell.forEach((item, index) => {
        if (item.editValue !== "") {
          arrData[index].val = item.editValue;
        } else {
          arrData[index].val = item.value;
        }
      });
      if (arrData.length > 0) {
        // 验证筛选器是否填写完整
        for (var key in arrData) {
          var item = arrData[key];
          if (item.fieldId !== "") {
            if (item.val.value == "" || item.op == "") {
              this.$message({
                showClose: true,
                type: "warning",
                // 请填写完整筛选条件
                message: this.$i18n.t(
                  "vue_label_checkduplicate_complete_filter_rules"
                ),
              });
              return false;
            } else if (item.val.value != "" && item.op != "") {
              // let str = "1";
              // if (arrData.length == 1) {
              //   str = "1";
              // } else if (arrData.length > 1) {
              //   arrData.map((item, index) => {
              //     if (index >= 1) {
              //       str += ` AND ${index + 1} `;
              //       // str = str.replace(/AND $/, "");
              //     }
              //   });
              // }
              this.conditionData = JSON.stringify({
                data: arrData,
                // filter: str,
                filter: this.filterValue ? this.filterValue : "",
              });
            }
          } else {
            this.conditionData = JSON.stringify({
              data: [
                {
                  fieldId: "",
                  op: "",
                  val: "",
                },
              ],
              filter: this.filterValue ? this.filterValue : "",
            });
          }
        }
      } else {
        this.conditionData = "";
      }
      if (this.detailInfo == "") {
        this.$message({
          type: "warning",
          message: this.$i18n.t("vue_label_homepage_enter_name"),
        });
        return false;
      }
      if (this.detailInfo.length > 25) {
        this.$message({
          type: "warning",
          message: this.$i18n.t("label.service.newBuildDialog.characterlimit"), //名称超出25个字符限制this.$i18n.t('label.service.newBuildDialog.characterlimit')
        });
        return false;
      }
      if (this.valueStr == "") {
        this.$message({
          type: "warning",
          message: this.$i18n.t("label.processsetting.please.select.field"), //请选择字段this.$i18n.t('label.processsetting.please.select.field')
        });
        return false;
      }
      let option = {
        label: this.detailInfo,
        objId: "serviceappointment",
        isprivate: this.isAllUser == true ? "0" : "1",
        conditionVals: this.conditionData,
        daysbeforehorizontal: this.num1,
        daysafterhorizontal: this.num2,
        selectedFieldIds: this.valueStr,
      };
      let result = await saveServiceAppointmentView(option);
      if (result.result == true) {
        this.detailInfo = "";
        this.isAllUser = false;
        this.conditionData = "";
        this.$message({
          type: "success",
          message: this.$i18n.t("savesuccess"), //保存成功 this.$i18n.t('savesuccess')
        });
        this.$emit("closeNewBuild", "again", result.data.id);
      }
    },
    handleClose() {
      this.$emit("closeNewBuild", false);
    },
    //取消新建
    cancel() {
      this.$emit("closeNewBuild", false);
    },
    updateItem(handle, index) {
      if (handle == "add") {
        if (this.pushData.length <= 9) {
          this.pushData.push({
            ...this.pushDatas,
            // id: this.num,
          });
          if (this.pushData.length >= 0 && this.pushData.length <= 1) {
            this.showBut = [false];
          } else {
            this.showBut.push(true);
            this.showBut[0] = true;
          }
        }
      } else {
        this.pushData.splice(index, 1);
        // this.$refs.editableCell.splice(index, 1);
      }
    },
    remoteSearch(item, index) {
      if (item.fieldId !== "") {
        this.fieldId = item.fieldId;
      }
      this.rowIndex = index + 1;
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    async choseField() {
      // let params = {
      //   objId: "lead",
      //   viewId: "aec2020A7C2081DrYmyi",
      // };
      // let result = await GetViewInfo(params);
      // this.choseFieldList = result.data.conditionAllFieldList;
      this.choseFieldList = this.appointName.conditionAllFieldList;
    },
    //判断选择添加如果选择字段获取的值为S则调用S对象,获取的值为D则调用D对象,获取的值为F则调用F对象,获取的值为O则调用O对象
    async handleCommand(item, key, index) {
      this.selector = [];
      let keys = "";
      if (key.schemefieldType === "Z") {
        keys = key.expressionType;
      } else {
        keys = key.schemefieldType;
      }
      this.conditions = operator.TYPE[keys].getScreeningOpeTypes();
      this.pushData.map((item) => {
        if (index.id === item.fieldId) {
          // item.op = ''
          // item.type =
          key.schemefieldType === "Z"
            ? key.expressionType
            : key.schemefieldType;
          // this.$refs.editableCell[indexs].editValue = "";
        }
      });
      this.pushData.forEach((items) => {
        if (item.id === items.id) {
          // item.inputType = inputType[keys === 'L' ? 'Q' : keys]
          item.inputType = inputType[keys];
        }
      });
      // 当前选中字段类型
      this.conditions = operator.TYPE[keys].getScreeningOpeTypes();
      if (keys == "ct") {
        let res = await GetCurrencyInfo();
        res.data.forEach((item) => {
          let obj = {
            label: item.currencyname,
            value: item.currencycode,
          };
          this.selector.push(obj);
        });
      }
    },
    // 改变查找/查找带值选项及值
    changeSelect(row) {
     
      let options = {
        label: row.data.id,
        value: row.data.name,
      };
      this.$set(this.pushData[this.rowIndex - 1], "val", options);
      this.showSearchTable = false;
    },
    async selectes(val) {
      if (val.type === "R") {
        this.getRecordTypes();
      }
      // else if(val.type === "L" && val.fieldId === "ffe202085ACBDD8EusnH"){
      //   this.getCountrySelectValue()
      // }
      else {
        if (val.fieldId === "ffe20120329185449704") {
          return;
        }
        let arr = [];
        let params = {
          fieldId: val.fieldId,
        };
        let res = await GetViewGetSelectValue(params);
        if (res.result) {
          res.data.forEach((item) => {
            let obj = {
              label: item.codekey,
              value: item.codekey,
            };
            arr.push(obj);
            this.selector = arr;
          });
        }
      }
    },
    // 获取国家或地区选项列表值
    // getCountrySelectValue(filedName) {
    //   getSelectValue({ fieldId: "country" }).then((res) => {
    //     if (res.result && res.returnCode === "1") {
    //       let options = [];
    //       res.data.forEach((item) => {
    //         options.push({
    //           label: item.codevalue,
    //           value: item.codekey,
    //         });
    //       });
    //       this.selector = options;
    //     } else {
    //       this.$message.error(res.returnInfo);
    //     }
    //   });
    // },
    async getRecordTypes() {
      let arr = [];
      let params = {
        prefix: this.prefix,
      };
      let res = await getRecordType(params);
      if (res.result) {
        res.data.recordTypeList.forEach((item) => {
          let obj = {
            label: item.name,
            value: item.name,
          };
          arr.push(obj);
          this.selector = arr;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/dialogStyle.scss";
.range {
  margin-top: 10px;
}
.range button {
  width: 42px;
  height: 32px;
  margin-left: 10px;
}
.detail-container {
  width: 100%;
  h1 {
    font-size: 14px;
    color: #1d2033;
    font-weight: 400;
  }
  .detail-item {
    label {
      color: #1d2033;
      margin-bottom: 10px;
    }
    ::v-deep .el-input-number--mini {
      margin: 0 5px;
    }
    ::v-deep .el-radio__input.is-checked + .el-radio__label {
      color: #1d2033;
    }
    .filter {
      ::v-deep .el-input__inner {
        width: 70%;
        height: 30px;
        border-radius: 3px;
        margin: 5px 0;
      }
    }
  }
  .detail-input {
    ::v-deep .el-input__inner {
      width: 70%;
      height: 30px;
      border-radius: 3px;
      margin: 5px 0;
    }
  }
  .data {
    margin-bottom: 5px;
    ::v-deep .el-input__inner {
      height: 28px !important;
    }
  }
}
.detail-wo {
  ::v-deep .el-input__icon {
    line-height: 32px !important;
  }
  ::v-deep .el-button {
    padding: 10px 15px;
  }
}
::v-deep .el-dropdown-menu {
  height: 400px;
  overflow: auto;
}
::v-deep .el-input__inner {
  height: 32px;
}
::v-deep .el-input__icon {
  line-height: 20px;
}
::v-deep .el-form-item__label {
  line-height: 32px;
  width: auto !important;
}
.vl {
  display: inline-block;
}
::v-deep .el-form-item__content {
  line-height: 27px;
  margin-left: 0 !important;
}
::v-deep .el-select {
  margin-right: 10px;
}
::v-deep .el-form-item__label {
  line-height: 42px;
}
::v-deep .el-form-item {
  margin-bottom: 5px;
}
::v-deep .remoteBtn {
  position: absolute;
  right: 8px;
  top: 2px;
}
::v-deep .el-button--mini,
.el-button--mini.is-round {
  padding: 7px 8px;
}
::v-deep .el-button--info {
  background: #ffffff;
  border-color: #dcdfe6;
}
::v-deep .el-button--info {
  color: black;
}
::v-deep .el-textarea__inner {
  transform: translateY(4px);
  min-height: 32px !important;
}
::v-deep .el-dialog__footer {
  text-align: initial;
  overflow: hidden;
  button {
    float: right;
  }
}
::v-deep .el-date-editor {
  display: none !important;
}
::v-deep .el-dialog__footer ::v-deep .el-button:last-child {
  margin-right: 10px;
}
::v-deep .remoteBtn1 {
  top: 3px;
}
::v-deep .el-dialog__footer button {
  margin-left: 10px;
}
</style>
