<!--
 * @Author: 张凤
 * @Date: 2021-06-18 15:11:25
 * @LastEditTime : 2021-09-23 11:43:09
-->
<template>
  <!-- 候选人列表 -->
  <div
    class="candidateNeutral"
    :class="isShowTowMenu == true ? 'candidateNeutral1' : ''"
    v-loading="reconmendLoading"
  >
    <div
      class="candidateNeutralBox"
      :class="showBatchDispatch > 0 ? 'computed-height' : ''"
      v-if="serviceAreaData && serviceAreaData.length > 0"
      v-loadmore="loadmore"
    >
      <div
        class="candidate-item"
        v-for="(item, canindex) in serviceAreaData"
        :key="canindex"
      >
        <div class="areaList">
          <!-- 服务区域 -->
          <div
            class="area-header"
            :class="radioServiceArea == item.id ? 'area-active' : ''"
          >
            <div
              class="header-box"
              @mouseenter="mouseOverArea(canindex)"
              @mouseleave="mouseOutArea"
            >
              <div
                class="area-raido"
                @click.exact="setCurrentServerArea($event, canindex, item)"
                @click.ctrl.exact="setTwoMenu(item)"
                @click.meta.exact="setTwoMenu(item)"
              >
                <div
                  :class="currentServer === canindex ? 'serverarea-name' : ''"
                  class="serverarea-namemax"
                >
                  {{ item.name }}
                </div>
                <div class="candidate-circle">
                  {{ setNumber(item.score) }}/100
                </div>
              </div>
              <div class="area-btn" v-show="mouseOverAreaIndex === canindex">
                <el-button
                  v-show="isShowDistribution && !item.relateApp"
                  type="primary"
                  size="mini"
                  :loading="distributionLoading"
                  @click="serviceAreaAssignment(item)"
                >
                  <!-- 分配 -->
                  {{ $t("vue_label_pool_assign") }}
                </el-button>
                <el-button
                  v-show="isShowDistribution && item.relateApp == '1'"
                  type="primary"
                  size="mini"
                  @click="getObjectPermission(item)"
                >
                  <!-- 取消分配 -->
                  {{ $t("c1974") }}
                </el-button>
              </div>
            </div>
            <div
              class="distance-box"
              style="width: 100%; font-size: 12px; color: #576c9c"
            >
              <div
                v-if="item.zyzg.length > 0 || item.zdcx"
                class="infor-distance-box"
              >
                <div class="infor-distance" v-if="item.zdcx">
                  <!-- 距离计算异常 -->
                  <div class="distance-red" v-if="!item.zdcx.distance">
                    {{ $t("c2415") }}
                  </div>
                  <!-- 距离在1-9999km范围内 -->
                  <div v-else-if="item.zdcx.distance">
                    {{ $t("c2416") }} :
                    {{ getDistance(Number(item.zdcx.distance)) }}
                  </div>
                </div>
                <div
                  class="infor-distance"
                  v-for="(zyzgitem, zyzgindex) in item.zyzg"
                  :key="zyzgindex"
                >
                  {{ zyzgitem.qualificationfieldName }} :
                  {{ zyzgitem.qualificationfieldNameValue }}
                </div>
              </div>
              <!-- 没有socre时不显示资源首选 -->
              <div
                class="first-choose"
                v-if="item.zysx && item.zysx.score"
                style="margin: 12px 12px 0 0"
              >
                {{ $t("c2419") }}
              </div>
            </div>
            <!-- 关键信息 -->
            <div
              class="crux-infor"
              :class="radioServiceArea != item.id ? 'crux-svg-border' : ''"
              @mouseenter="mouseOverCrux(canindex)"
              @mouseleave="mouseOutCrux"
            >
              <div
                class="infor-box"
                v-for="(cruxItem, cruxIndex) in item.heigthLight"
                :key="cruxIndex"
              >
                <div class="infor-title">
                  {{ cruxItem.fieldLabel }}
                </div>
                <div class="infor-value" v-if="cruxItem.fieldType != 'B'">
                  {{ cruxItem.fieldValue }}
                </div>
                <div class="infor-svg" v-if="cruxItem.fieldType == 'B'">
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-if="cruxItem.fieldValue"
                  >
                    <use href="#icon-Click-Check"></use>
                  </svg>
                  <svg class="icon" aria-hidden="true" v-else>
                    <use href="#icon-choose1"></use>
                  </svg>
                </div>
              </div>
              <div
                class="see-more"
                v-if="mouseOverCruxIndex === canindex"
                @click="getSeeMoreDetail(item)"
              >
                查看更多->
              </div>
            </div>
          </div>
          <!-- 服务资源 -->
          <el-checkbox-group
            v-model="formData.formCheckServiceResourid"
            @change="(value) => handlecheckedServeChange(value)"
          >
            <div
              v-if="radioServiceArea == item.id && currentServer === canindex"
            >
              <div
                class="candidateList"
                v-for="(candidateitem, index) in candidateData"
                :key="index"
                @click="selectcandidate(candidateitem, index, $event)"
              >
                <div class="person">
                  <el-checkbox class="person-name" :label="candidateitem.srid">
                    <div class="personImg">
                      <img :src="getHeadImg(candidateitem.srownerid)" />
                    </div>
                    <div class="personInfor">
                      <div class="personNum">
                        <div class="personInfor-title">
                          <div class="personName">
                            {{ candidateitem.srname }}
                          </div>
                          <div
                            class="assigned"
                            v-show="candidateitem.isAssigned == 'true'"
                          >
                            {{ $t("label.projectManagement.dispatched") }}
                          </div>
                          <div class="candidate-circle candidate-nobold">
                            {{ setNumber(candidateitem.score) }}/100
                          </div>
                          <!-- 距离计算异常 -->
                          <div
                            class="first-distance distance-red"
                            v-if="
                              candidateitem.zdcx && !candidateitem.zdcx.distance
                            "
                          >
                            {{ $t("c2415") }}
                          </div>
                          <div
                            class="first-distance"
                            v-if="
                              candidateitem.zdcx && candidateitem.zdcx.distance
                            "
                          >
                            {{
                              getDistance(Number(candidateitem.zdcx.distance))
                            }}
                          </div>
                          <div
                            class="first-choose"
                            v-show="
                              candidateitem.zysx && candidateitem.zysx.score
                            "
                          >
                            {{ $t("c2419") }}
                          </div>
                        </div>
                        <div class="totalselect">
                          {{ candidateitem.total
                          }}{{ $t("label.tabpage.optionsz") }}
                        </div>
                      </div>
                    </div>
                  </el-checkbox>
                </div>
                <!-- 服务资源派工时段 -->
                <el-radio-group
                  v-model="
                    formData.formServiceResourcesData[index]
                      .formServiceResourtime
                  "
                  @change="modifyTimeRadio(canindex, index)"
                  size="small"
                >
                  <div
                    v-show="getCheckDispatchTime(candidateitem)"
                    @mouseover="serveMouseOver(candidateitem)"
                    @mouseout="serveMouseout"
                    class="timeBox"
                    v-for="(Timeitem, Timeindex) in candidateitem.newSrTimeList"
                    :key="Timeindex"
                  >
                    <div
                      class="time-item"
                      :class="timeBoxFlag ? 'timemouse' : ''"
                      @click="switchDispatchTime($event, Timeitem)"
                    >
                      <el-radio
                        :label="candidateitem.srid + ',' + Timeitem.currenttime"
                      >
                        <div
                          class="diff-time"
                          :class="timeBoxFlag ? 'timemouse' : ''"
                        >
                          <span>{{ Timeitem.startTime }}</span>
                          <div class="duration-red">
                            {{ Timeitem.duration }}
                          </div>
                          <span>{{ Timeitem.endTime }}</span>
                        </div>
                      </el-radio>
                    </div>
                  </div>
                </el-radio-group>
              </div>
            </div>
          </el-checkbox-group>
          <!-- 骨架屏 -->
          <Wang-skeleton
            v-if="isShowSkeleton && currentServer === canindex"
            type="line"
            :options="skeletonOptions"
          >
          </Wang-skeleton>
          <!-- 服务资源无数据 -->
          <div
            class="no-candidate"
            v-if="
              currentServer === canindex &&
              candidateData &&
              candidateData.length == 0 &&
              !isShowSkeleton
            "
          >
            {{ $t("c2438") }}
          </div>
          <!-- 收起和展开服务资源 -->
          <div
            class="crux-svg"
            :class="radioServiceArea == item.id ? '' : 'crux-svg-border'"
          >
            <svg
              class="icon"
              v-if="currentServer !== canindex"
              @click="getAreaIcon($event, canindex, item)"
              aria-hidden="true"
            >
              <use href="#icon-xiajiantou"></use>
            </svg>
            <svg
              class="icon transformsvg"
              v-if="currentServer === canindex"
              @click="getAreaIcon($event, canindex, item)"
              aria-hidden="true"
            >
              <use href="#icon-xiajiantou"></use>
            </svg>
          </div>
        </div>
      </div>
      <div class="no-databox">
        <div v-if="pageNum < maxPageNum + 1" class="load-data">
          <div class="no-loading" v-loading="reconmendMoreLoading"></div>
          {{ $t("label.file.loading") }}
        </div>
        <div
          v-if="pageNum == maxPageNum + 1 || maxPageNum == 0"
          class="load-data"
        >
          {{ $t("label.weixin.nomore") }}
        </div>
      </div>
    </div>
    <div v-else class="no-data">
      <div class="no-data-svg">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-houxuanren-shaixuanwujieguo"></use>
        </svg>
      </div>
      <div class="no-data-title">
        <p>{{ $t("c2235") }}</p>
      </div>
    </div>
    <div class="moreDispatch" v-if="showBatchDispatch > 0">
      <el-button size="mini" @click="cancelDispatch">
        {{ $t("label.tabpage.cancel") }}
      </el-button>
      <el-button
        type="primary"
        @click="serviceAppoDispatch"
        size="mini"
        v-if="showBatchDispatch === 1"
        :disabled="!isShowDispatch"
        :loading="AppoDispatchLoading"
      >
        <!-- 派遣 -->
        {{ $t("c1733") }}
      </el-button>
      <el-button
        type="primary"
        @click="serviceAppoDispatch"
        size="mini"
        v-if="showBatchDispatch > 1"
        :disabled="!isShowDispatch"
        :loading="AppoDispatchLoading"
      >
        <!-- 批量派遣 -->
        {{ $t("label.service.dispatchingUnits.batch.send") }}
      </el-button>
    </div>
    <!--  -->
    <el-dialog
      :title="$t('label.popup.infoz')"
      :visible.sync="cancelServiceVisible"
      width="30%"
      :before-close="cancelServiceClose"
    >
      <span>{{ $t("c2135") }} </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelServiceVisible = false">{{
          $t("label.tabpage.cancel")
        }}</el-button>
        <el-button
          type="primary"
          :loading="cancelServiceLoading"
          @click="cancelServiceAreaAssignment"
        >
          {{ $t("setup.layout.button.ok") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import VueCookies from "vue-cookies";
import {
  recommendCandidatesST,
  serviceAppoDispatch,
  recommendCandidates,
  serviceAreaAssignment,
  cancelServiceAreaAssignment,
  getObjectPermission,
} from "../api.js";

export default {
  components: {},
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding, vnode) {
        let that = vnode.context;
        el.addEventListener("scroll", function () {
          let sign = 100;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (
            scrollDistance <= sign &&
            that.pageNum <= that.maxPageNum &&
            that.reconmendMoreLoading === false
          ) {
            that.pageNum += 1;
            that.recommendCandidatesST(
              that.appointmentLoadMoreId,
              that.PolicyLoadMoreId,
              "loadmore"
            );
          }
        });
      },
    },
  },
  props: {
    // 策略id
    strategyValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 是否显示禁选
      timeBoxFlag: false,
      // 是否显示派遣按钮
      isShowDispatch: false,
      // 是否显示分配按钮
      isShowDistribution: false,
      // 候选人列表数据
      serviceAreaData: [],
      // 当前服务区域下标
      currentServer: "",
      // 可派工数据
      candidateData: [],
      TimeRadio: false, //服务资源单选按钮
      chooseCandidateActive: "", //查看某人的时间空档
      chooseServeActive: "", //查看某人的时间空档
      tokenCookie: "", //token
      imgUrlCookie: "", //域名
      radioServiceArea: "", //服务区域单选按钮值
      reconmendLoading: false, //候选人列表loading
      serviceappointmentId: "", //服务预约id
      serviceterritoryId: "", //服务区域id
      serverAreaName: "",
      mouseOverAreaIndex: "", //服务区域分配按钮悬浮显示
      mouseOverCruxIndex: "", //服务资源关键信息鼠标悬浮
      cancelServiceVisible: false, //取消分配二次确认弹框
      objectPermission: {}, //点击取消分配获取当前服务区域数据
      formData: {
        //选中参数
        //   formServiceAreaId: "", //服务区域id
        //   isCheckServiceArea: "", //是否候选了服务区域
        //   formCheckServiceResourid: [],
        //   formServiceResourcesData: [
        //     //服务资源数据
        //     {
        //       formServiceResourName: "", //服务资源名称
        //       formServiceResourid: "", //服务资源id
        //       formServiceResourtime: "", //派工时间
        //       formStartTime: "", //开始时间
        //       formEndTime: "", //开始时间
        //       formDurationTime: "", //持续时间
        //       estimatedTravelTime:'',//实际出行时间（小时）
        //       actualDrivingDistance:'',//实际的驾车距离
        //     },
        //  ]
      }, //选中的服务资源和派工时段
      showBatchDispatch: 0, //是否显示批量派遣按钮
      isShowSkeleton: false, //是否显示骨架屏
      // 骨架屏数据
      skeletonOptions: {
        active: true,
        row: 3,
        column: 2,
        width: "10vw",
        height: "20px",
        justifyContent: "space-around",
      },
      //取消分配二次确定按钮loading
      cancelServiceLoading: false,
      // 分配按钮加loading
      distributionLoading: false,
      // 派遣的loading
      AppoDispatchLoading: false,
      pageNum: 1, //页码
      pageSize: 20, //每页数量默认20
      maxPageNum: 1, //最大页码
      appointmentLoadMoreId: {}, //滚动加载时服务预约id
      PolicyLoadMoreId: "", //滚动加载时策略id
      reconmendMoreLoading: false, //滚动加载loading
      isShowTowMenu: false, //是否显示二级菜单
    };
  },
  mounted() {
    //token
    this.tokenCookie = this.$CCDK.CCToken.getToken();
    //域名
    this.imgUrlCookie = VueCookies.get("domainName");
    this.getShowTwoMenu();
    this.$bus.$on("setConsoleDispatchingUnits", this.getShowTwoMenu);
  },
  destroyed() {
    this.$bus.$off("setConsoleDispatchingUnits", this.getShowTwoMenu);
  },
  computed: {
    ...mapState(["consoleData"]),
  },
  methods: {
    // 是否显示二级菜单
    getShowTwoMenu() {
      if (this.$store.state.navigationStyle) {
        let currentMenu = this.consoleData.data.list.find((item) => {
          if (
            item.routerName == "consoleDispatchingUnits" &&
            item.children?.length > 0
          ) {
            return item;
          }
        });
       
        if (currentMenu) {
          this.isShowTowMenu = true;
        } else {
          this.isShowTowMenu = false;
        }
      }else{
        this.isShowTowMenu = false;
      }
    },
    loadmore() {},
    // 距离<1：<1km
    // 距离>9999：>9999km
    // 距离：+km
    getDistance(value) {
      if (value > 0 && value < 1) {
        return " < 1.00 km";
      } else if (value > 9999) {
        return " > 9999.00 km";
      } else if (value) {
        return value + " km";
      } else {
        return "0";
      }
    },
    // 秒转换成小时，保留两位小数
    getdirveHours(value) {
      if (value) {
        return (value / 3600).toFixed(2);
      } else {
        return "0";
      }
    },
    // 计算服务目标显示数据，最多显示10个
    getTargetNum(data, index) {
      // 有最短出行，资源首选两种固定目标
      let num = 9;
      if (data.zdcx) {
        num = num - 1;
      }
      if (data.zysx) {
        num = num - 1;
      }
      if (index < num) {
        return true;
      } else {
        return false;
      }
    },
    // ctrl+单击打开二级菜单
    setTwoMenu(data) {
      // 判断在服务控制台程序下，点击查看更多打开二级菜单
      if (this.$store.state.navigationStyle) {
        this.$CCDK.CCPage.openDetailPage(
          {
            oprateType: "DETAIL",
            objectName: data.name,
            objId: data.id,
          },
          data.id,
          {
            openPlace: "menu2",
            openMode: "_blank",
            pageId: data.id,
          }
        );
        this.getShowTwoMenu();
      } else {
        this.$router.push({
          path: `/commonObjects/detail/${data.id}/DETAIL`,
        });
      }
    },
    // 取消派遣取消勾选的数据
    cancelDispatch() {
      this.formData = {
        formServiceAreaId: "",
        isCheckServiceArea: "",
        formCheckServiceResourid: [],
        formServiceResourcesData: [],
      };
      this.formData.formServiceResourcesData.map((formitem) => {
        formitem.formServiceResourtime = "";
        formitem.formStartTime = "";
        formitem.formEndTime = "";
        formitem.formDurationTime = "";
      });
      this.showBatchDispatch = 0;
      this.radioServiceArea = "";
    },
    // 根据页面展示处理时间
    // sritem:时间字段
    // type：day:比较两个日期的间隔天数，没有type则计算相隔小时数
    getEditTime(sritem, type) {
      let newTimeList = {};
      newTimeList.startDate = sritem.substring(0, 19);
      newTimeList.endDate = sritem.substring(20, sritem.length);
      // 开始日期
      let currentstartdate = new Date(newTimeList.startDate.replace(/-/g, "/"));
      newTimeList.startTime = this.setStartEndTime(currentstartdate, "start");
      // 结束日期
      let currentenddate = new Date(newTimeList.endDate.replace(/-/g, "/"));
      newTimeList.endTime = this.setStartEndTime(currentenddate, "end");
      let diffStartDate = this.setStartEndTime(currentstartdate, "diff");
      let diffEndDate = this.setStartEndTime(currentenddate, "diff");
      if (type == "day") {
        if (this.getDiffDay(diffEndDate, diffStartDate) > 0) {
          newTimeList.duration =
            "+" + this.getDiffDay(diffEndDate, diffStartDate);
        } else {
          newTimeList.duration = "";
        }
      } else {
        var ms = currentenddate.getTime() - currentstartdate.getTime();
        if (ms < 0) {
          newTimeList.duration = "0";
        } else {
          newTimeList.duration = Math.floor(ms / 1000 / 60 / 60);
        }
      }
      newTimeList.time = currentstartdate + "|" + currentenddate;
      newTimeList.currenttime = sritem;
      return newTimeList;
    },
    // 校验是否展示下面的派工台工作时段
    getCheckDispatchTime(data) {
      let findId = this.formData.formCheckServiceResourid.find((item) => {
        return item == data.srid;
      });
      if (findId) {
        return true;
      } else {
        return false;
      }
    },
    // 点击取消分配，调权限接口,有权限显示二次确认菜单
    async getObjectPermission(data) {
      let params = {
        id: "serviceappointment",
      };
      let res = await getObjectPermission(params);
      if (res.result) {
        if (res.data.modify) {
          this.objectPermission = data;
          this.cancelServiceVisible = true;
        } else {
          this.$message.error(this.$i18n.t("c2136"));
        }
      }
    },
    // 关闭取消分配弹框
    cancelServiceClose() {
      this.cancelServiceVisible = false;
    },
    // 取消分配接口
    async cancelServiceAreaAssignment() {
      this.cancelServiceLoading = true;
      let params = {
        serviceAppointmentId: this.serviceappointmentId, //	服务预约id
        serviceTerritoryId: this.objectPermission.id, //	服务区域id
      };
      let res = await cancelServiceAreaAssignment(params);
      if (res.result) {
        this.$message.success(this.$i18n.t("c2137"));
        this.objectPermission = {};
        this.cancelServiceVisible = false;
        // 取消分配后重新调取候选人列表，更新分配权限
        this.$emit("strategyChange");
        this.$emit("getbtnJurisdiction");
        this.cancelServiceLoading = false;
      } else {
        this.$message.error(this.$i18n.t("c2138"));
        this.objectPermission = {};
        this.cancelServiceLoading = false;
      }
    },
    // 更新复选框
    handlecheckedServeChange(value) {
      let choosevalue = value[value.length - 1];
      let currentSource = {}; //当前服务资源
      this.candidateData.map((canitem) => {
        if (canitem.srid == choosevalue) {
          currentSource = canitem;
        }
      });
      // 找对应的服务区域
      let serviceResourid = this.formData.formCheckServiceResourid.toString();
      // 判断当前选中了几条服务资源，资源>1,显示批量派遣||派遣
      // 勾选复选框，默认选中第一个派工时间段
      if (serviceResourid) {
        this.formData.formServiceResourcesData.map((formitem) => {
          if (
            formitem.formServiceResourid === choosevalue &&
            currentSource?.srTimeList?.length > 0
          ) {
            formitem.formStartTime = this.getEditTime(
              currentSource.srTimeList[0]
            ).startDate;
            formitem.formEndTime = this.getEditTime(
              currentSource.srTimeList[0]
            ).endDate;
            formitem.formDurationTime = this.getEditTime(
              currentSource.srTimeList[0]
            ).duration;
            formitem.formServiceResourtime =
              choosevalue +
              "," +
              this.getEditTime(currentSource.srTimeList[0]).currenttime;
          } else if (
            serviceResourid.indexOf(formitem.formServiceResourid) < 0
          ) {
            formitem.formServiceResourtime = "";
            formitem.formStartTime = "";
            formitem.formEndTime = "";
            formitem.formDurationTime = "";
          }
        });
      }
      // 如果当前没选中复选框，不显示派遣按钮
      if (this.formData.formCheckServiceResourid.length == 0) {
        this.showBatchDispatch = 0;
        this.formData.formServiceResourcesData.map((item) => {
          item.formServiceResourtime = "";
          item.formStartTime = "";
          item.formEndTime = "";
          item.formDurationTime = "";
        });
      } else if (this.formData.formCheckServiceResourid.length >= 1) {
        let num = 0;
        this.formData.formServiceResourcesData.map((item) => {
          if (item.formStartTime && item.formEndTime && item.formDurationTime) {
            num += 1;
          }
        });
        if (num == 1) {
          this.showBatchDispatch = 1;
        } else if (num > 1) {
          this.showBatchDispatch = 2;
        } else {
          this.showBatchDispatch = 0;
        }
      }
    },
    // 判断打开二级菜单
    getSeeMoreDetail(item) {
      // 判断在服务控制台程序下，点击查看更多打开二级菜单
      if (this.$store.state.navigationStyle) {
        let menuObj = {
          name: item.name, // tab名称
          id: item.id, //id，唯一标识tab的
          routerName: "consoleDetail", //路由的名字
          params: {
            id: item.id,
            oprateType: "DETAIL",
          },
        };
        window.$CCDK.CCMenu.addMenu1(menuObj);
      } else {
        this.$router.push({
          path: `/commonObjects/detail/${item.id}/DETAIL`,
        });
      }
    },
    //鼠标进入关键信息悬浮
    mouseOverCrux(index) {
      this.mouseOverCruxIndex = index;
    },
    //鼠标离开关键信息取消悬浮
    mouseOutCrux() {
      this.mouseOverCruxIndex = "";
    },
    // 点击图标显示服务资源
    getAreaIcon(el, index, item) {
      if (el.target.tagName === "INPUT") return;
      if (this.currentServer === index) {
        this.currentServer = "";
        this.radioServiceArea = "";
      } else {
        this.isShowSkeleton = true;
        this.currentServer = index;
        this.radioServiceArea = item.id;
        this.recommendCandidates();
      }
    },
    // 服务区域悬浮显示分配按钮
    mouseOverArea(index) {
      this.mouseOverAreaIndex = index;
    },
    // 取消显示分配按钮
    mouseOutArea() {
      this.mouseOverAreaIndex = "";
    },
    // 数字保留两位小数
    setNumber(value) {
      let str = value.toString();
      let index = str.indexOf(".");
      let number = value;
      if (index > -1) {
        number = str.substring(0, index + 3);
        return number;
      } else {
        return number;
      }
    },
    // 鼠标移入data
    serveMouseOver() {
      // if(data.isAssigned == 'true'){
      //   this.timeBoxFlag=true;
      // }else{
      //   this.timeBoxFlag=false;
      // }
    },
    // 鼠标移出
    serveMouseout() {
      this.timeBoxFlag = false;
    },
    // 计算两个日期相差天数
    getDiffDay(date_1, date_2) {
      // 计算两个日期之间的差值
      let totalDays, diffDate;
      let myDate_1 = Date.parse(date_1);
      let myDate_2 = Date.parse(date_2);
      // 将两个日期都转换为毫秒格式，然后做差
      diffDate = Math.abs(myDate_1 - myDate_2); // 取相差毫秒数的绝对值

      totalDays = Math.floor(diffDate / (1000 * 3600 * 24)); // 向下取整
      return totalDays; // 相差的天数
    },
    // 切换派工时间段定位到甘特图
    switchDispatchTime(el, data) {
      if (el.target.tagName === "INPUT") return;
      if (data) {
        this.$emit("locationGrantt", data.startDate);
      }
    },
    // 点击当前的服务区域,收起或展开
    setCurrentServerArea(el, index, item) {
      if (el.target.tagName === "INPUT") return;
      if (this.currentServer === index) {
        this.currentServer = "";
        this.radioServiceArea = "";
        this.showBatchDispatch = 0;
        // 切换服务区域时，清空其他区域选中数据
        this.formData = {
          formServiceAreaId: "",
          isCheckServiceArea: "",
          formCheckServiceResourid: [],
        };
        this.formData.formServiceResourcesData &&
          this.formData.formServiceResourcesData.map((formitem) => {
            formitem.formServiceResourtime = "";
            formitem.formStartTime = "";
            formitem.formEndTime = "";
            formitem.formDurationTime = "";
          });
      } else {
        this.isShowSkeleton = true;
        this.currentServer = index;
        this.radioServiceArea = item.id;
        this.recommendCandidates();
      }
    },
    // 分配接口
    async serviceAreaAssignment(data) {
      this.distributionLoading = true;
      let params = {
        serviceAppointmentId: this.serviceappointmentId, //	服务预约id
        serviceTerritoryId: data.id, //	服务区域id
        esdrivingDistance: data?.zdcx?.distance, //预计驾车距离
        midrivingDistance: data?.zdcx?.minDistance, //最短驾车距离
        policyId: this.strategyValue, //计划策略id
      };
      let res = await serviceAreaAssignment(params);
      if (res.result) {
        this.distributionLoading = false;
        let areaSuccess = [
          {
            region: res.data[0].tname, //服务区域名称
            startTime: this.setStartEndTime(
              res.data[0].schedstarttime,
              "planstart"
            ), //	计划开始时间
            endTime: this.setStartEndTime(
              res.data[0].schedendtime,
              "planstart"
            ), //	计划结束时间
            duration: res.data[0].duration, //	持续时间
          },
        ];
        this.$emit("getbtnJurisdiction");
        this.$emit("serviceAppoSuccess", areaSuccess, "distribution");
        // 分配成功刷新甘特图
        this.$emit(
          "locationGrantt",
          this.setStartEndTime(res.data[0].schedendtime, "locationtime")
        );
      } else {
        this.distributionLoading = false;
        this.$message.error(this.$i18n.t("c1732"));
      }
    },
    // 派遣接口
    serviceAppoDispatch() {
      this.AppoDispatchLoading = true;
      let params = [];
      let areaSuccessData = [];
      // 获取传参数据
      let areaSuccess = {};
      this.formData.formServiceResourcesData.map((formItem) => {
        if (
          formItem.formServiceResourid &&
          formItem.formServiceResourtime &&
          formItem.formStartTime
        ) {
          params.push({
            serviceTerritoryId: this.radioServiceArea, //服务区域id
            serviceResource: formItem.formServiceResourid, //服务资源id
            serviceAppointmentId: this.serviceappointmentId, //	服务预约id
            startTime: formItem.formStartTime, //	计划开始时间
            endTime: formItem.formEndTime, //	计划结束时间
            estimatedTravelTime: this.getdirveHours(
              formItem.estimatedTravelTime
            ), //预计出行时间（小时）
            actualDrivingDistance: formItem?.actualDrivingDistance, //预计的驾车距离
            policyId: this.strategyValue, //计划策略id
          });
          // 派遣成功后回显数据
          areaSuccess = {
            region: this.serviceAreaData[this.currentServer].name, //服务区域名称
            startTime: formItem.formStartTime, //	计划开始时间
            endTime: formItem.formEndTime, //	计划结束时间
            duration: formItem.formDurationTime, //	持续时间
            resources: formItem.formServiceResourName, //服务资源名称
          };
          areaSuccessData.push(areaSuccess);
        }
      });
      serviceAppoDispatch(params)
        .then((res) => {
          if (res.result && !res.data) {
            this.AppoDispatchLoading = false;
            this.$message.success(
              this.$i18n.t("label.service.appointmentList.send.successfully")
            );
            this.$emit("serviceAppoSuccess", areaSuccessData, "dispatch");
            // 派遣成功后清除当前选中的数据；
            this.formData = {
              formServiceAreaId: "",
              isCheckServiceArea: "",
              formCheckServiceResourid: [],
              formServiceResourcesData: [],
            };
            this.radioServiceArea = "";
          } else {
            this.showBatchDispatch = 0;
            this.AppoDispatchLoading = false;
            this.$message.error("已经派遣给" + res.data + "不能重复派遣");
          }
        })
        .catch(() => {
          this.showBatchDispatch = 0;
          this.AppoDispatchLoading = false;
        });
    },
    /**
     * 根据计划策略选出预约所需服务区域列表
     * @param {Object} data:服务预约数据
     * @param {String} schedulingPolicyId:策略id
     * @param {String} status:状态：判断是否为滚动加载
     */
    recommendCandidatesST(data, schedulingPolicyId, status) {
      this.$emit("setNoChange", true);

      if (!status) {
        this.currentServer = ""; //切换策略清楚当前选中下标
        this.radioServiceArea = "";
        this.pageNum = 1;
        this.pageSize = 20;
        this.reconmendLoading = true;
      } else {
        this.reconmendMoreLoading = true;
      }
      this.serviceappointmentId = data.id; //服务预约id
      if (!schedulingPolicyId) {
        this.serviceterritoryId = data.serviceterritoryid; //服务区域id
      } else {
        this.serviceterritoryId = schedulingPolicyId; //服务区域id
      }
      let params = {
        serviceappointmentId: data.id, //服务预约id
        schedulingPolicyId: schedulingPolicyId || "", //计划策略id
        pageNum: this.pageNum.toString(), //页码
        pageSize: this.pageSize.toString(), //每页数量
      };
      recommendCandidatesST(params)
        .then((res) => {
          if (res.result) {
            // 页数>1时，对服务预约id和策略id赋值
            this.appointmentLoadMoreId = data;
            this.PolicyLoadMoreId = schedulingPolicyId;
            this.maxPageNum = res?.data?.totalPage;
            // zysx,zdcx,zyzg后会添加随机数，去掉数字
            res.data?.stList &&
              res.data.stList.map((item) => {
                let zyzg = [];
                for (var key in item) {
                  if (key.indexOf("zysx") > -1) {
                    item.zysx = item[key];
                  } else if (key.indexOf("zdcx") > -1) {
                    item.zdcx = item[key];
                  } else if (key.indexOf("zyzg") > -1) {
                    zyzg.push(item[key]);
                  }
                }
                item.zyzg = zyzg;
              });
            // 先按总分排序，总分是0的话，就在按目标里有无score排序，总分是0且目标没有score放在最后
            if (status == "loadmore") {
              this.serviceAreaData = this.serviceAreaData.concat(
                res.data.stList
              );
              this.reconmendMoreLoading = false;
            } else {
              this.serviceAreaData = res.data.stList;
              // 初始化数据
              this.formData = {
                formServiceAreaId: "",
                isCheckServiceArea: "",
                formCheckServiceResourid: [],
                formServiceResourcesData: [],
              };
              this.showBatchDispatch = 0;
              this.reconmendLoading = false;
            }
            //score:保留两位小数
            this.serviceAreaData &&
              this.serviceAreaData.map((item) => {
                item.score = item.score ? parseInt(item.score * 100) / 100 : 0;
              });
            this.$emit("setNoChange", false);
          }
        })
        .catch(() => {
          this.reconmendLoading = false;
          this.reconmendMoreLoading = false;
          this.showBatchDispatch = 0;
          this.$emit("setNoChange", false);
        });
    },
    // 星期方法
    getWeek(days) {
      switch (days) {
        case 1:
          days = this.$i18n.t("monday");
          break;
        case 2:
          days = this.$i18n.t("tuesday");
          break;
        case 3:
          days = this.$i18n.t("wednesday");
          break;
        case 4:
          days = this.$i18n.t("thursday");
          break;

        case 5:
          days = this.$i18n.t("friday");
          break;

        case 6:
          days = this.$i18n.t("saturday");
          break;

        case 0:
          days = this.$i18n.t("sunday");
          break;
      }
      return days;
    },
    // 获取区域下候选资源
    recommendCandidates() {
      this.candidateData = [];
      let params = {
        serviceappointmentId: this.serviceappointmentId, //服务预约id
        serviceterritoryId: this.radioServiceArea, //服务区域id
        schedulingPolicyId: this.strategyValue, //下拉列表中计划策略id
      };
      recommendCandidates(params)
        .then((res) => {
          res.data.map((item) => {
            if (item.srTimeList) {
              this.$set(item, "newSrTimeList", []);
              item.srTimeList.map((sritem) => {
                let newTimeList = this.getEditTime(sritem, "day");
                item.newSrTimeList.push(newTimeList);
              });
            }
            item.total = item.srTimeList?.length || "0";
            item.score = item.score > 0 ? item.score : "0";
          });
          // 服务预约分数排序从高到低
          res.data.sort(function (a, b) {
            return a.score < b.score ? 1 : -1;
          });
          // 服务资源排序
          res.data.sort((a, b) => {
            let dateOne = Number(a.score || 0);
            let dateTow = Number(b.score || 0);
            if (dateOne === dateTow) {
              return 0;
            } else if (dateTow - dateOne > 0) {
              return 1;
            } else {
              return -1;
            }
          });
          this.candidateData = res.data;
          this.candidateData.map((item) => {
            for (var key in item) {
              if (key.indexOf("zdcx") > -1) {
                item.zdcx = item[key];
              } else if (key.indexOf("zysx") > -1) {
                item.zysx = item[key];
              }
            }
          });
          this.formData.formCheckServiceResourid = [];
          this.formData.formServiceResourcesData = [];
          res.data.map((resitem) => {
            if (resitem) {
              this.formData.formServiceResourcesData.push({
                formServiceResourid: resitem.srid, //服务资源id
                formServiceResourtime: "", //派工时间
                formStartTime: "", //开始时间
                formEndTime: "", //开始时间
                formDurationTime: "", //开始时间
                formServiceResourName: resitem.srname, //服务资源名称
                estimatedTravelTime: resitem?.zdcx?.duration, //预计出行时间（小时）
                actualDrivingDistance: resitem?.zdcx?.distance, //预计的驾车距离
              });
            }
          });
          this.isShowSkeleton = false;
        })
        .catch(() => {
          this.isShowSkeleton = false;
        });
    },
    // 处理开始时间：2022-10-11 13:33
    // 处理结束时间：12:23
    // 处理标准时间：2022-10-09
    setStartEndTime(time, status) {
      if (status == "planstart" || status == "locationtime") {
        time = new Date(time);
      }
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let hour = time.getHours();
      let minutes = time.getMinutes();
      let week = this.getWeek(time.getDay());
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (status == "start") {
        let startTime =
          year +
          this.$i18n.t("year") +
          month +
          this.$i18n.t("label.login.access.month") +
          day +
          this.$i18n.t("day") +
          week +
          " " +
          hour +
          ":" +
          minutes;
        return startTime;
      } else if (status == "diff" || status == "locationtime") {
        let diffTime = year + "-" + month + "-" + day;
        return diffTime;
      } else if (status == "planstart") {
        let planTime =
          year + "-" + month + "-" + day + " " + hour + ":" + minutes;
        return planTime;
      } else {
        let endTime = hour + ":" + minutes;
        return endTime;
      }
    },
    // 单选按钮触发事件,单选修改派工时段，修改开始时间和结束时间
    modifyTimeRadio(index1, index2) {
      if (
        this.formData.formServiceResourcesData[index2].formServiceResourtime
      ) {
        let nowdate =
          this.formData.formServiceResourcesData[index2].formServiceResourtime;
        let index = nowdate.indexOf(",");
        let date = nowdate.substring(index + 1, nowdate.length);
        this.formData.formServiceResourcesData[index2].formStartTime =
          this.getEditTime(date).startDate;
        this.formData.formServiceResourcesData[index2].formEndTime =
          this.getEditTime(date).endDate;
        this.formData.formServiceResourcesData[index2].formDurationTime =
          this.getEditTime(date).duration;
      }
    },
    //获取人员头像
    getHeadImg(id) {
      return `${
        this.imgUrlCookie
      }/chatterzone.action?m=showPhoto&userid=${id}&binding=${
        this.tokenCookie
      }&dandan=${Math.random()}`;
    },
    //  选择候选人查看空档时间列表
    selectcandidate(item, index, el) {
      if (el.target.tagName === "INPUT") return;
      if (this.chooseCandidateActive === index) {
        this.TimeRadio = "";
        this.chooseCandidateActive = "";
        this.chooseServeActive = "";
      } else {
        this.TimeRadio = "";
        this.chooseCandidateActive = index;
        this.chooseServeActive = item.id;
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.candidateNeutral {
  height: calc(100% - 93px);
  position: relative;
}
.candidateNeutral1 {
  height: calc(100% - 141px);
  position: relative;
}
.candidateNeutralBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: #f8f9fb;
  .candidate-item {
    margin-bottom: 10px;
    box-shadow: 0px 3px 8px -2px rgb(223 224 226 / 60%);
  }
}
.computed-height {
  height: calc(100% - 78px);
}
.no-databox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .no-loading {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  ::v-deep .el-loading-spinner .circular {
    width: 20px;
    height: 20px;
  }
  ::v-deep .el-loading-spinner {
    top: 50%;
    margin-top: -13px;
    width: 100%;
    text-align: center;
    position: absolute;
  }
  .load-data {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: #999999;
    padding: 10px 0;
    background: #f8f9fb;
  }
}
.no-data {
  display: flex;
  flex-direction: column;
  .no-data-svg {
    width: 100%;
    padding: 0 25%;
    height: 180px;
    margin-top: 50px;
    margin-bottom: 14px;
  }
  svg {
    width: 100%;
    height: 180px;
  }
  .no-data-title {
    margin: 0 auto;
    text-align: center;
    color: #999999;
  }
}

.moreDispatch {
  height: 78px;
  position: absolute;
  bottom: 0;
  background: #ffffff;
  line-height: 78px;
  text-align: center;
  width: 100%;
}

::v-deep .el-radio-group {
  width: 100%;
}
.candidateList:first-child {
  border-top: 1px solid #dddfe8;
}
.candidateList {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dddfe8;
  .person {
    display: flex;
    align-items: center;
    ::v-deep .el-checkbox__label {
      display: flex !important;
      align-items: center !important;
      align-content: center !important;
      width: 100%;
    }
    .personImg {
      margin-right: 8px;
      width: 30px;
      height: 30px;
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }
  }
  .timeBox {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 10px 20px 10px 46px;
    border-top: 1px solid #dddfe8;
    justify-content: space-between;
    background: rgba(0, 109, 204, 0.03);
    .time-item {
      display: flex;
      align-content: center;
      align-items: center;
      ::v-deep .el-radio {
        margin-right: 0;
      }
      .diff-time {
        display: flex;
        align-content: center;
        align-items: center;
        font-size: 12px;
        color: #1d2033;
      }
      .duration-red {
        text-align: center;
        box-sizing: content-box;
        border-bottom: 1px solid #979797;
        color: #d62940;
        width: 30px;
        margin: 0 2px;
        text-align: center;
      }
    }
    .plan {
      width: 50px;
      height: 20px;
      background: #006dcc;
      color: #fff;
    }
    .level {
      width: 56px;
      height: 20px;
      background: #fa6400;
      color: #fff;
    }
  }

  .timemouse {
    cursor: no-drop !important;
  }
}
.person-name {
  display: flex !important;
  align-items: center !important;
  align-content: center !important;
  padding: 5px 20px 5px 20px;
  width: 100%;
  background: #f7f7f7;
}
.areaList {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 3px 8px -2px rgba(223, 224, 226, 0.6);
  .area-header {
    flex-direction: column;
    display: flex;
    align-items: center;
    align-content: center;
    background: #ffffff;
    justify-content: space-between;
    width: 100%;
    .area-raido {
      display: flex;
      align-items: center;
      align-content: center;
      color: #1d2033;
      font-size: 14px;
      flex: 1;
      max-width: calc(100% - 60px);
      height: 100%;
      .serverarea-name {
        color: #006dcc;
      }
      .serverarea-namemax {
        max-width: 45%;
        overflow: hidden;
        text-overflow: ellipsis; //文本溢出显示省略号
        white-space: nowrap;
        cursor: pointer;
      }
      .area-name {
        width: calc(100% - 60px);
      }
      ::v-deep .el-radio__label {
        padding-left: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        font-size: 14px;
        color: #1d2033;
      }
    }

    .area-btn {
      display: flex;
      align-items: center;
      align-content: center;
      min-width: 60px;
    }
  }

  .header-box {
    display: flex;
    height: 40px;
    align-items: center;
    align-content: center;
    width: calc(100% - 32px);
    border-bottom: 1px dashed #dfe0e2;
    cursor: pointer;
  }
  .distance-box {
    width: 100%;
    display: flex;
    padding: 0 16px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .infor-distance-box {
      display: flex;
      flex-wrap: wrap;
    }
    .infor-distance {
      font-size: 12px;
      color: #576c9c;
      background: #fdfdfd;
      border-radius: 10px;
      border: 1px solid #f1f1f1;
      margin: 12px 12px 0 0;
      height: 26px;
      line-height: 24px;
      padding: 0 8px;
      white-space: nowrap;
    }
  }

  .crux-infor {
    display: flex;
    width: 100%;
    font-size: 14px;
    padding: 0px 6px 0px 36px;
    flex-wrap: wrap;
    position: relative;

    .infor-box {
      display: flex;
      width: calc(50% - 10px);
      padding-bottom: 12px;

      .infor-title {
        color: #aaabaf;
        margin-right: 12px;
        max-width: 100px;
      }

      .infor-value {
        color: #20232b;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex: 1;
      }

      .infor-svg {
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }

    .see-more {
      font-size: 14px;
      position: absolute;
      top: 5px;
      right: 0px;
      padding: 20px 30px;
      background: linear-gradient(
        to left,
        rgb(255 255 255 / 50%),
        rgb(255 255 255 / 50%)
      );
    }

    .see-more:hover {
      color: #006dcc;
    }
  }

  .area-active {
    // background: #e0eef9;
  }
}

.no-candidate {
  font-size: 12px;
  color: #999;
  text-align: center;
  height: 30px;
  line-height: 30px;
}
.crux-svg {
  width: calc(100% - 72px);
  height: 17px;
  margin: 0 36px;
  text-align: center;
  .transformsvg {
    transform: rotate(180deg);
  }
  svg {
    width: 40px;
    height: 16px;
    text-align: center;
  }
}
.crux-svg-border {
  // border-top: 1px solid #e1e1e1;
}

.personInfor {
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
}

.personName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 40%;
  font-size: 14px;
  color: #20232b;
}

.personNum {
  font-size: 10px;
  color: #9fa3b4;
}

.candidate-circle {
  border-radius: 10px;
  color: #eb6f1c;
  margin: 0 10px 0 20px;
  align-content: center;
  display: flex;
  align-items: center;
  height: 26px;
  min-width: 45px;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}
.candidate-nobold {
  font-weight: normal;
}

.personInfor-title {
  display: flex;
  align-items: center;
  height: 26px;

  .assigned {
    font-weight: bold;
    color: #d62940;
    line-height: 16px;
    font-size: 12px;
    background: #ffe8eb;
    border-radius: 3px;
    min-width: 44px;
    text-align: center;
    height: 20px;
    line-height: 20px;
    margin-bottom: 30px;
    box-shadow: 2px 2px 6px 0px rgb(73 25 25 / 12%);
    border-radius: 8px 8px 8px 0px;
  }
  .candidate-circle {
    margin: 0 10px 0 10px;
  }
}
.first-choose {
  color: #006dcc;
  box-shadow: 0px 1px 0px 0px rgba(223, 224, 226, 0.5);
  font-size: 12px;
  min-width: 44px;
  text-align: center;
  height: 26px;
  line-height: 24px;
  background: #e8f4ff;
  border-radius: 10px;
  border: 1px solid #006dcc;
}
.first-distance {
  color: #006dcc;
  font-size: 12px;
  min-width: 44px;
  text-align: center;
  line-height: 18px;
  background: #fdfdfd;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  margin-right: 14px;
  padding: 2px 5px;
}
.distance-red {
  color: #cc3359;
}

.totalselect {
  font-size: 10px;
  color: #aaabaf;
}

::v-deep .el-radio {
  margin-right: 0;
  display: flex;
  min-width: 160px;
  align-items: center;
  align-content: center;
  color: #1d2033;
  font-size: 14px;
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #006dcc;
  font-size: 14px;
}
::v-deep .is-checked {
  .diff-time {
    color: #006dcc !important;
  }
  .el-radio__label {
    color: #006dcc !important;
  }
}

::v-deep .el-radio__inner {
  width: 16px;
  height: 16px;
}
</style>