<template>
  <div class="appointment-list">
    <!-- 表头 -->
    <div class="table table-title">
      <div class="color"></div>
      <div
        style="width: 20px; display: inline-block"
        v-show="isGnattAndMap !== 'first'"
      ></div>
      <el-checkbox
        v-show="tableTitle.length > 0 && isGnattAndMap === 'first'"
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
      ></el-checkbox>
      <div
        class="item"
        v-for="(item, index) in tableTitle"
        :key="index"
        :style="{ width: `calc((100% - 30px) / ${tableTitle.length})` }"
      >
        <div class="table-name" @click="getBoxSort(item, '', index)">
          <div class="name-box">
            {{ item.fieldname }}
          </div>
          <div class="asc-box">
            <!-- 选中升序 -->
            <svg
              class="icon ascicon"
              v-if="status == 'asc' && fieldSortIndex == index"
              aria-hidden="true"
            >
              <use href="#icon-Click-drop-down"></use>
            </svg>
            <!-- 没有排序 -->
            <svg
              class="icon ascicon"
              v-else
              @click.stop="sort(item, 'asc', index)"
              aria-hidden="true"
            >
              <use href="#icon-drop-down1"></use>
            </svg>
            <!-- 选中降序 -->
            <svg
              class="icon descicon"
              v-if="status == 'desc' && fieldSortIndex == index"
              aria-hidden="true"
            >
              <use href="#icon-Click-drop-down"></use>
            </svg>
            <svg
              class="icon descicon"
              v-else
              @click.stop="sort(item, 'desc', index)"
              aria-hidden="true"
            >
              <use href="#icon-drop-down1"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <!-- 表身 -->
    <div v-loading="listDataLoading" class="bodys">
      <div class="no-data" v-show="tableBodyList.length == 0">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-system_tips2_ic"></use>
        </svg>
        <div class="word">
          {{ $t("label.service.appointmentList.service.empty") }}
        </div>
      </div>
      <div
        class="body-small-box scrollBoxUnique"
        v-show="tableBodyList.length > 0"
      >
        <div class="body-long-box" id="external-events">
          <!-- 甘特图对应的服务预约 -->
          <div
            class="body-box"
            v-for="(body, index) in tableBodyList"
            :key="index"
            v-show="isGnattAndMap === 'first'"
          >
            <el-checkbox-group
              v-model="checkedList"
              @change="handleCheckedListChange"
            >
              <div
                class="table table-body"
                :style="{
                  background: ` linear-gradient(45deg, ${body.color} -80%, #FFFFFF 67%)`,
                }"
                @click="selectDetail(index, body)"
                @mouseover="over(body)"
                @mousedown="down"
              >
                <div class="color" :style="{ background: body.color }"></div>
                <el-checkbox :label="body"> </el-checkbox>
                <!-- 拖拽暂时去掉 -->
                <!-- <div
                  class="item"
                  :class="
                    body.codeType === 'inprogress' ||
                    body.codeType === 'notprocessed'
                      ? 'draggable'
                      : ''
                  " -->
                <div
                  class="item"
                  v-for="(item, index1) in tableTitle"
                  :key="index1"
                  :style="{
                    width: `calc((100% - 30px) / ${tableTitle.length})`,
                  }"
                >
                  {{ body[getName(item.fieldtype, item.apiname)] }}
                </div>
              </div>
              <div class="table-item-detail" v-show="index == currentIndex">
                <div v-loading="miniDetailLoading">
                  <div
                    class="item"
                    v-for="(item, index) in fieldNameList"
                    :key="index"
                  >
                    <div class="name">{{ item.labelname }}</div>
                    <div
                      class="value"
                      :class="{
                        active: isJump(
                          item.fieldtype,
                          item.fieldname,
                          fieldValueList
                        ),
                      }"
                      @click="
                        detailTurn(
                          item.fieldtype,
                          item.fieldname,
                          fieldValueList
                        )
                      "
                    >
                      {{
                        fieldValueList[
                          getName(item.fieldtype, item.fieldname)
                        ] == "false" ||
                        fieldValueList[
                          getName(item.fieldtype, item.fieldname)
                        ] == "true"
                          ? fieldValueList[
                              getName(item.fieldtype, item.fieldname)
                            ] == "false"
                            ? $t("label.service.appointmentList.thisOption")
                            : $t("label.service.appointmentList.checkThe")
                          : fieldValueList[
                              getName(item.fieldtype, item.fieldname)
                            ]
                      }}
                    </div>
                  </div>
                  <!-- <div class="item">
                  <div class="name">到期日期</div>
                  <div class="value">2021/1/26</div>
                </div>
                <div class="item">
                  <div class="name">名称</div>
                  <div class="value">值</div>
                </div> -->
                  <div class="func-button">
                    <!-- 候选人 -->
                    <!-- codevalue为无，已分配，已计划，已派遣，进行中的时候出现候选人按钮 #A5E2D6 
                         codevalue一直返回中文不会有多语言的问题-->
                    <div
                      v-if="serviceAppointmentStatus == '1'"
                      class="button"
                      @click="
                        fnThrottle('getOrderReceiving', 500, 1000, body)()
                      "
                    >
                      <svg class="icon" aria-hidden="true">
                        <use href="#icon-jiedan"></use>
                      </svg>
                      {{ $t("c2321") }}
                    </div>
                    <div
                      v-if="serviceAppointmentStatus == '1'"
                      class="button"
                      @click="getutOrderReject(body)"
                    >
                      <svg class="icon" aria-hidden="true">
                        <use href="#icon-judan"></use>
                      </svg>
                      {{ $t("c2322") }}
                    </div>
                    <div
                      class="button"
                      v-show="btnForHouxuan"
                      @click="excutePolicy(body)"
                    >
                      <svg class="icon" aria-hidden="true">
                        <use href="#icon-a-DispatchDesk-Candidates"></use>
                      </svg>
                      {{ $t("label.service.gantt.candidate") }}
                    </div>
                    <!-- 取消分配-->
                    <div
                      v-show="btnForCancelAssign"
                      @click="fnThrottle('cancelAssign', 500, 1000, body)()"
                      class="button"
                    >
                      <svg class="icon" aria-hidden="true">
                        <use href="#icon-a-DispatchDesk-Cancel"></use>
                      </svg>
                      {{ $t("c1974") }}
                    </div>
                    <!-- 取消计划 -->
                    <div
                      v-show="btnForCancelPlan"
                      class="button"
                      @click="fnThrottle('cancelPlan', 500, 1000, body)()"
                      :class="{
                        buttonActive:
                          cancelDeliverData.codevalue !=
                          $t('label.projectManagement.planned')
                            ? true
                            : false,
                      }"
                    >
                      <svg class="icon" aria-hidden="true">
                        <use href="#icon-system_refresh_ic"></use>
                      </svg>
                      {{ $t("label.service.appointmentList.cancelThePlan") }}
                    </div>

                    <!-- 取消派遣 -->
                    <div
                      v-show="btnForCancelDispatch"
                      @click="fnThrottle('cancelDispatch', 500, 1000, body)()"
                      class="button"
                    >
                      <svg class="icon" aria-hidden="true">
                        <use href="#icon-a-DispatchDesk-Cancel"></use>
                      </svg>
                      {{ $t("c911") }}
                    </div>
                    <!-- 甘特图 -->
                    <div
                      v-if="btnForGaatt"
                      class="button"
                      @click="locationInGannt()"
                    >
                      <svg class="icon" aria-hidden="true">
                        <use href="#icon-a-DispatchDesk-GanttChart"></use>
                      </svg>
                      <span>
                        {{ $t("label.service.gantt.locationToGantt") }}
                      </span>
                    </div>

                    <!-- <div
                      class="button"
                      @click="dispatch()"
                      :class="{
                        buttonActive:
                          cancelDeliverData.color != '#A0E0FD' ? true : false
                      }"
                    >
                      派遣
                    </div> -->
                    <!-- <div class="button">
                    编辑
                  </div> -->
                    <!-- <div class="button finally" @click.stop="showMoreFunc">
              </div> -->
                  </div>
                </div>
              </div>
            </el-checkbox-group>
          </div>

          <!-- 地图对应的服务预约 -->
          <div
            class="body-box"
            v-for="(body, index) in tableBodyList"
            :key="'w' + index"
            v-show="isGnattAndMap !== 'first'"
          >
            <!-- <el-radio-group
              v-model="checkedListRadio"
              @change="handleCheckedListChange"
            > -->
            <div
              class="table table-body"
              :style="{
                background: ` linear-gradient(45deg, ${body.color} -80%, #FFFFFF 67%)`,
              }"
              @click="selectDetail(index, body)"
              @mouseover="over(body)"
              @mousedown="down"
            >
              <div class="color" :style="{ background: body.color }"></div>
              <el-radio
                v-model="checkedListRadio"
                :label="body"
                @change="handleCheckedListRadio"
                >.</el-radio
              >
              <!-- 暂时禁用拖拽 -->
              <!-- <div
                class="item"
                :class="body.codevalue == '无' ? 'draggable' : ''" -->
              <div
                class="item"
                v-for="(item, index1) in body"
                v-show="
                  index1 != 'color' &&
                  index1 != 'id' &&
                  index1 != 'ganttName' &&
                  index1 != 'durationIns' &&
                  index1 != 'serviceterritoryid' &&
                  index1 != 'address' &&
                  index1 != 'schedstarttime' &&
                  index1 != 'schedendtime' &&
                  index1 != 'codevalue' &&
                  index1 != 'codeType' &&
                  index1 != 'schedulingpolicyid'
                "
                :key="index1"
                :style="{
                  width: `calc((100% - 30px) / ${tableTitle.length})`,
                }"
              >
                {{ item }}
              </div>
            </div>
            <div class="table-item-detail" v-show="index == currentIndex">
              <div v-loading="miniDetailLoading">
                <div
                  class="item"
                  v-for="(item, index) in fieldNameList"
                  :key="index"
                >
                  <div class="name">{{ item.labelname }}</div>
                  <div
                    class="value"
                    :class="{
                      active: isJump(
                        item.fieldtype,
                        item.fieldname,
                        fieldValueList
                      ),
                    }"
                    @click="
                      detailTurn(item.fieldtype, item.fieldname, fieldValueList)
                    "
                  >
                    {{
                      fieldValueList[getName(item.fieldtype, item.fieldname)] ==
                        "false" ||
                      fieldValueList[getName(item.fieldtype, item.fieldname)] ==
                        "true"
                        ? fieldValueList[
                            getName(item.fieldtype, item.fieldname)
                          ] == "false"
                          ? $t("label.service.appointmentList.thisOption")
                          : $t("label.service.appointmentList.checkThe")
                        : fieldValueList[
                            getName(item.fieldtype, item.fieldname)
                          ]
                    }}
                  </div>
                </div>
                <!-- <div class="item">
                    <div class="name">到期日期</div>
                    <div class="value">2021/1/26</div>
                  </div>
                  <div class="item">
                    <div class="name">名称</div>
                    <div class="value">值</div>
                  </div> -->
                <div class="func-button">
                  <!-- <div class="button first">
                </div> -->
                  <div
                    v-show="
                      cancelDeliverData.codevalue ==
                      $t('label.projectManagement.planned')
                    "
                    class="button first"
                    @click="cancelDeliver()"
                    :class="{
                      buttonActive:
                        cancelDeliverData.codevalue !=
                        $t('label.projectManagement.planned')
                          ? true
                          : false,
                    }"
                  >
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-system_refresh_ic"></use>
                    </svg>
                    {{ $t("label.service.appointmentList.cancelThePlan") }}
                  </div>
                  <!-- <div
                    class="button"
                    @click="dispatch()"
                    :class="{
                      buttonActive:
                        cancelDeliverData.color != '#A0E0FD' ? true : false
                    }"
                  >
                  </div> -->
                  <!-- <div class="button">
                    </div> -->
                  <!-- <div class="button finally" @click.stop="showMoreFunc">
                </div> -->
                </div>
              </div>
            </div>
            <!-- </el-radio-group> -->
          </div>
        </div>
      </div>
    </div>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :pager-count="5"
      :page-sizes="[10, 30, 50, 100]"
      :page-size="50"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalCount"
    ></el-pagination>
    <el-dialog
      :title="$t('c1086')"
      :close-on-click-modal="false"
      :visible.sync="candidateShow"
      width="500px"
    >
      <div class="celue">
        <div class="celue-item">
          <div class="celue-item-label">
            {{ $t("label.mobile.phone.time.range") }}
          </div>
          <div class="celue-item-option">
            <el-date-picker
              v-model="startDateRange"
              type="date"
              :placeholder="$t('c1356')"
            >
            </el-date-picker>
            <div class="centerdiv">
              {{ $t("label.forecast.customforecast.submit.zhi") }}
            </div>
            <el-date-picker
              v-model="endDateRange"
              type="date"
              :placeholder="$t('c1357')"
            >
            </el-date-picker>
          </div>
        </div>
        <el-form
          ref="celueform"
          :model="celueformData"
          :rules="celueformRules"
          label-width="auto"
          size="small"
        >
          <el-form-item prop="celueValue">
            <div class="celue-item-label">{{ $t("c1087") }}</div>
            <el-select
              style="width: 100%; padding: 0"
              v-model="celueformData.celueValue"
              placeholder="请选择"
              :disabled="celueReadonly"
            >
              <el-option
                v-for="item in celueOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!-- <div class="celue-item">
          <div class="celue-item-label">使用以下策略</div>
          <div class="celue-item-option">
            <el-select
              style="width: 100%; padding: 0"
              v-model="celueValue"
              placeholder="请选择"
            >
              <el-option
                v-for="item in celueOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div> -->
        <!-- 推荐策略规则暂不使用注释 -->
        <!-- <div class="scrollBoxUnique celue-list">
          <div v-for="(item, index) in recommendRuleData" :key="index">
            <div class="celue-objectname">{{ item.ruleObject }}</div>
            <div
              class="celue-rule"
              v-for="(ruleitem, ruleindex) in item.data"
              :key="ruleindex"
            >
              <div class="celue-rule-item">
                <div class="celue-number">{{ Number(ruleindex) + 1 }}</div>
                <div class="celue-rule-title">{{ ruleitem.ruleName }}</div>
                <div class="celue-rule-content">
                  {{ ruleitem.ruleNameValue }}
                </div>
              </div>
              <div class="celue-rule-item">
                <div class="celue-div"></div>
                <div class="celue-rule-title">{{ ruleitem.ruleType }}</div>
                <div class="celue-rule-content">
                  {{ ruleitem.ruleTypeValue }}
                </div>
              </div>
              <div class="celue-rule-item">
                <div class="celue-div"></div>
                <div class="celue-rule-title">{{ ruleitem.ruleContent }}</div>
                <div class="celue-rule-content">
                  {{ ruleitem.ruleContentValue }}
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="candidateShow = false">取 消</el-button>
        <el-button type="primary" @click="saveCanditate">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 取消计划 -->
    <el-dialog
      :title="$t('label.service.appointmentList.cancelThePlan')"
      :close-on-click-modal="false"
      :visible.sync="cancelPlanDialog"
      width="600px"
    >
      <div class="cancel-dispatch">
        <div class="cancel-dispatch-tittle">是否确认取消计划？</div>
        <div class="cancel-dispatch-box">
          <el-checkbox-group v-model="checkCancelPlanList">
            <el-checkbox
              v-for="item in listForCancelPlan"
              :key="item.id"
              :label="item.id"
            >
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelPlanDialog = false">取 消</el-button>
        <el-button type="primary" @click="saveCancelPlan">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 取消派遣弹窗 -->
    <el-dialog
      :title="$t('c911')"
      :close-on-click-modal="false"
      :visible.sync="cancelDispatchDialog"
      width="600px"
    >
      <div class="cancel-dispatch">
        <div class="cancel-dispatch-tittle">是否确认取消派遣？</div>
        <div class="cancel-dispatch-box">
          <el-checkbox-group v-model="checkCancelDispatchList">
            <el-checkbox
              v-for="item in listForCancelDispatch"
              :key="item.id"
              :label="item.id"
            >
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDispatchDialog = false">取 消</el-button>
        <el-button type="primary" @click="saveCancelDispatch">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 拒单原因弹框 -->
    <el-dialog
      :title="$t('c2324')"
      width="600px"
      top="5%"
      style="padding: 20px 30px"
      :visible.sync="utOrderRejectFlag"
      append-to-body
      :close-on-click-modal="false"
      @close="cancelOrderRejectFlag"
    >
      <div class="rejection-box">
        <!-- 拒单原因 -->
        <el-select
          popper-class="reason-rejection"
          v-model="reasonRejectionchoose"
          placeholder="请选择"
        >
          <el-option
            v-for="item in reasonRejectionData"
            :key="item.codevalue"
            :label="item.codevaluelang"
            :value="item.codevalue"
          >
          </el-option>
        </el-select>
        <el-input
          popper-class="reason-rejectioninput"
          type="textarea"
          :placeholder="$t('c2325')"
          v-model="reasonRejectionValue"
          maxlength="500"
          show-word-limit
        >
        </el-input>
      </div>
      <span slot="footer">
        <el-button
          @click="cancelOrderRejectFlag"
          :loading="utOrderRejectLoading"
          size="mini"
        >
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          type="primary"
          :loading="utOrderRejectLoading"
          @click="utOrderReject"
          size="mini"
        >
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getServiceAppointmentList,
  getAppointmentChecked,
  save,
  getDetail,
  cancelServiceAppo,
  excutePolicy,
  updateServiceApp,
  getServieceResourceByAppo,
  getObjectInfo,
  cancelServiceAreaAssignment,
  cancelServiceAppoDispatch,
  utOrderReceive,
  oRButton,
  utOrderReject,
  getRejectReason,
  getAppoStatusLegends,
} from "../api";
import { owneridIsJump } from "@/config/commonMehods/everyoneJump.js"; // 判断所有人是否跳转权限方法
import { formatDate } from "@/components/cloudcc-gantt/utils/date";

export default {
  props: {
    resourceId: {
      type: String,
      default: "",
    },
    dateValue: {
      type: String,
      default: "",
    },
    formType: {
      type: String,
      default: "",
    },
    treeString: {
      type: String,
      default: "",
    },
    inputValue: {
      type: String,
      default: "",
    },
    isGnattAndMap: {
      type: String,
      default: "",
    },
  },
  name: "appointmentList",
  data() {
    return {
      pickerMinDate: null,
      pickerMaxDate: null,
      day30: 30 * 24 * 3600 * 1000,
      // 日期使用
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          // 当有开始时间和结束时间时，取消禁选，可以重新选择开始时间
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
            this.pickerMaxDate = maxDate;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (
              time.getTime() < this.pickerMinDate ||
              time.getTime() > this.pickerMinDate + this.day30
            );
          }
          return false;
        },
      },
      // 结束日期前30天，开始日期后30天
      // pickerOptions: {
      //   disabledDate(time) {
      //     let three = 24 * 3600 * 30 * 1000;
      //     return (
      //       time.getTime() < Date.now() - 8.64e7 ||
      //       time.getTime() > Date.now() + three
      //     );
      //   },
      // },
      //需要排序字段
      sortcode: "",
      //需要排序状态
      status: "",
      //状态排序
      isStatusSort: true,
      //是否关联服务预约
      checkedRegional: false,
      //预约编号排序
      isTopSort: true,
      dateTime: "",
      //加载状态
      listDataLoading: false,
      //显示更多列表功能
      isShowMoreFunc: false,
      //当前选中列表
      currentIndex: null,
      //选中的列表
      checkedList: [],
      //单选中的列表
      checkedListRadio: {},
      //当前页数
      currentPage: 1,
      //每页数量
      pageNumber: 50,
      checkAll: false,
      isIndeterminate: false,
      tableTitle: [],
      // tableBodyList: [{ id: '1', count: 'SA-001', data: '2021-1-26', color: '#A0E0FD' }, { id: '1', count: 'SA-001', data: '2021-1-26', color: '#FFB985' }, { id: '1', count: 'SA-001', data: '2021-1-26', color: '#F7A7A7' }, { id: '1', count: 'SA-001', data: '2021-1-26', color: '#A5E2D6' }],
      tableBodyList: [],
      //当前鼠标移入列表数据
      currentData: {},
      //数据总数
      totalCount: 0,
      //最大页数
      maxPage: 0,
      fieldNameList: [], //悬浮气泡详细信息名称
      fieldValueList: {}, //悬浮气泡详细信息值,
      //判断是否可取消派遣的数据
      cancelDeliverData: "",
      miniDetailLoading: true, // 列表详情小卡片loading
      // 候选人策略弹窗
      candidateShow: false,
      // 时间范围
      startDateRange: "", //开始日期
      endDateRange: "", //结束日期
      // 策略
      celueformData: {
        // 策略选项
        celueValue: "",
      },
      serviceAppointmentId: "", //服务预约id
      // 策略校验
      celueformRules: {
        celueValue: [
          {
            required: true,
            message: "请选择策略",
            trigger: "blur",
          },
        ],
      },
      celueOptions: [],
      // 策略只读
      celueReadonly: false,
      recommendRuleData: [
        {
          ruleObject: "工作规则对象",
          data: [
            {
              ruleName: "规则名称",
              ruleNameValue: "筛选总部工程师",
              ruleType: "规则类型",
              ruleTypeValue: "字段匹配规则",
              ruleContent: "规则内容",
              ruleContentValue: "服务区域AAA",
            },
          ],
        },
        {
          ruleObject: "工作规则对象2",
          data: [
            {
              ruleName: "规则名称",
              ruleNameValue: "筛选总部工程师",
              ruleType: "规则类型",
              ruleTypeValue: "字段匹配规则",
              ruleContent: "规则内容",
              ruleContentValue: "服务区域AAA",
            },
          ],
        },
      ],
      // 是否显示派遣按钮
      isShowDispatch: false,
      // 是否显示分配按钮
      isShowDistribution: false,
      // 服务预约按钮展示
      // 候选人
      btnForHouxuan: false,
      // 取消分配
      btnForCancelAssign: false,
      // 取消计划
      btnForCancelPlan: false,
      // 取消派遣
      btnForCancelDispatch: false,
      // 甘特图
      btnForGaatt: false,
      // 服务预约下有没有取消派遣的已计划服务资源记录
      listForCancelPlan: [],
      // 服务预约下有没有取消派遣的已分配服务资源记录
      listForCancelDispatch: [],
      // 取消计划弹窗shuju
      cancelPlanData: {},
      // 取消计划弹窗
      cancelPlanDialog: false,
      // 选择要取消的服务资源
      checkCancelPlanList: [],
      // 取消派遣弹窗shuju
      cancelDispatchData: {},
      // 取消派遣弹窗
      cancelDispatchDialog: false,
      // 选择要取消的服务资源
      checkCancelDispatchList: [],
      // 服务区域对象名
      serviceAreaLabel: "服务区域",
      //当前选中的服务预约
      currentCandidateData: {},
      //点击拒单操作的数据
      chooseServiceOrder: {},
      //拒单原因选项
      reasonRejectionData: [],
      //拒单原因选项
      reasonRejectionchoose: "",
      //拒单原因500字
      reasonRejectionValue: "",
      //拒单loading
      utOrderRejectLoading: false,
      //拒单弹框
      utOrderRejectFlag: false,
      //服务预约状态status为0:不显示；1:显示接单拒单按钮；2:显示接单按钮；3:显示拒单按钮
      serviceAppointmentStatus: "",
      //状态对应的颜色数据
      ColorStatusData: [],
      // 服务预约列表字段排序的下表
      fieldSortIndex: "",
    };
  },
  watch: {
    dateValue(time) {
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      // var h = date.getHours() - 8;
      // h = h < 10 ? "0" + h : h;

      this.dateTime = y + "-" + m + "-" + d;
      if (this.resourceId != "") {
        this.currentPage = 1;
        this.isTopSort = true;
        this.isStatusSort = true;
        this.status = "";
        this.sortcode = "";
        this.getAppointmentList();
      }
    },
    currentPage(newVal, oldVal) {
      if (newVal != oldVal) {
        let a = (newVal - 1) * this.pageNumber + 1;
        let b = 0;
        if (this.currentPage < this.maxPage) {
          b = a + this.pageNumber - 1;
        } else {
          b = a + (this.totalCount % this.pageNumber) - 1;
        }
        this.$Bus.$emit("deliver-list-totalCount", a, b, this.totalCount);
      }
    },
    inputValue(newVal, oldVal) {
      if (newVal != oldVal) {
        this.currentPage = 1;
        this.isTopSort = true;
        this.isStatusSort = true;
        this.status = "";
        this.sortcode = "";
        this.getAppointmentList("", "", "switch");
      }
    },
    // treeString(newVal, oldVal) {
    //   // if (newVal != oldVal) {
    //   //   if (this.resourceId != "") {
    //   //     this.currentPage = 1;
    //   //     this.isTopSort = true;
    //   //     this.isStatusSort = true;
    //   //     this.status = "";
    //   //     this.sortcode = "";
    //   //     this.getAppointmentList();
    //   //   }
    //   // }
    // },
    // resourceId(newVal) {
    //   // if (newVal != "") {
    //   //   if (this.resourceId != "") {
    //   //     this.currentPage = 1;
    //   //     this.isTopSort = true;
    //   //     this.isStatusSort = true;
    //   //     this.status = "";
    //   //     this.sortcode = "";
    //   //     this.getAppointmentList();
    //   //   }
    //   // }
    // },
    formType(newVal) {
      if (newVal != "") {
        if (this.resourceId != "") {
          this.currentPage = 1;
          this.isTopSort = true;
          this.isStatusSort = true;
          this.status = "";
          this.sortcode = "";
          this.getAppointmentList();
        }
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.clickFn);
    this.$Bus.$on("get-serviceList-again", this.ebFn2);
    this.$Bus.$on("get-appointList", this.ebFn3);
    this.$Bus.$on("update-active-appo", this.ebFn4);
    this.$Bus.$on("get-appointList-map", this.ebFn5);
    this.$bus.$on("save-saveAppointmentIsChecked", this.ebFn6);
    this.getObjectInfo();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickFn);
    this.$Bus.$off("get-serviceList-again", this.ebFn2);
    this.$Bus.$off("get-appointList", this.ebFn3);
    this.$Bus.$off("update-active-appo", this.ebFn4);
    this.$Bus.$off("get-appointList-map", this.ebFn5);
    this.$bus.$off("save-saveAppointmentIsChecked", this.ebFn6);
  },
  created() {
    this.getAppointmentIsChecked();
  },
  methods: {
    // 节流函数
    fnThrottle(method, delay, duration, value) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          if (method == "getOrderReceiving") {
            that.getOrderReceiving(value);
          } else if (method == "cancelAssign") {
            that.cancelAssign(value);
          } else if (method == "cancelPlan") {
            that.cancelPlan(value);
          } else if (method == "cancelDispatch") {
            that.cancelDispatch(value);
          }
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            if (method == "getOrderReceiving") {
              that.getOrderReceiving(value);
            } else if (method == "cancelAssign") {
              that.cancelAssign(value);
            } else if (method == "cancelPlan") {
              that.cancelPlan(value);
            } else if (method == "cancelDispatch") {
              that.cancelDispatch(value);
            }
          }, delay);
        }
      };
    },
    clickFn() {
      this.isShowMoreFunc = false;
    },
    ebFn2() {
      this.getAppointmentList();
      this.currentIndex = null;
    },
    ebFn3() {
      this.getAppointmentList();
    },
    ebFn4(id) {
      this.updateActioveAppo(id);
    },
    ebFn5() {
      this.getAppointmentList();
    },
    ebFn6(res) {
      this.checkedRegional = res;
      //   this.currentPage = 1;
      //   this.getAppointmentList();
    },
    // 获取图例颜色数据
    async getAppoStatusLegends() {
      let res = await getAppoStatusLegends();
      this.ColorStatusData = res.data || [];
      this.$Bus.$emit("postGanttLegends", this.ColorStatusData);
    },
    // 根据状态获取对应颜色
    getCurrentColor(name) {
      let currentColr = this.ColorStatusData.find((item) => {
        return item.codevalue == name;
      });
      if (currentColr) {
        return currentColr.legend;
      } else {
        return "#FFFFFF";
      }
    },
    // 服务预约详情页显示接单和拒单按钮
    oRButton(data, id) {
      let params = {
        serviceappointmentId: data?.id || id,
      };
      oRButton(params).then((res) => {
        if (res.result) {
          this.serviceAppointmentStatus = res?.data?.status || 0;
          // status为0:不显示；1:显示接单拒单按钮
          // 当存在拒单按钮时，调取拒单原因接口
          if (this.serviceAppointmentStatus == "1") {
            this.getRejectReason();
          }
        }
      });
    },
    // 拒单原因接口
    getRejectReason() {
      getRejectReason().then((res) => {
        if (res.result && res.data) {
          this.reasonRejectionData = res.data;
        }
      });
    },
    // 接单接口
    getOrderReceiving(data) {
      this.serviceAppointmentId = data.id;
      this.chooseServiceOrder = data;
      let params = {
        serviceappointmentId: data.id, //服务预约id
      };
      utOrderReceive(params).then((res) => {
        if (res.result) {
          this.serviceAppointmentStatus = "Receiving";
          this.$message.success(this.$i18n.t("c2175"));
          this.getAppointmentList();
        } else {
          this.$message.error(this.$i18n.t("c2176"));
        }
      });
    },
    // 点击拒单按钮
    getutOrderReject(data) {
      this.serviceAppointmentId = data.id;
      this.getRejectReason(); //获取拒单原因
      this.chooseServiceOrder = data;
      this.utOrderRejectFlag = true;
      this.reasonRejectionchoose = this.$i18n.t("c2323");
    },
    // 关闭拒单弹框
    cancelOrderRejectFlag() {
      this.utOrderRejectFlag = false;
    },
    // 拒单接口
    async utOrderReject() {
      this.utOrderRejectLoading = true;
      let params = {
        serviceappointmentId: this.chooseServiceOrder.id, //服务预约id
        rejectReason: this.reasonRejectionchoose, //拒单原因,
        rejectDetail: this.reasonRejectionValue, //详细原因
        serviceterritoryId: this.chooseServiceOrder?.serviceterritoryid, //服务区域id
      };
      utOrderReject(params).then((res) => {
        if (res.result) {
          this.serviceAppointmentStatus = "Receiving";
          this.$message.success(this.$i18n.t("c2178"));
          this.utOrderRejectFlag = false;
          this.reasonRejectionchoose = "";
          this.reasonRejectionValue = "";
          this.getAppointmentList();
        } else {
          this.$message.error(this.$i18n.t("c2177"));
          this.utOrderRejectFlag = false;
        }
        this.utOrderRejectLoading = false;
      });
    },
    // 获取对象信息
    getObjectInfo() {
      getObjectInfo({ id: "ServiceTerritory2021" }).then((res) => {
        if (res.result) {
          this.serviceAreaLabel = res.data.labelname;
        }
      });
    },
    // 取消分配
    cancelAssign(item) {
      if (item.serviceterritoryid) {
        let option = {
          serviceAppointmentId: item.id,
          serviceTerritoryId: item.serviceterritoryid,
        };
        this.$confirm(this.$i18n.t("c2135"))
          .then(() => {
            cancelServiceAreaAssignment(option).then((res) => {
              if (res.result) {
                this.$message.success(this.$i18n.t("c2137"));
                this.getAppointmentList();
                this.$bus.$emit("gantt-appoin-cancelService");
                this.currentIndex = null;
              }
            });
          })
          .catch(() => {});
      } else {
        // this.serviceAreaLabel
        this.$message.warning("请先将服务预约分配服务区域后再进行操作");
      }
    },
    // 取消计划
    cancelPlan(item) {
      this.checkCancelPlanList = [];
      if (this.listForCancelPlan.length > 0) {
        this.listForCancelPlan.forEach((item) => {
          this.checkCancelPlanList.push(item.id);
        });
        this.cancelPlanData = item;
        this.cancelPlanDialog = true;
      }
    },
    // 确认取消计划
    saveCancelPlan() {
      if (this.checkCancelPlanList.length > 0) {
        let option = {
          appoid: this.cancelPlanData.id,
          resourceIds: this.checkCancelPlanList.join(","),
        };
        cancelServiceAppo(option).then((res) => {
          if (res.result) {
            this.$message.success("取消计划成功");
            this.cancelPlanDialog = false;
            this.getAppointmentList();
            this.$bus.$emit("gantt-appoin-cancelService");
            this.currentIndex = null;
          }
        });
      } else {
        this.$message.warning("请至少选择一个服务资源");
      }
    },
    // 取消派遣
    cancelDispatch(item) {
      this.checkCancelDispatchList = [];
      if (this.listForCancelDispatch.length > 0) {
        this.listForCancelDispatch.forEach((item) => {
          this.checkCancelDispatchList.push(item.id);
        });
        this.cancelDispatchData = item;
        this.cancelDispatchDialog = true;
      }
    },
    // 确认取消派遣
    saveCancelDispatch() {
      if (this.checkCancelDispatchList.length > 0) {
        let option = {
          serviceAppointmentId: this.cancelDispatchData.id,
          serviceResource: this.checkCancelDispatchList.join(","),
        };
        cancelServiceAppoDispatch(option).then((res) => {
          if (res.result) {
            this.$message.success("取消派遣成功");
            this.cancelDispatchDialog = false;
            this.getAppointmentList();
            this.$bus.$emit("gantt-appoin-cancelService");
            this.currentIndex = null;
          }
        });
      } else {
        this.$message.warning("请至少选择一个服务资源");
      }
    },

    getDraggable(color) {
      if (
        color == "#A5E2D6" ||
        color == "#A0E0FD" ||
        color == "#A5B8FF" ||
        color == "#FFE085"
      ) {
        return true;
      } else {
        return false;
      }
    },
    formatDate,
    // 保存候选人策略规则弹框
    saveCanditate() {
      this.$refs.celueform.validate((valid) => {
        if (valid) {
          this.updateServiceApp();
        }
      });
    },
    /**
     * 父组件中ref调用
     * status:切换服务区域时刷新服务资源列表
     */
    freshList(resourceId, areaIds, status) {
      // this.resourceId = resourceId
      // this.treeString = areaIds
      this.currentPage = 1;
      this.isTopSort = true;
      this.isStatusSort = true;
      this.status = "";
      this.sortcode = "";
      this.getAppointmentList(resourceId, areaIds, status);
    },
    // 获取“显示未与服务区域关联的服务预约”是否被勾选
    async getAppointmentIsChecked() {
      let result = await getAppointmentChecked();
      if (result.result == true) {
        this.checkedRegional = result.data.ischecked == "true" ? true : false;
      }
    },
    /**
     * 点击列表名空白位置进行排序切换当前排序状态
     * @param {Array} item 列表名称数据
     * @param {String} status 排序状态
     * @param {Number} index 下标
     */
    getBoxSort(item, status, index) {
      this.fieldSortIndex = index;
      this.sortcode = item.apiname;
      this.currentPage = 1;
      if (this.status == "asc") {
        this.status = "desc";
      } else {
        this.status = "asc";
      }
      this.getAppointmentList();
    },
    /**
     * 预约编号与状态排序
     * @param {Array} item 列表名称数据
     * @param {String} status 排序状态
     * @param {Number} index 下标
     */
    sort(item, status, index) {
      this.fieldSortIndex = index;
      this.sortcode = item.apiname;
      this.status = status || "";
      this.currentPage = 1;
      this.getAppointmentList();
    },
    //派遣
    // dispatch() {
    //   if (this.cancelDeliverData.color != "#A0E0FD") {
    //     return false;
    //   }
    //   let option = {
    //     id: this.cancelDeliverData.id,
    //     status: "已派遣",
    //   };
    //   this.save(JSON.stringify([option]));
    // },
    // 点击候选人按钮，判断是否弹窗
    // 点击候选人后，如果服务预约没有关联计划策略或没有填写“最早允许开始”和“到期日期”任意日期/时间值，
    // 则系统会弹出“候选人策略”弹窗，弹窗上显示“时间范围”，从“{最早允许的开始}”开始到“{到期日期}”结束，如果字段无值，默认当前操作时间。
    async excutePolicy(data) {
      this.serviceAppointmentId = data.id;
      let res = await excutePolicy({ serviceappointmentId: data.id });
      if (res.data.type == "candidateList") {
        this.$emit("openCandidate", data);
      } else {
        this.currentCandidateData = data;
        let startDate = "";
        let endDate = "";
        // 结束时间
        if (res.data.duedate) {
          endDate = formatDate(new Date(res.data.duedate), "yyyy-MM-dd");
        } else if (!res.data.duedate && !res.data.earlieststarttime) {
          // 没有开始时间和结束时间，默认当前时间为开始时间，结束时间为当前时间+3
          endDate = formatDate(this.getNextDay(new Date(), 3), "yyyy-MM-dd");
        } else if (res.data.earlieststarttime && !res.data.duedate) {
          endDate = formatDate(
            this.getNextDay(res.data.earlieststarttime, 3),
            "yyyy-MM-dd"
          );
        }
        // 开始时间
        if (res.data.earlieststarttime) {
          startDate = formatDate(
            new Date(res.data.earlieststarttime),
            "yyyy-MM-dd"
          );
        } else {
          startDate = formatDate(new Date(), "yyyy-MM-dd");
        }
        this.startDateRange = startDate;
        this.endDateRange = endDate;
        this.celueOptions = res.data.polocyList;
        this.celueReadonly = res.data.readOnly;
        if (res.data.polocyList?.length > 0) {
          this.celueformData.celueValue = res.data.polocyList[0].id;
        }
        this.candidateShow = true;
      }
    },
    // 计划策略保存接口
    async updateServiceApp() {
      if (
        new Date(this.endDateRange).getTime() -
          new Date(this.startDateRange).getTime() <
        0
      ) {
        this.$message.error(
          this.$i18n.t("earlieststarttime.duedate.time.illegal")
        );
        return;
      }
      let params = {
        serviceAppointmentId: this.serviceAppointmentId, //服务预约id
        policyId: this.celueformData.celueValue, //计划策略id
        earlieststarttime: this.startDateRange, //最早允许开始时间
        duedate: this.endDateRange, //到期时间
      };
      let res = await updateServiceApp(params);
      if (res.result) {
        this.candidateShow = false;
        this.getAppointmentList();
        this.currentCandidateData.schedulingpolicyid =
          this.celueformData.celueValue; //策略id
        //保存后打开候选人列表
        this.$emit("openCandidate", this.currentCandidateData);
      }
    },
    // 计算日期添加天数方法
    getNextDay(d, t) {
      d = new Date(d);
      d = +d + 1000 * 60 * 60 * 24 * t;
      d = this.$moment(new Date(d)).format("YYYY-MM-DD");
      return d; //格式为"2019-02-16 00:00:00"
    },
    locationInGannt() {
      if (this.cancelDeliverData.schedstarttime) {
        this.$emit("locationGrantt", this.cancelDeliverData.schedstarttime);
      }
    },
    //取消选择
    cancelDeliver() {
      if (
        this.cancelDeliverData.codevalue !=
        this.$i18n.t("label.projectManagement.planned")
      ) {
        return false;
      }
      // 取消服务预约派遣
      this.cancelServiceAppo();
      //获取服务预约列表
      // 获取全部服务资源及服务预约
    },
    // type类型为S Y M的跳转详情--详情信息的跳转
    detailTurn(type, name, item) {
      // 判断是否为控制台样式
      if (this.$store.state.navigationStyle) {
        // 是否为跳转字段
        if (this.isJump(type, name, item)) {
          // 编号name跳转
          if (name === "name") {
            this.$CCDK.CCPage.openDetailPage(
              {
                oprateType: "DETAIL",
                objectName: item.name,
                objId: item.id,
              },
              item.id,
              {
                openPlace: "menu2",
                openMode: "_blank",
                pageId: item.id,
              }
            );
          } else {
            this.$CCDK.CCPage.openDetailPage(
              {
                oprateType: "DETAIL",
                objectName: item[name + "Name"],
                objId: item[name],
              },
              item[name],
              {
                openPlace: "menu2",
                openMode: "_blank",
                pageId: item[name],
              }
            );
          }
        }
      } else {
        if (this.isJump(type, name, item)) {
          let path = null
          if (name === "name") {
            path = `#/commonObjects/detail/${this.fieldValueList.id}/DETAIL`
          } else {
            path = `#/commonObjects/detail/${this.fieldValueList[name]}/DETAIL`
          }
          if(path){
            // 新tab打开，bug11317
            const url = window.location.origin+path
            window.open(url)
          }
        }
      }
    },
    // 判断字段是否可跳转
    isJump(fieldtype, fieldname, item) {
      if (
        ((fieldtype === "S" || fieldtype === "V") && fieldname === "name") ||
        (fieldtype === "Y" && fieldname !== "ownerid") ||
        (fieldname === "ownerid" && owneridIsJump(item[fieldname])) ||
        fieldtype === "M"
      ) {
        /* 可跳转：
              1.字段类型为S，且字段名称为name；
              2.字段类型为Y，但不包含所有人（所有人字段类型为Y）；
              3.字段名称为所有人，且符合所有人跳转规则；
              4.字段类型为M。
          */
        return true;
      } else {
        return false;
      }
    },
    // 显示是否需要加ccname，获取对应的name
    getName(fieldtype, fieldname) {
      if (fieldtype === "Y" || fieldtype === "M" || fieldtype === "R") {
        // 字段类型为Y,M即需要加上ccname取值
        return fieldname + "ccname";
      } else if (
        fieldtype === "S" &&
        (fieldname === "whoid" || fieldname === "relateid")
      ) {
        return fieldname + "ccname";
      } else {
        return fieldname;
      }
    },
    //获取服务预约详情
    async getDetails(id, status) {
      // 分配成功后判断当前服务预约是否包含该分配记录，包含时更新服务预约详情
      // 不包含该记录时服务预约详情
      if (status == "sssigned") {
        let findData = this.tableBodyList.find((item) => {
          return item.id == id;
        });
        if (!findData) {
          this.currentIndex = null;
          return;
        }
      }
      this.miniDetailLoading = true;
      let result = await getDetail({ operation: "MINI", id: id });
      if (result.result == true) {
        this.fieldNameList = result.data.fieldList;
        this.fieldValueList = result.data.data;
        this.miniDetailLoading = false;
      }
    },
    // //左箭头
    // reduce() {
    //   if (this.currentPage > 1) {
    //     this.currentPage--;
    //     this.getAppointmentList();
    //   }
    // },
    // //右箭头
    // add() {
    //   if (this.currentPage < this.maxPage) {
    //     this.currentPage++;
    //     this.getAppointmentList();
    //   }
    // },

    //切换每页显示条数,5,10,
    handleSizeChange(val) {
      this.pageNumber = val;
      this.getAppointmentList();
    },

    //切换当前显示页数,1,2,3,4
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getAppointmentList();
    },

    //列表鼠标移入
    over(item) {
      this.currentData = item;
    },
    //列表鼠标按下
    down(res) {
      this.$Bus.$emit("appointment-drag-data", res, this.currentData);
    },
    // 拖拽后更新
    updateActioveAppo(id) {
      if (id == this.cancelDeliverData.id) {
        this.getDetails(id);
      }
    },
    //获取服务预约列表
    // status:判断是否是分配后调取接口
    // id:服务区域id
    async getAppointmentList(resourceId, areaIds, status, id) {
      this.getAppoStatusLegends();
      this.listDataLoading = true;
      let formTypeArr = [];
      if (
        this.formType.indexOf(
          this.$i18n.t("label.service.appointmentList.earliest.allowedStart")
        ) != -1
      ) {
        formTypeArr.push("ffe21sappointment038");
      }
      if (
        this.formType.indexOf(this.$i18n.t("label.chatter.maturiyDate")) != -1
      ) {
        formTypeArr.push("ffe21sappointment008");
      }
      if (
        this.formType.indexOf(
          this.$i18n.t("label.service.appointmentList.startOf.maturityPeriod")
        ) != -1
      ) {
        formTypeArr.push("ffe21sappointment009");
      }
      if (
        this.formType.indexOf(
          this.$i18n.t("label.service.appointmentList.plansToStart")
        ) != -1
      ) {
        formTypeArr.push("ffe21sappointment029");
      }
      if (
        this.formType.indexOf(
          this.$i18n.t("label.service.appointmentList.plansToEnd")
        ) != -1
      ) {
        formTypeArr.push("ffe21sappointment030");
      }
      if (
        this.formType.indexOf(
          this.$i18n.t("label.service.appointmentList.gantt.chart.shows")
        ) != -1
      ) {
        formTypeArr.push("ffe21sappointment045");
      }
      let keyword = this.inputValue.trim() === "" ? "" : this.inputValue;
      let option = {
        dateFieldName: formTypeArr.join(),
        viewId: resourceId || this.resourceId || "",
        serviceTerritoryId: areaIds || this.treeString || "",
        isRelateTerritory: this.checkedRegional == true ? false : true,
        horizontaldate: this.dateTime + " 00:00:00",
        pageNum: String(this.currentPage),
        pageSize: String(this.pageNumber),
        queryCondition: keyword,
        sortcode: this.sortcode,
        status: this.status || "desc",
      };
      let bodyList = [];
      let result = await getServiceAppointmentList(option);
      if (result.result == true) {
        // 父组件切换服务区域时关闭服务详情
        if (status == "switch") {
          this.currentIndex = null;
        }
        this.tableBodyList = [];
        this.tableTitle = [];
        this.listDataLoading = false;
        this.totalCount = result.data.totalCount;
        this.maxPage = Math.ceil(this.totalCount / this.pageNumber);
        let a = (this.currentPage - 1) * this.pageNumber + 1;
        let b = 0;
        if (this.currentPage < this.maxPage) {
          b = a + this.pageNumber - 1;
        } else if (this.totalCount == 10) {
          b = 10;
        } else {
          b = a + (this.totalCount % this.pageNumber) - 1;
        }
        this.$Bus.$emit("deliver-list-totalCount", a, b, this.totalCount);
        for (let i = 1; i < result.data.viewFieldVoList.length; i++) {
          for (let j = 0; j < result.data.viewFieldVoList.length - i; j++) {
            if (
              Number(result.data.viewFieldVoList[j].seq) >
              Number(result.data.viewFieldVoList[j + 1].seq)
            ) {
              var tt = result.data.viewFieldVoList[j];
              result.data.viewFieldVoList[j] =
                result.data.viewFieldVoList[j + 1];
              result.data.viewFieldVoList[j + 1] = tt;
            }
          }
        }
        this.tableTitle = result.data.viewFieldVoList;
        bodyList = result.data.serviceAppointmentList;
        // this.tableTitle = [{ apiname: "ganttlabel", fieldname: '客户ID' }, { apiname: "lastmodifybyid", fieldname: '上次修改人' }, { apiname: "name", fieldname: '预约编号' }]
        this.tableTitle.forEach((res) => {
          bodyList.forEach((ele, index) => {
            if (this.tableBodyList.length < bodyList.length) {
              this.tableBodyList.push({});
            }
            if (res.fieldtype == "Y" || res.fieldtype == "R") {
              this.tableBodyList[index][res.apiname + "ccname"] =
                ele[res.apiname + "ccname"];
            } else {
              this.tableBodyList[index][res.apiname] = ele[res.apiname];
            }
            this.tableBodyList[index].codevalue = ele.codevalue;
            this.tableBodyList[index].id = ele.id;
            this.tableBodyList[index].ganttName = ele.name;
            this.tableBodyList[index].serviceterritoryid =
              ele.serviceterritoryid;
            this.tableBodyList[index].address = ele.address;
            this.tableBodyList[index].schedstarttime = ele.schedstarttime;
            this.tableBodyList[index].schedendtime = ele.schedendtime;
            this.tableBodyList[index].schedulingpolicyid =
              ele.schedulingpolicyid ? ele.schedulingpolicyid : ""; //策略id
            if (
              ele.duration == undefined ||
              ele.duration == "0" ||
              ele.duration == ""
            ) {
              this.tableBodyList[index].durationIns = 1;
            } else {
              this.tableBodyList[index].durationIns = Number(ele.duration);
            }
            let color = this.getCurrentColor(ele.codevalue);
            // switch (ele.codevalue) {
            //   case "无":
            //     color = "#A5E2D6";
            //     break;
            //   case "已计划":
            //     color = "#A0E0FD";
            //     break;
            //   case "已派遣":
            //     color = "#A5B8FF";
            //     break;
            //   case "进行中":
            //     color = "#FFE085";
            //     break;
            //   case "已完成":
            //     color = "#FFB485";
            //     break;
            //   case "无法完成":
            //     color = "#F7A7A7";
            //     break;
            //   case "已取消":
            //     color = "#DDDBDA";
            //     break;
            // }
            this.tableBodyList[index].color = color;
            this.tableBodyList[index].codeType = ele.codeType;
          });
        });
        // 分配成功后更新服务预约详情,调用分配接口和显示接单拒单接口
        if (status == "sssigned") {
          this.getDetails(id, status);
          this.getServieceResourceByAppo(this.serviceAppointmentId);
          this.oRButton("", this.serviceAppointmentId);
        }
        // 接单拒单后刷新服务预约列表，如果列表中包含当前数据，刷新卡片
        if (this.serviceAppointmentStatus == "Receiving") {
          bodyList.forEach((item) => {
            if (item.id == this.chooseServiceOrder.id) {
              this.getDetails(item.id);
            }
          });
          let searchData = bodyList.find((item) => {
            return item.id == this.chooseServiceOrder.id;
          });
          // 服务预约不包含当前记录关闭卡片
          if (!searchData) {
            this.currentIndex = null;
          } else {
            this.getDetails(this.chooseServiceOrder.id);
            this.getServieceResourceByAppo(this.serviceAppointmentId);
          }
          this.serviceAppointmentStatus = "";
          this.chooseServiceOrder = {};
        }
      }
    },
    /**
     * 父组件ref调用
     * 派遣服务预约
     */
    async save(data) {
      let option = {
        objectApi: "ServiceAppointment",
        data: data,
      };
      let result = await save(option);
      if (result.result == true) {
        this.$bus.$emit("gantt-appoin-cancelService");
        this.getAppointmentList();
        this.currentIndex = null;
        this.$message.success(
          this.$i18n.t("label.service.appointmentList.send.successfully")
        );
        this.$router.go(0);
      }
    },
    // 取消服务预约派遣
    async cancelServiceAppo() {
      let option = {
        appoid: this.cancelDeliverData.id,
      };
      let result = await cancelServiceAppo(option);
      if (result.result == true) {
        this.currentIndex = null;
        this.getAppointmentList();
        this.$bus.$emit("gantt-appoin-cancelService");
        this.$message.success(
          this.$i18n.t("label.service.appointmentList.canceltheSuccess")
        );
        this.$router.go(0);
      }
    },
    //显示更多列表功能
    // showMoreFunc() {
    //   this.isShowMoreFunc = true;
    // },
    selectDetail(index, body) {
      if (this.currentIndex != index) {
        this.getDetails(body.id);
      }
      // 判断是否显示接单拒单按钮
      this.oRButton(body);
      //服务预约状态类型=未处理和进行中显示：候选人
      if (body.codeType === "inprogress" || body.codeType === "notprocessed") {
        this.btnForHouxuan = true;
      } else {
        this.btnForHouxuan = false;
      }
      this.getServieceResourceByAppo(body.id);
      this.btnForGaatt = true;
      this.cancelDeliverData = body;
      this.currentIndex = this.currentIndex == index ? null : index;
    },
    // 根据接口判断是否显示按钮
    getServieceResourceByAppo(id) {
      getServieceResourceByAppo({
        serviceAppointmentId: id,
      }).then((res) => {
        if (res.result) {
          // 是否显示取消分配按钮
          this.btnForCancelAssign = res.data.unallocateButton;
          // 服务资源列表，此列表为空时不显示取消派遣按钮
          // a. 服务预约下有没有取消派遣的已分配服务资源记录显示：取消派遣、甘特图。
          this.listForCancelDispatch = res.data.resourceList || [];
          this.listForCancelPlan = res.data.planResourceList || [];
          this.btnForCancelPlan =
            this.listForCancelPlan.length > 0 ? true : false;
          if (this.listForCancelDispatch.length > 0) {
            this.btnForCancelDispatch = true;
            // this.btnForGaatt = true;
          } else {
            this.btnForCancelDispatch = false;
            // this.btnForGaatt = false;
          }
        }
      });
    },
    handleCheckedListChange(val) {
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.tableBodyList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.tableBodyList.length;
      this.checkedList = val;
    },
    handleCheckedListRadio(val) {
      this.checkedListRadio = val;
      this.$bus.$emit("get-checkedListRadio", this.checkedListRadio);
    },
    handleCheckAllChange(val) {
      this.checkedList = val ? this.tableBodyList : [];
      this.isIndeterminate = false;
    },
  },
};
</script>

<style lang='scss' scoped>
.rejection-box {
  ::v-deep .el-select {
    width: 360px;
    height: 32px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin-bottom: 16px;
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
  ::v-deep .el-textarea {
    width: 540px;
    height: 222px;
    background: #fafafa;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    .el-textarea__inner {
      height: 222px;
    }
  }
}
.scrollContent-noScroll::-webkit-scrollbar {
  width: 0px;
  height: 10px;
}
.scrollContent-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.icon {
  display: inline;
}
.appointment-list {
  height: calc(100% - 210px);
  position: relative;
  font-size: 12px;
  .el-radio {
    margin-right: -8px;
    transform: translateY(-15px);
  }
  .el-checkbox-group {
    font-size: 12px;
  }
  .el-checkbox:last-of-type {
    margin-right: 5px;
    transform: translateY(-15px);
  }
  ::v-deep .el-checkbox__label {
    display: none;
  }
  .table {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #dddfe8;
    font-size: 12px;
    .item {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 100%;
      span {
        position: relative;
      }
      img {
        position: absolute;
        right: -15px;
        top: 1px;
        width: 14px;
        cursor: pointer;
      }
    }
    .color {
      display: inline-block;
      border-radius: 0 3px 3px 0;
      width: 5px;
      height: 11px;
      margin-right: 5px;
      transform: translateY(-15px);
    }
  }
  .table-title {
    background: #f8f9fb;
    .table-name {
      display: flex;
      flex-direction: row;
    }
    .name-box {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .asc-box {
      display: flex;
      flex-direction: column;
      padding: 10px 2px 8px 5px;
      .ascicon {
        transform: rotate(180deg);
        width: 10px;
        height: 10px;
      }
      .descicon {
        width: 10px;
        height: 10px;
      }
    }
  }
  .table-item-detail {
    width: 100%;
    background: #f8f9fb;
    .item {
      padding: 10px 20px;
      box-sizing: border-box;
      width: 50%;
      display: inline-block;
      height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .name {
        color: #2e324d;
        margin-bottom: 5px;
      }
      .value {
        color: #9fa3b4;
        margin-bottom: 5px;
      }
      .active {
        color: #006dcc !important;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .func-button {
      position: relative;
      margin-left: 15px;
      .dialog-box {
        position: absolute;
        top: 30px;
        right: 0;
        background: #ffffff;
        border: 1px solid #dddfe8;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
        border-radius: 1px;
        width: 120px;
        padding: 10px 0;
        z-index: 2;
        li {
          padding: 5px 10px;
          color: #006dcc;
          text-align: left;
          cursor: pointer;
          .icon {
            margin-right: 5px;
          }
        }
      }
      .button {
        cursor: pointer;
        padding: 5px;
        box-sizing: border-box;
        display: inline-block;
        border: 1px solid #dddfe8;
        color: #006dcc;
        //border-left: none;
        margin-bottom: 10px;
        img {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
      .buttonActive {
        cursor: default;
        color: grey;
      }
      .first {
        border-left: 1px solid #dddfe8;
        border-radius: 3px 0 0 3px;
      }
      .finally {
        border-radius: 0px 3px 3px 0px;
      }
    }
  }
  .no-data {
    text-align: center;
    width: 100%;
    padding-top: 80px;
    height: 100%;
  }
  .body-small-box {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .bodys {
    width: 100%;
    height: calc(100% - 80px);
  }
  .body-long-box {
    width: 100%;
  }
  .table-body {
    cursor: pointer;
  }
  .page {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    border-top: 1px solid #dddfe8;
    text-align: center;
    line-height: 40px;
    img {
      width: 16px;
      cursor: pointer;
    }
    .img-grey {
      cursor: default;
    }
    .page-number {
      display: inline-block;
      border: 1px solid #dddfe8;
      width: 40px;
      height: 20px;
      line-height: 20px;
      margin: 0 5px;
    }
  }
  // 新版--分页
  .el-pagination {
    overflow-x: auto;
    display: flex;
    justify-content: space-between;
    padding: 2px 10px;
    ::v-deep .el-select .el-input .el-input__inner {
      padding-right: 18px;
      padding-left: 0;
    }
    ::v-deep .el-pagination__jump {
      margin-left: 0px;
      .el-pagination__editor {
        padding: 0 6px;
        //margin: 0 4px;
      }
    }
    ::v-deep .el-pagination__total {
      margin-right: 0;
    }
    ::v-deep .el-select .el-input {
      width: 80px;
    }
    ::v-deep .btn-prev {
      padding: 0;
      min-width: 18px;
    }
    ::v-deep .btn-next {
      padding: 0;
      min-width: 18px;
    }
    ::v-deep .el-pager li {
      min-width: 16px;
    }
  }
  .celue {
    height: 100%;
    .celue-item-label {
      font-size: 14px;
      color: #999999;
      line-height: 19px;
      margin-bottom: 12px;
    }
    .celue-item {
      margin-bottom: 20px;

      .celue-item-option {
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          padding: 0 5px;
        }
        .centerdiv {
          min-width: 10px;
        }
      }
    }
    .celue-list {
      height: 40%;
      margin-top: 30px;
    }
    .celue-objectname {
      width: 100%;
      color: #999999;
      background: #f7f7fa;
      height: 32px;
      line-height: 32px;
      padding-left: 12px;
      font-size: 14px;
      margin: 4px 0;
    }
    .celue-rule {
      margin-top: 12px;
      .celue-rule-item {
        margin-bottom: 8px;
        font-size: 14px;
        display: flex;
        .celue-number {
          width: 20px;
          height: 20px;
          margin: 0 8px 0 12px;
          display: inline-block;
          border-radius: 50%;
          color: #576c9c;
          border: 1px solid #d8eeff;
          text-align: center;
        }
        .celue-div {
          width: 40px;
          height: 20px;
        }
      }
      .celue-rule-title {
        width: 76px;
        display: inline-block;
        color: #999999;
      }
      .celue-rule-content {
        display: inline-block;
        padding-left: 12px;
        color: #333333;
      }
    }
  }
}
.cancel-dispatch {
  .cancel-dispatch-tittle {
    font-size: 14px;
    color: #333333;
    line-height: 19px;
    margin-bottom: 24px;
  }
  .cancel-dispatch-box {
    .el-checkbox:last-of-type {
      margin-right: 0px;
      transform: translateY(0);
    }
    ::v-deep .el-checkbox__label {
      display: inline;
    }
  }
}
</style>
