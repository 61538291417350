<template>
  <div class="selectInput">
    <div class="title" :class="isShow ? 'active' : ''">
      <span @click.stop="liShow" class="inputInner"
        >{{ lableName }} {{ selectCon }}</span
      >
      <i
        class="icon el-icon-arrow-down"
        @click.stop="liShow"
        :class="isShow ? 'active' : ''"
      ></i>
    </div>
    <ul v-show="isShow" isSelectCheck="1" @click.stop="">
      <!-- 全部 -->
      <li isSelectCheck="1">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          isSelectCheck="1"
          >{{$t("label.forecast.forehistory.all")}}</el-checkbox
        >
      </li>
      <li isSelectCheck="1">
        <el-checkbox-group
          v-model="checkedData"
          @change="handlecheckedDataChange"
          isSelectCheck="1"
        >
          <el-checkbox
            v-for="item in listData"
            :label="item.name"
            :key="item.name"
            isSelectCheck="1"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    lableName: {
      type: String,
      default: "",
    },
    liData: {
      type: Array,
      default: () => [],
    },
    checkName: {
      type: String,
      default:"",
    },
  },
  data() {
    return {
      checkedData: [], //选中的数据
      isShow: false, //下拉列表是否显示
      selectCon: "", //选中的内容
      checkAll: false,
      isIndeterminate: false,
      listData:[],//下拉数据
    };
  },
  mounted() {
    //点击页面空白处非选择ul区域时隐藏下拉列表
    document.addEventListener("click", this.clickFn);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickFn);
  },
  watch: {
    // 监听传过来的下拉数据
    liData:{
      deep:true,
      immediate:true,
      handler(val){
        this.listData = val;
        if(this.checkName&&val.length){
          let arr=this.listData.filter((item) => this.checkName.indexOf(item.id)!=-1);
          this.checkedData=arr.map((item) => item.name);
          this.selectCon = this.checkedData.join(" ");
        }
      }
    },
  },
  methods: {
    clickFn() {
      this.isShow = false;
    },
    //控制下拉列表的显示隐藏
    liShow() {
      this.isShow = !this.isShow;
    },
    handleCheckAllChange(val) {
      if (val) {
        this.selectCon = this.$i18n.t("label.forecast.forehistory.all");//全部
      } else {
        this.selectCon = "";
      }
      let _data = this.listData.map((item) => item.name);
      let _id = this.listData.map((item) => item.id);
      this.checkedData = val ? _data : [];
      let _ids = val ? _id.join(",") : ""; //传给父组件供查询使用
      this.$emit("update:checkName", _ids);
      this.isIndeterminate = false;
    },
    handlecheckedDataChange(value) {
      let checkedCount = value.length;
      let _ids=[];
      this.checkAll = checkedCount === this.listData.length;
      if (this.checkAll) {
        this.selectCon = this.$i18n.t("label.forecast.forehistory.all");//全部
      } else {
        this.selectCon = value.join(" ");
      }
      for (let i = 0; i <  this.listData.length; i++) {
        for (let j = 0; j < value.length; j++) {
          if(this.listData[i].name==value[j]){
            _ids.push(this.listData[i].id)
          }
        }
      }
      this.$emit("update:checkName", _ids.join(","));
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.listData.length;
    },
  },
};
</script>

<style scoped lang="scss">
.selectInput {
  float: left;
  cursor: pointer;
}
.selectInput .title {
  width: 142px;
  position: relative;
  border: 1px solid #dcdfe6;
  background: #fff;
}
.selectInput .inputInner {
  width: 136px;
  height: 26px;
  display: block;
  line-height: 26px;
  outline: none;
  border: 0;
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 3px;
}
.selectInput i {
  position: absolute;
  width: 30px;
  height: 26px;
  line-height: 26px;
  right: 0;
  top: 0;
  text-align: center;
  cursor: pointer;
  &.active {
    transform: rotate(-180deg);
    transition: transform 0.2s ease-in;
  }
}
.selectInput ul {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  width: 142px;
  padding: 10px 10px;
  margin: 10px 0 0;
  position: relative;
  max-height: 200px;
  overflow-y: auto;
}
.title.active:after {
  content: "";
  position: absolute;
  left: 25px;
  bottom: -16px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background: #fff;
  z-index: 0;
  border-top: 1px solid #dcdfe6;
  border-left: 1px solid #dcdfe6;
}
.selectInput li {
  line-height: 30px;
  cursor: pointer;
  label {
    display: block;
    margin: 0;
  }
  input {
    margin-right: 6px;
  }
}
</style>
